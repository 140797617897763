// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ErrorType} from 'contexts/ErrorContext';
import {useUserContext} from 'contexts/UserContext';
import {Toast} from 'pages/components/Toast';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'utils/path';

import {Props, ToastInfo} from './ErrorCatcher.types';

export function ErrorCatcher({children, error, clear}: Props) {
  const navigate = useNavigate();
  const [toastInfo, setToastInfo] = useState<ToastInfo | null>(null);
  const {isPaidUser} = useUserContext();

  const {t} = useTranslation();

  useEffect(() => {
    if (error) {
      if (error.type === ErrorType.NoCredit) {
        setToastInfo({
          title: t('No Credit'),
          content: isPaidUser
            ? t('Sorry, all credits have been depleted. You can recharge now.')
            : t(
                'Sorry, all credits have been depleted. Subscribe to continue creation.'
              ),
          confirmText: isPaidUser ? t('Recharge') : t('Subscribe'),
          onOk: () => {
            navigate(PLAN_PAGE_PATH);
            clear();
          },
        });
      } else if (
        error.type === ErrorType.Service ||
        error.type === ErrorType.CapacityLimiting
      ) {
        setToastInfo({
          title: t('Notice'),
          content: t('System error. Please try again.'),
          showCancel: false,
          confirmText: t('OK'),
          onOk: () => {
            error.callback && error.callback();
            clear();
          },
        });
      } else if (error.type === ErrorType.Network) {
        setToastInfo({
          title: t('Notice'),
          content: t('Please check your connection.'),
          showCancel: false,
          confirmText: t('OK'),
          onOk: () => {
            error.callback && error.callback();
            clear();
          },
        });
      } else {
        setToastInfo(null);
      }
    } else {
      setToastInfo(null);
    }
  }, [clear, error, navigate, isPaidUser, t]);
  return (
    <>
      {children}
      {error && toastInfo && (
        <Toast
          title={toastInfo.title}
          visible={true}
          showCloseBtn={false}
          onOk={toastInfo.onOk || clear}
          onCancel={clear}
          showCancel={toastInfo.showCancel}
          confirmText={toastInfo.confirmText}
          cancelText={t('Cancel')}
        >
          {toastInfo.content}
        </Toast>
      )}
    </>
  );
}
