// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as GiftIcon} from 'assets/svg/3.0/Gift2.svg';
import {ReactComponent as StarIcon} from 'assets/svg/3.0/Star2.svg';
import classNames from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import styles from './DiscountCard.module.scss';
import {Props} from './DiscountCard.types';

export function DiscountCard({size}: Props) {
  const {t} = useTranslation('workspace');

  return (
    <div className={classNames(styles.container, styles[size])}>
      <div className={styles.box}>
        <StarIcon className={classNames(styles.star, styles.big)} />
        <StarIcon className={classNames(styles.star, styles.small)} />
        <p className={styles.title}>
          <GiftIcon className={styles.icon} />
          <span className={styles.text}>{t('Year-end sale')}</span>
        </p>
        <p className={styles.content}>
          <Trans
            ns="workspace"
            i18nKey={'Use code MOOTION2025 to get 20% off on all plans'}
            values={{code: 'MOOTION2025', off: '20%'}}
          >
            Use code <span className={styles.code}>MOOTION2025</span> to get{' '}
            <span className={styles.off}>20% off</span> on all plans!
          </Trans>
        </p>
      </div>
    </div>
  );
}
