import {ReactComponent as CoinIcon} from 'assets/svg/3.0/Coin.svg';
import {ReactComponent as LockIcon} from 'assets/svg/3.0/Lock.svg';
import {ReactComponent as TipIcon} from 'assets/svg/outline/Info.svg';
import classNames from 'classnames';
import {Option} from 'components/Option';
import {Popover} from 'components/PopoverNew';
import {TFunction} from 'i18next';
import {noop} from 'lodash';
import {AnimateModel} from 'modules/ai-frontend/types';
import {AnimateOptionTypeEnums} from 'modules/scene/utils';
import {ANIMATE_ADVANCE_PRICE, ANIMATE_PRICE} from 'modules/task/utils';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './AnimateSelect.module.scss';
import {AnimateInfo, Props} from './AnimateSelect.types';

export const AnimateSelect = forwardRef<
  {getValidData: () => AnimateInfo},
  Props
>((props, ref) => {
  const [optionValue, setOptionValue] = useState<AnimateModel>(
    props.initAnimatePrompt === undefined
      ? AnimateOptionTypeEnums.Base
      : AnimateOptionTypeEnums.Vira
  );
  const [textareaValue, setTextareaValue] = useState(
    props.initAnimatePrompt || ''
  );
  const {t} = useTranslation('project');

  useImperativeHandle(
    ref,
    () => ({
      getValidData: () => ({
        model: optionValue,
        prompt:
          optionValue === AnimateOptionTypeEnums.Base
            ? undefined
            : textareaValue.trim(),
      }),
    }),
    [optionValue, textareaValue]
  );

  const onChangeOptionValue = useCallback(
    (value: AnimateModel) => {
      setOptionValue(value);
      props.onChangeOptionValue && props.onChangeOptionValue(value);
    },
    [props]
  );

  return (
    <div className={styles['animate-content']}>
      <div className={styles['option-wrap']}>
        <Option
          label={
            <>
              {t('Base')}
              {props.disableAdvanceOption === true ? null : (
                <CreditInfo num={ANIMATE_PRICE} t={t} />
              )}
              <span className={styles['tip-icon']}>
                <Popover
                  triggerElement={<TipIcon />}
                  popoverClassName={styles['tip-popover']}
                  trigger={'hover'}
                  direction="right"
                  distance={7}
                  content={
                    <div className={styles['tip-content']}>
                      {t('Cost effective, best for normal tasks')}
                    </div>
                  }
                />
              </span>
            </>
          }
          isDisabled={false}
          isChecked={optionValue === AnimateOptionTypeEnums.Base}
          onCheck={() => onChangeOptionValue(AnimateOptionTypeEnums.Base)}
        />
        {props.type === 'animate' ? (
          <AnimateAdvancedOption
            isLock={props.disableAdvanceOption ?? false}
            optionValue={optionValue}
            onChangeOptionValue={onChangeOptionValue}
            t={t}
          />
        ) : (
          <AnimateAllAdvanceOption
            isLock
            onChangeOptionValue={noop}
            optionValue={optionValue}
            t={t}
          />
        )}
      </div>
      {optionValue === AnimateOptionTypeEnums.Vira && (
        <div className={styles['animate-textarea-container']}>
          <textarea
            className={styles.textarea}
            placeholder={t(
              '(optional) Describe the details or actions in the scene.'
            )}
            maxLength={400}
            value={textareaValue}
            onChange={e => setTextareaValue(e.target.value)}
          />
          <div className={styles.counter}>
            <span
              className={classNames(
                styles.circle,
                400 - textareaValue.length === 0 && styles['red']
              )}
            />
            <span className={styles.counterText}>
              {400 - textareaValue.length}
            </span>
          </div>
        </div>
      )}
    </div>
  );
});

AnimateSelect.displayName = 'AnimateSelect';

function CreditInfo({num, t}: {num: number; t: TFunction}) {
  return (
    <span className="credit-tag credit-tag-ml2">
      <CoinIcon className={styles.icon} />
      {num}
      <span className="credit-tag-slash">/</span>
      <span className="credit-tag-text">{t('scene')}</span>
    </span>
  );
}
function AnimateAdvancedOption(props: {
  isLock: boolean;
  optionValue: AnimateModel;
  onChangeOptionValue: (value: AnimateModel) => void;
  t: TFunction;
}) {
  const {t, isLock, optionValue, onChangeOptionValue} = props;
  const advanceLabel = useMemo(
    () => (
      <>
        {t('Advanced')}
        {isLock ? null : <CreditInfo num={ANIMATE_ADVANCE_PRICE} t={t} />}
        <span className={styles['tip-icon']}>
          <Popover
            triggerElement={<TipIcon />}
            popoverClassName={styles['tip-popover']}
            trigger={'hover'}
            direction="right"
            distance={7}
            content={
              <div className={styles['tip-content']}>
                {isLock
                  ? t(
                      'Upgrade to use our advanced video gen model specialized in motion & consistency'
                    )
                  : t(
                      'Our advanced video gen model specialized in motion & consistency'
                    )}
              </div>
            }
          />
        </span>
      </>
    ),
    [isLock, t]
  );
  return isLock ? (
    <span className={styles['disable-option']}>
      <LockIcon />
      {advanceLabel}
    </span>
  ) : (
    <Option
      isDisabled={false}
      label={advanceLabel}
      isChecked={optionValue === AnimateOptionTypeEnums.Vira}
      onCheck={() => onChangeOptionValue(AnimateOptionTypeEnums.Vira)}
    />
  );
}
function AnimateAllAdvanceOption(props: {
  isLock: boolean;
  optionValue: AnimateModel;
  onChangeOptionValue: (value: AnimateModel) => void;
  t: TFunction;
}) {
  const {t, isLock, optionValue, onChangeOptionValue} = props;
  return (
    <Option
      isDisabled={isLock}
      label={
        <span className={styles.disabled}>
          {t('Advanced')}
          <span
            className={classNames(
              'credit-tag credit-tag-ml2',
              styles['disabled-credit-tag']
            )}
          >
            {t('coming soon')}
          </span>
        </span>
      }
      isChecked={optionValue === AnimateOptionTypeEnums.Vira}
      onCheck={() => onChangeOptionValue(AnimateOptionTypeEnums.Vira)}
    />
  );
}
