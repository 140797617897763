// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useResourceManager} from 'contexts/ResourceManager';
import {patch as patchPreference} from 'modules/preference/models/Preference';
import {ProjectType} from 'modules/project/types';
import {BilingualDialogueProjectConfig} from 'modules/project-config/models/BilingualDialogueProjectConfig';
import {BilingualStoryProjectConfig} from 'modules/project-config/models/BilingualStoryProjectConfig';
import {ProjectConfig} from 'modules/project-config/models/ProjectConfig';
import {ShortVideoProjectConfig} from 'modules/project-config/models/ShortVideoProjectConfig';
import {useCallback, useMemo} from 'react';

import {ConfigPage} from './ConfigPage';
import {HookParams, HookReturn} from './ConfigPage.types';

function useHook({
  nativeLanguage,
  updateConfig,
  onNext,
  updatePreference,
  ...params
}: HookParams): HookReturn {
  const {
    bgmsOf,
    voiceoversOf,
    titleStylesOf,
    getTransitions,
    getBilingualStoryVoiceover,
    subtitleStylesOf,
    thumbnailTypesOf,
    getTitleBilingualCombinationsByLanguage,
    getSubtitleBilingualCombinationsByLanguage,
  } = useResourceManager();
  const language = params.language;

  const titleStyleCombination = useMemo(() => {
    return getTitleBilingualCombinationsByLanguage(
      params.language,
      nativeLanguage
    );
  }, [
    getTitleBilingualCombinationsByLanguage,
    nativeLanguage,
    params.language,
  ]);
  const subtitleStyleCombination = useMemo(() => {
    return getSubtitleBilingualCombinationsByLanguage(
      params.language,
      nativeLanguage
    );
  }, [
    getSubtitleBilingualCombinationsByLanguage,
    nativeLanguage,
    params.language,
  ]);

  const titleStyleList = useMemo(
    () => titleStylesOf(language),
    [language, titleStylesOf]
  );

  const subtitleStyleList = useMemo(
    () => subtitleStylesOf(language),
    [language, subtitleStylesOf]
  );
  const voiceoverList = useMemo(() => {
    if (
      params.config instanceof BilingualStoryProjectConfig ||
      params.config instanceof BilingualDialogueProjectConfig
    ) {
      return getBilingualStoryVoiceover();
    }
    return voiceoversOf(language);
  }, [getBilingualStoryVoiceover, language, params.config, voiceoversOf]);

  const bgmList = useMemo(() => {
    const bgms = bgmsOf(
      params.config instanceof BilingualStoryProjectConfig ||
        params.config instanceof BilingualDialogueProjectConfig
        ? 'BILINGUAL'
        : 'ALL'
    );
    if (params.config instanceof ShortVideoProjectConfig) {
      return bgms.filter(bgm => bgm.flow !== 'Slow');
    }
    return bgms;
  }, [bgmsOf, params.config]);

  const thumbnailTypes = useMemo(
    () => thumbnailTypesOf(language),
    [language, thumbnailTypesOf]
  );
  const transitions = useMemo(() => {
    const transitions = getTransitions();
    if (params.config instanceof ShortVideoProjectConfig) {
      return transitions.filter(transition => transition.flow !== 'Slow');
    }
    return transitions;
  }, [getTransitions, params.config]);

  const updateConfigAndPreference = useCallback(
    async <T extends ProjectConfig<ProjectType>, K extends keyof T>(
      key: K,
      value: T[K]
    ) => {
      updateConfig(params.config.patch({[key]: value}), false);
      updatePreference(
        preference => patchPreference(preference, {[key]: value}),
        true
      );
    },
    [params.config, updateConfig, updatePreference]
  );

  return {
    ...params,
    bgmList,
    titleStyleCombination,
    subtitleStyleCombination,
    subtitleStyleList,
    thumbnailTypes,
    titleStyleList,
    transitions,
    voiceoverList,
    onNext,
    updateConfigAndPreference,
  };
}

export const ConfigPageContainer = combine(useHook, [
  'config',
  'language',
  'ratio',
  'thumbnail',
  'title',
  'updateConfig',
  'onNext',
  'nativeLanguage',
  'updatePreference',
])(ConfigPage);
