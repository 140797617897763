// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import Cookie from 'js-cookie';

enum LocalStorageKey {
  UserToken = 'ut',
  DeviceId = 'di',
  OutTradeNo = 'otn',
  NotShowDialogAgain = 'nsda',
  PreProject = 'pp',
}

enum CookieKey {
  UserToken = 'ut',
  CampaignId = 'ci',
  ActiveUserId = 'au',
  ReferralCode = 'rc',
  Language = 'lang',
}

function cookieDomain() {
  return process.env.REACT_APP_ENV === 'dev'
    ? null
    : process.env.REACT_APP_BASE_DOMAIN;
}

export function getCampaignId() {
  return Cookie.get(CookieKey.CampaignId);
}

export function clearCampaignId() {
  const domain = cookieDomain();
  Cookie.remove(CookieKey.CampaignId, domain ? {domain: `.${domain}`} : {});
}

export function getActiveUserId() {
  return Cookie.get(CookieKey.ActiveUserId);
}

export function setActiveUserId(userId: string) {
  const domain = cookieDomain();
  const now = new Date();
  const tenMinutesLater = new Date(now.getTime() + 10 * 60 * 1000);

  // 设置用户活跃缓存一小时有效
  Cookie.set(CookieKey.ActiveUserId, userId, {
    ...(domain
      ? {domain: `.${domain}`, expires: tenMinutesLater}
      : {expires: tenMinutesLater}),
  });
}

export function getReferralCode() {
  return Cookie.get(CookieKey.ReferralCode);
}

export function setReferralCode(referralCode: string) {
  const domain = cookieDomain();
  Cookie.set(CookieKey.ReferralCode, referralCode, {
    ...(domain ? {domain: `.${domain}`} : {}),
  });
}

export function clearReferralCode() {
  const domain = cookieDomain();
  Cookie.remove(CookieKey.ReferralCode, domain ? {domain: `.${domain}`} : {});
}

export function setUserToken(token: string) {
  const domain = cookieDomain();
  Cookie.set(CookieKey.UserToken, token, {
    expires: 30,
    ...(domain ? {domain: `.${domain}`} : {}),
  });
}

export function getUserToken() {
  const token = Cookie.get(CookieKey.UserToken);
  if (!token) {
    const t = localStorage.getItem(LocalStorageKey.UserToken);
    if (t) {
      setUserToken(t);
      localStorage.removeItem(LocalStorageKey.UserToken);
    }
    return t;
  }
  return token;
}

export function clearUserToken() {
  const domain = cookieDomain();
  Cookie.remove(CookieKey.UserToken, domain ? {domain: `.${domain}`} : {});
}

export function setNotShowDialogAgain() {
  localStorage.setItem(LocalStorageKey.NotShowDialogAgain, 'true');
}

export function getNotShowDialogAgain() {
  return localStorage.getItem(LocalStorageKey.NotShowDialogAgain);
}

export function clearNotShowDialogAgain() {
  localStorage.removeItem(LocalStorageKey.NotShowDialogAgain);
}

export function getPreProject() {
  return localStorage.getItem(LocalStorageKey.PreProject);
}

export function setPreProject(project: string) {
  localStorage.setItem(LocalStorageKey.PreProject, project);
}

export function clearPreProject() {
  localStorage.removeItem(LocalStorageKey.PreProject);
}

export function setLanguage(lang: string) {
  const domain = cookieDomain();
  Cookie.set(CookieKey.Language, lang, {
    ...(domain ? {domain: `.${domain}`} : {}),
  });
}

export function getLanguage() {
  return Cookie.get(CookieKey.Language);
}
