// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as WarningIcon} from 'assets/svg/3.0/Warning.svg';
import {ReactComponent as TipIcon} from 'assets/svg/outline/Info.svg';
import classNames from 'classnames';
import {BackToTop} from 'components/BackToTop';
import {Popover} from 'components/PopoverNew';
import {InfoMap, PromptEditor} from 'components/PromptEditor';
import {PromptTag} from 'components/PromptTag';
import {useResourceManager} from 'contexts/ResourceManager';
import {useUserContext} from 'contexts/UserContext';
import {TFunction} from 'i18next';
import {useScrollTop, useVisible} from 'lib/hooks';
import {patch as patchConstraint} from 'modules/constraint/models/Constraint';
import {getConstraintByProjectAndType} from 'modules/constraint/utils';
import {BilingualDialogueProject} from 'modules/project/models/BilingualDialogueProject';
import {BilingualStoryProject} from 'modules/project/models/BilingualStoryProject';
import {GeneralStoryProject} from 'modules/project/models/GeneralStoryProject';
import {patch as patchProject, Project} from 'modules/project/models/Project';
import {
  BaseIdeaPromptPolicy,
  BilingualDialogueIdeaPromptPolicy,
  BilingualStoryIdeaPromptPolicy,
  GeneralStoryIdeaPromptPolicy,
  ScriptPromptPolicy,
  ShortVideoIdeaPromptPolicy,
} from 'modules/project/models/PromptPolicy';
import {ShortVideoProject} from 'modules/project/models/ShortVideoProject';
import {ProjectType} from 'modules/project/types';
import {getPromptPolicyByProjectAndPromptType} from 'modules/project/utils';
import {CheckBox} from 'pages/components/CheckBox';
import {ConfigCard} from 'pages/components/ConfigCard';
import {GenerateHeader} from 'pages/components/GenerateHeader';
import {InfoLabel} from 'pages/components/InfoLabel';
import {LanguageSelect} from 'pages/components/LanguageSelect';
import {RatioSelect} from 'pages/components/RatioSelect';
import {
  CharacterSelect,
  NativeLanguageSelect,
  ProficiencyLevelSelect,
  TargetLanguageSelect,
  ToneSelect,
} from 'pages/components/SelectWithoutLabel';
import {StylesBox} from 'pages/components/StylesBox';
import {Toast} from 'pages/components/Toast';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {generateIdPath, PLAN_PAGE_PATH, PROJECT_PATH} from 'utils/path';
import {getPromptTags} from 'utils/prompt';

import styles from './PromptPage.module.scss';
import {Props} from './PromptPage.types';
import {Vocabulary} from './Vocabulary';

export function PromptPage<T extends ProjectType>({
  project,
  showStyleLockedToast = true,
  updateProject,
  onChange,
  onGenerate,
  preference,
}: Props<T>) {
  const {t} = useTranslation('project');
  const containerRef = useRef<HTMLDivElement>(null);
  const [toastVisible, hideToast, showToast, errorType] = useVisible();
  const {
    userInfo: {userId, plan},
  } = useUserContext();
  const {resUrl, getStyles} = useResourceManager();
  const [isScrollTop] = useScrollTop(containerRef.current);
  const [promptInfoMap, setPromptInfoMap] = useState<InfoMap>({
    'paragraphs-character-number': [],
    'paragraph-number': 0,
    'prompt-character-number': 0,
  });

  const onFocusRef = useRef<(() => void) | null>(null);

  const navigate = useNavigate();
  const onChangeTab = useCallback(
    (tab: 'idea' | 'content') => {
      if (!project.id) {
        navigate(
          `${generateIdPath(PROJECT_PATH, 'new')}?type=${
            project.type
          }&prompt_type=${tab === 'idea' ? 'idea' : 'script'}`,
          {replace: true}
        );
        return;
      } else {
        if (tab === 'idea') {
          updateProject(
            patchProject(project, {
              promptPolicy: getPromptPolicyByProjectAndPromptType(
                project,
                'idea',
                preference
              ),
              constraint: patchConstraint(
                project.constraint,
                getConstraintByProjectAndType(project.type, 'idea', plan)
              ),
            } as Partial<Project<T>>),
            false
          );
        } else {
          updateProject(
            patchProject(project, {
              promptPolicy: getPromptPolicyByProjectAndPromptType(
                project,
                'content',
                preference
              ),
              constraint: patchConstraint(
                project.constraint,
                getConstraintByProjectAndType(project.type, 'content', plan)
              ),
            } as Partial<Project<T>>),
            false
          );
        }
      }
    },
    [navigate, plan, preference, project, updateProject]
  );

  const onClickPromptTag = useCallback(
    (prompt: string) => {
      onChange('prompt', prompt);
      onFocusRef.current && onFocusRef.current();
    },
    [onChange]
  );

  const overlengthParagraphIndexes = useMemo(() => {
    return promptInfoMap['paragraphs-character-number'].reduce(
      (acc, cur, index) => {
        if (cur > project.constraint.sceneSubtitleLength.max) {
          acc.push(index + 1);
        }
        return acc;
      },
      [] as number[]
    );
  }, [project.constraint.sceneSubtitleLength.max, promptInfoMap]);

  const [isError, setIsError] = useState(false);

  const isGenerateDisabled = useMemo(() => {
    return (
      isError ||
      !project.prompt ||
      project.prompt.length === 0 ||
      project.prompt.length > project.constraint.promptLength.max ||
      (project.promptPolicy.isScriptPrompt &&
        project.promptPolicy instanceof ScriptPromptPolicy &&
        project.promptPolicy?.paragraphAsShots &&
        (overlengthParagraphIndexes.length > 0 ||
          promptInfoMap['paragraph-number'] >
            project.constraint.sceneQuantity.max))
    );
  }, [
    isError,
    overlengthParagraphIndexes.length,
    project.constraint.promptLength.max,
    project.constraint.sceneQuantity.max,
    project.prompt,
    project.promptPolicy,
    promptInfoMap,
  ]);

  const showUpgradeUnlockStyleBoxDialog = useCallback(() => {
    showStyleLockedToast && showToast('style_locked');
  }, [showStyleLockedToast, showToast]);

  const uiInfo = getUIInfoByProjectType(project, t);

  const onScrollToTop = () => {
    containerRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const [isShowBackToTop, setIsShowBackToTop] = useState(false);
  useEffect(() => {
    const setter = () => {
      const scrollHeight = containerRef.current?.scrollHeight ?? 0;
      const offsetHeight = containerRef.current?.offsetHeight ?? 0;
      setIsShowBackToTop(prev => {
        if (!prev) return scrollHeight > offsetHeight;
        return scrollHeight - offsetHeight > 76;
      });
    };
    setter();
    window.addEventListener('resize', setter);
    return () => window.removeEventListener('resize', setter);
  }, []);

  const tabsRef = useRef<HTMLDivElement>(null);
  const [tabsHeight, setTabsHeight] = useState(0);
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setTabsHeight(entry.target.clientHeight);
      });
    });
    if (!tabsRef.current) return;
    setTabsHeight(tabsRef.current.clientHeight);
    observer.observe(tabsRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);
  const QUICK_PACE_MAP: Partial<Record<ProjectType, string>> = {
    general_story: t(
      'Rapid and short cuts, great for TikTok ads, memes, challenges.'
    ),
    short_video: t('You can set quick pace option in general creation.'),
  };
  return (
    <div
      className={classNames(
        styles.container,
        project.promptPolicy.isScriptPrompt && styles['is-use-content'],
        !userId && styles['is-not-login'],
        'prompt-page-container'
      )}
      ref={containerRef}
    >
      <GenerateHeader
        isDisabled={isGenerateDisabled ?? false}
        isScrollTop={isScrollTop}
        onGenerate={onGenerate}
        languageCode={project.language}
        isLogin={!!userId}
      />
      <div className={styles.body}>
        <p className={classNames(styles.title, 'page-title')}>{uiInfo.title}</p>

        {(project instanceof BilingualStoryProject ||
          project instanceof BilingualDialogueProject) && (
          <ConfigCard>
            <InfoLabel label={null}>
              <div className={styles['flex-container']}>
                <div className={styles['select-wrap']}>
                  <span className={styles['select-label']}>
                    {t('Target language')}
                  </span>
                  <TargetLanguageSelect
                    value={project.language}
                    onChange={value => onChange('language', value)}
                  />
                </div>
                <div className={styles['select-wrap']}>
                  <span className={styles['select-label']}>
                    {t('Native language')}
                  </span>
                  <NativeLanguageSelect
                    value={project.nativeLanguage}
                    onChange={value => onChange('nativeLanguage', value)}
                    languageCode={project.language}
                  />
                </div>
              </div>
            </InfoLabel>
          </ConfigCard>
        )}

        <ConfigCard
          className={styles['no-header']}
          style={{borderTop: `${tabsHeight}px solid transparent`}}
        >
          <InfoLabel label={null} className={styles['no-header-wrapper']}>
            <Tabs
              activeTab={project.promptPolicy.isIdeaPrompt ? 'idea' : 'content'}
              onChange={onChangeTab}
              className={styles['no-header-header']}
              innerRef={tabsRef}
              hiddenTab={
                project instanceof BilingualDialogueProject
                  ? ['content']
                  : undefined
              }
            />
            <div className={styles['flex-column-container']}>
              <PromptEditor
                onChange={value => {
                  onChange('prompt', value);
                }}
                value={project.prompt ?? ''}
                setInfoMap={setPromptInfoMap}
                maxCharacterCount={project.constraint.promptLength.max}
                maxParagraphCount={
                  (project.promptPolicy as ScriptPromptPolicy).paragraphAsShots
                    ? project.constraint.sceneQuantity.max
                    : undefined
                }
                maxParagraphCharacterCount={
                  (project.promptPolicy as ScriptPromptPolicy).paragraphAsShots
                    ? project.constraint.sceneSubtitleLength.max
                    : undefined
                }
                placeholder={uiInfo.promptEditorPlaceholder}
                onFocusCallBack={onFocus => (onFocusRef.current = onFocus)}
                setIsError={setIsError}
                className={getClassName(
                  project,
                  project.promptPolicy instanceof ScriptPromptPolicy
                    ? 'script'
                    : 'idea'
                )}
              >
                {(project.promptPolicy instanceof
                  GeneralStoryIdeaPromptPolicy ||
                  project.promptPolicy instanceof
                    ShortVideoIdeaPromptPolicy) && (
                  <CheckBox
                    label={t('Quick pace')}
                    tip={
                      <span className={styles['quick-pace-span']}>
                        <Popover
                          triggerElement={
                            <TipIcon
                              className={styles['quick-pace-span-icon']}
                            />
                          }
                          trigger={['hover']}
                          direction="top"
                          distance={7}
                          content={
                            <div
                              className={styles['quick-pace-span-tip-content']}
                            >
                              {QUICK_PACE_MAP[project.type]}
                            </div>
                          }
                          scrollElement={containerRef.current ?? undefined}
                        />
                      </span>
                    }
                    isChecked={
                      project instanceof ShortVideoProject
                        ? true
                        : (project.promptPolicy as GeneralStoryIdeaPromptPolicy)
                            .quickPace
                    }
                    disabled={project instanceof ShortVideoProject}
                    onCheck={value => onChange('quickPace', value)}
                  />
                )}
                {project.promptPolicy instanceof ScriptPromptPolicy && (
                  <CheckBox
                    label={t('Per paragraph as a scene')}
                    isChecked={project.promptPolicy.paragraphAsShots}
                    onCheck={value => onChange('paragraphAsShots', value)}
                  />
                )}
              </PromptEditor>
              <ul className={styles.errors}>
                {(project.prompt?.length ?? 0) >
                  project.constraint.promptLength.max && (
                  <li className={styles.error}>
                    <WarningIcon className={styles.icon} />
                    {t(
                      'You are reaching the limit (max characters in prompt).',
                      {max: project.constraint.promptLength.max}
                    )}
                  </li>
                )}
                {project.promptPolicy instanceof ScriptPromptPolicy &&
                  project.promptPolicy.paragraphAsShots &&
                  overlengthParagraphIndexes.length > 0 && (
                    <li className={styles.error}>
                      <WarningIcon className={styles.icon} />
                      {t(
                        'Paragraph exceeds the limit (300 characters per scene).',
                        {paragraphs: overlengthParagraphIndexes.join(',')}
                      )}
                    </li>
                  )}
                {project.promptPolicy instanceof ScriptPromptPolicy &&
                  project.promptPolicy.paragraphAsShots &&
                  promptInfoMap['paragraph-number'] >
                    project.constraint.sceneQuantity.max && (
                    <li className={styles.error}>
                      <WarningIcon className={styles.icon} />
                      {t(
                        'You are reaching the limit (max scenes in one creation).',
                        {
                          max: project.constraint.sceneQuantity.max,
                        }
                      )}
                    </li>
                  )}
              </ul>
              {project.promptPolicy instanceof BaseIdeaPromptPolicy ? (
                <PromptTag
                  tags={getPromptTags(project.type)}
                  onClick={onClickPromptTag}
                />
              ) : null}
            </div>

            {(project instanceof GeneralStoryProject ||
              project instanceof ShortVideoProject) && (
              <div className={styles['flex-container']}>
                <div className={styles['select-wrap']}>
                  <span className={styles['select-label']}>
                    {t('Output language')}
                  </span>
                  <LanguageSelect
                    value={project.language}
                    onChange={value => onChange('language', value)}
                  />
                </div>
                <div className={styles['select-wrap']}>
                  {project.promptPolicy instanceof BaseIdeaPromptPolicy && (
                    <>
                      <span className={styles['select-label']}>
                        {t('Tone')}
                      </span>
                      <ToneSelect
                        value={project.promptPolicy.tone}
                        onChange={value => onChange('tone', value)}
                      />
                    </>
                  )}
                </div>
              </div>
            )}

            {(project instanceof BilingualStoryProject ||
              project instanceof BilingualDialogueProject) && (
              <>
                {(project.promptPolicy instanceof
                  BilingualStoryIdeaPromptPolicy ||
                  project.promptPolicy instanceof
                    BilingualDialogueIdeaPromptPolicy) && (
                  <div className={styles['flex-container']}>
                    <div className={styles['select-wrap']}>
                      <span className={styles['select-label']}>
                        {t('Proficiency level')}
                        <Popover
                          triggerElement={
                            <TipIcon width={16} height={16} fill="#56565c" />
                          }
                          trigger={['hover']}
                          direction="top"
                          distance={7}
                          content={
                            <div
                              className={styles['tip-content-vocabulary']}
                              style={{width: 209}}
                            >
                              {t('We use CEFR levels for reference')}
                            </div>
                          }
                          scrollElement={containerRef.current ?? undefined}
                        />
                      </span>
                      <ProficiencyLevelSelect
                        value={project.promptPolicy.proficiencyLevel}
                        onChange={value => onChange('proficiencyLevel', value)}
                      />
                    </div>
                    <div className={styles['select-wrap']}>
                      {project instanceof BilingualDialogueProject && (
                        <>
                          <span className={styles['select-label']}>
                            {t('Character')}
                          </span>
                          <CharacterSelect
                            value={project.characters}
                            onChange={value => onChange('characters', value)}
                          ></CharacterSelect>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className={styles['select-wrap']}>
                  <span className={styles['select-label']}>
                    {t('Vocabulary')}&nbsp;
                    <span className={styles['select-label-optional']}>
                      {t('(optional)')}&nbsp;
                      <Popover
                        triggerElement={
                          <TipIcon
                            className={styles['select-label-optional-icon']}
                          />
                        }
                        trigger={['hover']}
                        direction="top"
                        distance={6}
                        content={
                          <div className={styles['tip-content-vocabulary']}>
                            {t(
                              'Vocabularies will be emphasized and highlighted in the video for better learning experience.'
                            )}
                          </div>
                        }
                        scrollElement={containerRef.current ?? undefined}
                      />
                    </span>
                  </span>
                  <Vocabulary
                    vocabulary={project.vocabulary || []}
                    onChange={value => onChange('vocabulary', value)}
                    maxLength={project.constraint.vocabularyPhraseLength.max}
                    maxCount={project.constraint.vocabularyPhraseQuantity.max}
                  />
                </div>
              </>
            )}
          </InfoLabel>
        </ConfigCard>
        <ConfigCard>
          <InfoLabel label={null}>
            <div className={styles['select-wrap']}>
              <span className={styles['select-label']}>{t('Ratio')}</span>
              <RatioSelect
                value={project.size}
                onChange={value => onChange('size', value)}
              />
            </div>
            <div className={styles['select-wrap']}>
              <span className={styles['select-label']}>
                {t('Visual style')}
              </span>
              <StylesBox
                value={project.style}
                resUrl={resUrl}
                styleList={getStyles(['project'])}
                onChange={value => onChange('style', value)}
                showUpgradeUnlockStyleBoxDialog={
                  showUpgradeUnlockStyleBoxDialog
                }
              />
            </div>
          </InfoLabel>
        </ConfigCard>
        {isShowBackToTop && <BackToTop onBackToTop={onScrollToTop} />}
      </div>

      <Toast
        title={t('Upgrade to unlock')}
        visible={toastVisible && errorType === 'style_locked'}
        confirmText={t('Upgrade')}
        onOk={() => navigate(PLAN_PAGE_PATH)}
        onCancel={hideToast}
        maskClosable={false}
        cancelText={t("I'm OK")}
      >
        {t('Upgrade your plan to enjoy more styles.')}
      </Toast>
    </div>
  );
}

export function getUIInfoByProjectType<T extends ProjectType>(
  project: Project<T>,
  t: TFunction
) {
  const res: {promptEditorPlaceholder: string; title: string} = {
    promptEditorPlaceholder: '',
    title: '',
  };
  if (project.promptPolicy instanceof BaseIdeaPromptPolicy) {
    res.promptEditorPlaceholder = t(
      'Type in your ideas or paste reference content.'
    );
    if (project instanceof GeneralStoryProject) {
      res.title = t('General creation');
    } else if (project instanceof ShortVideoProject) {
      res.title = t('Faceless short videos');
    } else if (project instanceof BilingualStoryProject) {
      res.title = t('Bilingual story');
    } else if (project instanceof BilingualDialogueProject) {
      res.title = t('Bilingual dialogue');
    } else {
      throw new Error('Unsupported project type');
    }
  } else if (project.promptPolicy instanceof ScriptPromptPolicy) {
    res.promptEditorPlaceholder = t(
      'Paste your script, keep your content in the new creation.'
    );
    if (project instanceof GeneralStoryProject) {
      res.title = t('General creation');
    } else if (project instanceof ShortVideoProject) {
      res.title = t('Faceless short videos');
    } else if (project instanceof BilingualStoryProject) {
      res.title = t('Bilingual story');
      res.promptEditorPlaceholder = t(
        'Paste your script, keep your content in the new creation. Target language preferred.'
      );
    } else {
      throw new Error('Unsupported project type');
    }
  } else {
    throw new Error('Unsupported project type');
  }
  return res;
}

function Tabs({
  activeTab,
  className,
  onChange,
  hiddenTab,
  innerRef,
}: {
  activeTab: string;
  className?: string;
  onChange: (tab: 'idea' | 'content') => void;
  hiddenTab?: ('idea' | 'content')[];
  innerRef: React.RefObject<HTMLDivElement>;
}) {
  const {t} = useTranslation('project');
  const TabList: {label: ReactNode; value: 'idea' | 'content'}[] = useMemo(
    () => [
      {
        label: (
          <span className={styles['flex-container-label']}>
            {t('Generate from prompt')}
          </span>
        ),
        value: 'idea',
      },
      {
        label: (
          <span className={styles['flex-container-label']}>
            {t('Use my content')}
          </span>
        ),
        value: 'content',
      },
    ],
    [t]
  );
  const showTabList = useMemo(() => {
    if (!hiddenTab || !hiddenTab.length) return TabList;
    return TabList.filter(tab => !hiddenTab.includes(tab.value));
  }, [hiddenTab, TabList]);
  return (
    <div className={classNames(styles.tabs, className)} ref={innerRef}>
      {showTabList.map(tab => (
        <span
          key={tab.value}
          className={classNames(
            styles['tab-label'],
            activeTab === tab.value && styles['tab-label-active'],
            showTabList.length === 1 && styles['tab-label-default']
          )}
          onClick={() => onChange(tab.value)}
        >
          {tab.label}
        </span>
      ))}
    </div>
  );
}

function getClassName(
  project: Project<ProjectType>,
  promptType: 'idea' | 'script'
) {
  if (
    (project instanceof BilingualStoryProject ||
      project instanceof BilingualDialogueProject) &&
    promptType === 'idea'
  ) {
    return 'rtl-bilingual-element';
  } else {
    return 'rtl-element';
  }
}
