// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CreditIcon} from 'assets/svg/3.0/CreditIcon.svg';
import {useTranslation} from 'react-i18next';

import {Card} from '../Card';
import {getLevelByProductId, getTypeByProductId} from '../Plan.container';
import {Props} from './SubscriptionCard.types';

export function SubscriptionCard({
  credit,
  creditPack,
  creditAmount,
  activeProductId,
  subscriptionProductId,
  renewalDate,
}: Props) {
  const currentPlan = getLevelByProductId(activeProductId);
  const nextPlan = getLevelByProductId(subscriptionProductId);
  const {t} = useTranslation('plan');

  return (
    <Card
      rows={[
        {
          label: t('Your subscription'),
        },
        {
          label: t('Current plan'),
          value: `${currentPlan} plan`,
        },
        {
          label: t('Remaining credits'),
          value: (
            <>
              <CreditIcon />
              {credit + creditPack} / {creditAmount}
            </>
          ),
        },
      ]}
      next={
        activeProductId !== subscriptionProductId
          ? t('nextPlan will be automatically updated on renewalDate', {
              nextPlan: `${nextPlan} Plan ${
                nextPlan === 'Free'
                  ? ''
                  : `(${t(getTypeByProductId(subscriptionProductId))})`
              }`,
              renewalDate,
            })
          : undefined
      }
    />
  );
}
