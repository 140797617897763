// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CoinIcon} from 'assets/svg/3.0/Coin.svg';
import {ReactComponent as LockedIcon} from 'assets/svg/outline/Locked.svg';
import classNames from 'classnames';
import {UpgradeUnlockStyleBoxDialog} from 'components/UpgradeUnlockStyleBox';
import {useUserContext} from 'contexts/UserContext';
import {useVisible} from 'lib/hooks';
import {Button} from 'pages/components/Button';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'utils/path';

import {StyleTransferPanelProps} from '../AssetEditor.types';
import styles from './Panel.module.scss';

//Transfer永远是用没有style的图（应用了裁切或去背景）
export function StyleTransferPanel({
  resUrl,
  styleList,
  handleTransferStyle,
  style,
  setStyle,
}: StyleTransferPanelProps) {
  const [visible, hide, show] = useVisible();
  const navigate = useNavigate();
  const {
    userInfo: {plan},
  } = useUserContext();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const canuse = (e.currentTarget as HTMLDivElement)?.dataset.canuse;
    if (canuse === 'true') {
      const style = (e.currentTarget as HTMLDivElement)?.dataset.style;
      setStyle(style || '');
    } else {
      show();
    }
  };
  const onTransfer = () => {
    handleTransferStyle && handleTransferStyle(style);
  };

  const {t} = useTranslation('workspace');
  return (
    <div className={styles['action-wrap']}>
      <span className={styles['action-wrap-title']}>{t('Style Transfer')}</span>
      <div className={styles['action-wrap-items-styleTransfer']}>
        {styleList.map(item => {
          const imageDivStyle = {
            '--style-transfer-image': `url(${resUrl + item.image})`,
          } as React.CSSProperties;
          const canUse = item.supported_plans.includes(plan);
          return (
            <div
              key={item.id}
              data-style={item.name}
              data-canuse={canUse}
              onClick={handleClick}
              className={classNames(styles['action-wrap-item-styleTransfer'], {
                [styles['action-wrap-item-styleTransfer-active']]:
                  item.name === style,
                [styles['action-wrap-item-styleTransfer-disabled']]: !canUse,
              })}
            >
              <div
                className={styles['action-wrap-item-styleTransfer-image']}
                style={imageDivStyle}
              />
              <div className={styles['action-wrap-item-styleTransfer-label']}>
                {item.display_name}
              </div>
              {!canUse && <LockedIcon className={styles.locked} />}
            </div>
          );
        })}
      </div>

      <Button
        className={styles['action-wrap-btn']}
        theme="primary"
        type="button"
        onClick={onTransfer}
      >
        {t('Transfer')}
        <span className={styles.credits}>
          <CoinIcon className={styles.icon} /> 2
        </span>
      </Button>
      <UpgradeUnlockStyleBoxDialog
        onCancel={hide}
        onCloseDialog={hide}
        onUpgrade={() => {
          navigate(PLAN_PAGE_PATH);
        }}
        dialogShowing={visible}
      />
    </div>
  );
}
