// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import defaultDoodle from 'assets/images/default-doodle.png';
import {useAPI} from 'contexts/APIContext';
import {Doodle, DoodleType} from 'modules/doodle/types';
import {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useInterval} from 'utils/use-interval';

import styles from './DoodlePreviewer.module.scss';
import {PromotionDialog} from './PromotionDialog';

export function DoodlePreviewer() {
  const [doodle, setDoodle] = useState<Doodle<DoodleType> | null>();
  const [dialogVisible, setDialogVisible] = useState(false);
  const {backendClient} = useAPI();
  const fetch = useCallback(async () => {
    setDoodle(await backendClient.getDoodle().catch(() => null));
  }, [backendClient]);
  const {start, stop} = useInterval(fetch, 60000);
  useEffect(() => {
    start();
    return stop;
  }, [start, stop]);

  const [pos, setPos] = useState({left: 0, top: 0});

  if (doodle === undefined)
    return (
      <div className={styles.container}>
        <div className={styles.image}></div>
      </div>
    );

  switch (doodle?.type) {
    case DoodleType.DISPLAY:
      return (
        <div className={styles.container}>
          <div className={styles.image}>
            <img src={doodle.imageUrl} draggable={false} />
          </div>
        </div>
      );
    case DoodleType.INNER_LINK:
      return (
        <div className={styles.container}>
          <div className={styles.image}>
            <Link
              to={doodle.content.target}
              onClick={() => backendClient.reportClickBanner(doodle.id)}
            >
              <img src={doodle.imageUrl} draggable={false} />
            </Link>
          </div>
        </div>
      );
    case DoodleType.OUTER_LINK:
      return (
        <div className={styles.container}>
          <div className={styles.image}>
            <a
              href={doodle.content.target}
              rel="noreferrer"
              target="_blank"
              onClick={() => backendClient.reportClickBanner(doodle.id)}
            >
              <img src={doodle.imageUrl} draggable={false} />
            </a>
          </div>
        </div>
      );
    case DoodleType.PROMOTION_DIALOG:
      return (
        <div className={styles.container}>
          <div className={styles.image}>
            <img
              src={doodle.imageUrl}
              onClick={e => {
                setPos({left: e.pageX, top: e.pageY});
                backendClient.reportClickBanner(doodle.id);
                setDialogVisible(true);
              }}
              draggable={false}
            />
          </div>

          <PromotionDialog
            visible={dialogVisible}
            left={pos.left}
            top={pos.top}
            {...doodle.content}
            onClose={() => setDialogVisible(false)}
          />
        </div>
      );
    default:
      return (
        <div className={styles.container}>
          <div className={styles.image}>
            <img src={defaultDoodle} draggable={false} />
          </div>
        </div>
      );
  }
}
