// Copyright 2022 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {i18n} from 'i18n';
const t = i18n.t.bind(i18n);

function getDateMessage(time: string) {
  return t('time ago', {time});
}

export function dateToUpdateTime(date: Date) {
  const {year, month, day, hour, minute} = getTimeDifference(
    date.getTime(),
    Date.now()
  );

  let timeDifferenceMessage = '';

  if (year > 0) {
    timeDifferenceMessage = t('n years', {n: year});
  } else if (month > 0) {
    timeDifferenceMessage = t('n months', {n: month});
  } else if (day > 0) {
    timeDifferenceMessage = t('n days', {n: day});
  } else if (hour > 0) {
    timeDifferenceMessage = t('n hours', {n: hour});
  } else if (minute > 0) {
    timeDifferenceMessage = t('n minutes', {n: minute});
  } else {
    timeDifferenceMessage = t('just now');
  }

  if (timeDifferenceMessage !== t('just now')) {
    timeDifferenceMessage = getDateMessage(timeDifferenceMessage);
  }

  return timeDifferenceMessage;
}

export function getTimeDifference(
  startTimestamp: number,
  endTImestamp: number
) {
  if (startTimestamp >= endTImestamp) {
    return {year: 0, month: 0, day: 0, hour: 0, minute: 0};
  }

  const endDate = new Date(endTImestamp);
  const startDate = new Date(startTimestamp);

  const startDateReplica = new Date(startDate.getTime());
  startDateReplica.setDate(0);
  const startDateMonthDays = startDateReplica.getDate();

  const dayDiff = startDateMonthDays - startDate.getDate() + endDate.getDate();

  const yearDiff = endDate.getFullYear() - startDate.getFullYear();
  const monthDiff = endDate.getMonth() - startDate.getMonth();
  const hourDiff = endDate.getHours() - startDate.getHours();
  const minuteDiff = endDate.getMinutes() - startDate.getMinutes();

  let year = yearDiff;
  let month = monthDiff;
  let day = dayDiff;
  let hour = hourDiff;
  let minute = minuteDiff;

  if (minute < 0) {
    minute += 60;
    hour--;
  }

  if (hour < 0) {
    hour += 24;
    day--;
  }

  if (dayDiff === startDateMonthDays) {
    day = 0;
  } else if (dayDiff > startDateMonthDays) {
    day = dayDiff - startDateMonthDays;
  }

  if (startDate.getDate() > endDate.getDate()) {
    month--;
  }

  if (month < 0) {
    month += 12;
    year--;
  }

  return {
    year,
    month,
    day,
    hour,
    minute,
  };
}

export function formatTimestamp(second: number) {
  const minutes = Math.floor(second / 60);
  const seconds = Math.floor(second % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

export function convertUTCToLocaleDateString(utcDate: Date) {
  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
  );
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const formattedDate = localDate.toLocaleDateString(i18n.language, options);

  return formattedDate;
}
