// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Button} from 'components/Button';
import {Card} from 'components/CompositionPage/ResultCard/Card';
import {CheckBox} from 'pages/components/CheckBox';
import {Toast} from 'pages/components/Toast';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'utils/path';

import styles from './VideoDownloadToast.module.scss';
import {Props} from './VideoDownloadToast.types';

export function VideoDownloadToast({
  isVisible,
  outTradeNo,
  onCancel,
  onConfirm,
}: Props) {
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(
    typeof outTradeNo === 'string' ? true : false
  );

  const navigate = useNavigate();

  const {t} = useTranslation('workspace');

  return (
    <Toast
      visible={isVisible}
      title={t('Download video')}
      showFooter={false}
      className={styles.container}
      onCancel={onCancel}
      showCloseBtn={true}
    >
      <div className={styles.toastContent}>
        <Card
          content={t('Subscribe to unlock all features & download')}
          isActive
          btnText={t('Subscribe')}
          onBtnClick={() => {
            navigate(PLAN_PAGE_PATH);
          }}
        ></Card>
        <div className={styles.text}>{t('or')}</div>
        <Card
          content={
            <>
              <div className={styles.right}>
                <CheckBox
                  label={t('$0.99 without watermark')}
                  contentClassNames={styles.content}
                  isChecked={isChecked}
                  onCheck={setIsChecked}
                  className={styles.checkbox}
                />
              </div>
            </>
          }
          button={
            <Button
              className={styles.button}
              isLoading={isStripeLoading}
              disabled={isStripeLoading}
              onClick={async () => {
                try {
                  isChecked && setIsStripeLoading(true);
                  await onConfirm(isChecked);
                  !isChecked && onCancel();
                } catch {
                  setIsStripeLoading(false);
                }
              }}
              icon={<></>}
              size="middle"
              type="ghost"
            >
              {isStripeLoading ? t('Paying') : t('Download')}
            </Button>
          }
        ></Card>
      </div>
    </Toast>
  );
}
