// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as payment from 'api/payment';
import {useUserContext} from 'contexts/UserContext';
import {useCallback} from 'react';
import {createPlanPaymentCallbackUrl} from 'utils/stripe';

export function usePayment() {
  const {
    userInfo: {userId},
  } = useUserContext();

  const subscribe = useCallback(
    async (productId: number) => {
      const {
        data: {priceId, outTradeNo},
      } = await payment.initiatePrepayment(userId, productId);
      const {successUrl, failUrl} = createPlanPaymentCallbackUrl(
        outTradeNo,
        productId
      );
      const {data} = await payment.checkoutSession({
        price: priceId,
        user_id: userId,
        out_trade_no: outTradeNo,
        success_url: successUrl,
        cancel_url: failUrl,
        is_subscription: isSubscription(productId),
      });

      return data;
    },
    [userId]
  );

  const upgradePlan = useCallback(
    async (curProductId: number, newProductId: number, promoCode?: string) => {
      const {data: clientSecret} = await payment.upgrade(
        curProductId,
        newProductId,
        userId,
        promoCode
      );
      return clientSecret;
    },
    [userId]
  );

  const downgradePlan = useCallback(
    async (currentProductId: number, newProductId: number) => {
      await payment.downgrade(currentProductId, newProductId, userId);
    },
    [userId]
  );
  return {subscribe, upgradePlan, downgradePlan};
}
//productId < 7代表是订阅类型的产品，productId为7、8、9代表的是购买信用点的产品
export function isSubscription(productId: number) {
  return productId < 7;
}
