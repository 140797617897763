// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  EmailErrorType,
  ErrorMessageProps,
  ErrorToastEnumType,
  PasswordErrorType,
} from './AuthForm.types';

export function EmailErrorMessage({
  errorType,
  className,
}: ErrorMessageProps<EmailErrorType>) {
  const {t} = useTranslation('auth');
  const EMAIL_ERROR_MAP: Record<EmailErrorType, JSX.Element> = useMemo(
    () => ({
      USER_NOT_FOUND: (
        <p key="USER_NOT_FOUND" className={className}>
          {t('We could not find an account with the email.')}
        </p>
      ),
      EMAIL_HAD_REGISTER_YET: (
        <p key="EMAIL_HAD_REGISTER_YET" className={className}>
          {t('This email is taken, try log in.')}
        </p>
      ),
      EMAIL_INVALID: (
        <p key="EMAIL_INVALID" className={className}>
          {t('Please enter a valid email.')}
        </p>
      ),
    }),
    [className, t]
  );

  return errorType ? EMAIL_ERROR_MAP[errorType] : null;
}

export function PasswordErrorMessage({
  errorType,
  className,
}: ErrorMessageProps<PasswordErrorType>) {
  const {t} = useTranslation('auth');
  const PASSWORD_ERROR_MAP: Record<PasswordErrorType, JSX.Element> = useMemo(
    () => ({
      PASSWORD_INVALID: (
        <p key="PASSWORD_INVALID" className={className}>
          {t('Use password more than 8 characters.')}
        </p>
      ),
      PASSCODE_NOT_MATCH: (
        <p key="PASSCODE_NOT_MATCH" className={className}>
          {t('Wrong username/password combination.')}
        </p>
      ),
      COMFIRM_PASSCODE_NOT_MATCH: (
        <p key="COMFIRM_PASSCODE_NOT_MATCH" className={className}>
          {t('The password does not match.')}
        </p>
      ),
    }),
    [className, t]
  );

  return errorType ? PASSWORD_ERROR_MAP[errorType] : null;
}
export type UseErrorToastReturnType = ReturnType<typeof useErrorToast>;
export type ErrorToastVisibilityType = {
  setErrorToastType: UseErrorToastReturnType['setErrorToastType'];
  showErrorToast: UseErrorToastReturnType['showErrorToast'];
  setErrorToastVisible: UseErrorToastReturnType['setErrorToastVisible'];
};

export function useErrorToast() {
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [errorToastType, setErrorToastType] =
    useState<ErrorToastEnumType | null>(null);
  const emailRef = useRef<string>('');
  const showErrorToastCallbackRef = useRef<unknown>(null);
  const hideErrorToast = useCallback(() => {
    setErrorToastVisible(false);
  }, []);
  const onConfirmInErrorToast = useCallback(() => {
    if (errorToastType === 'mergeAccountError') {
      //谷歌账号出现合并，点击OK，登录并跳转
      if (
        showErrorToastCallbackRef.current &&
        typeof showErrorToastCallbackRef.current === 'function'
      ) {
        showErrorToastCallbackRef.current &&
          showErrorToastCallbackRef.current();
      }
    } else if (errorToastType === 'cannotResetPassword') {
      if (
        showErrorToastCallbackRef.current &&
        typeof showErrorToastCallbackRef.current === 'function'
      ) {
        showErrorToastCallbackRef.current &&
          showErrorToastCallbackRef.current();
      }
    }
    setErrorToastVisible(false);
  }, [errorToastType]);
  const showErrorToast = useCallback(
    (visible: boolean, email: string, showErrorToastCallback?: () => void) => {
      emailRef.current = email;
      setErrorToastVisible(visible);
      if (showErrorToastCallback) {
        showErrorToastCallbackRef.current = showErrorToastCallback;
      }
    },
    []
  );
  return {
    errorToastVisible,
    errorToastType,
    setErrorToastType,
    setErrorToastVisible,
    hideErrorToast,
    onConfirmInErrorToast,
    emailRef,
    showErrorToast,
  };
}
