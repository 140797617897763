// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useTranslation} from 'react-i18next';

import styles from './WelcomeModal.module.scss';
type WelcomeModalType = {
  onClose: () => void;
  isOpen: boolean;
};
export function WelcomeModal({onClose, isOpen}: WelcomeModalType) {
  const {t} = useTranslation('workspace');
  if (!isOpen) return null;
  const asideWidth =
    document.getElementById('mootion-aside')?.getBoundingClientRect()?.width ||
    0;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div
        className={styles.container}
        style={{left: asideWidth / 2}}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.title}>
          {t('Welcome to Mootion storyteller')}
        </div>
        <div className={styles.btn} onClick={onClose}>
          {t('Start my journey')}
        </div>
      </div>
    </div>
  );
}
