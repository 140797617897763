// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Button} from 'components/Button';
import {Toast} from 'pages/components/Toast';
import {useTranslation} from 'react-i18next';

import {PromoInput} from '../PromoInput';
import styles from './UpgradeConfirmToast.module.scss';
import {Props} from './UpgradeConfirmToast.types';

export function UpgradeConfirmToast({
  isUpgrading,
  promoInfo,
  clearPromoInfo,
  onCheck,
  onUpgrade,
  onCancel,
}: Props) {
  const {t} = useTranslation('plan');

  return (
    <Toast
      title={t('Upgrade for more')}
      visible
      onOk={onUpgrade}
      onCancel={onCancel}
      showCloseBtn={false}
      className={styles.container}
      footer={({close}) => (
        <div className={styles.footer}>
          <Button
            onClick={() => {
              onUpgrade();
              close();
            }}
            theme="primary"
            size="large"
            isLoading={isUpgrading}
            disabled={isUpgrading}
            icon={<></>}
          >
            {t('Confirm upgrade')}
          </Button>
          <Button
            onClick={() => {
              onCancel();
              close();
            }}
            size="large"
            type="text"
            className={styles.cancel}
            disabled={isUpgrading}
          >
            {t('Cancel')}
          </Button>
        </div>
      )}
    >
      <p className={styles.text}>
        {t('You will be charged and switch to new plan instantly.')}
      </p>
      <PromoInput
        promoInfo={promoInfo}
        isDisabled={isUpgrading}
        onClearPromoInfo={clearPromoInfo}
        onCheck={onCheck}
      />
    </Toast>
  );
}
