// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import None from 'assets/svg/3.0/None.svg';
import {ReactComponent as SubtitleIcon} from 'assets/svg/3.0/Subtitle.svg';
import {ReactComponent as TextIcon} from 'assets/svg/3.0/Text.svg';
import classNames from 'classnames';
import {useResourceManager} from 'contexts/ResourceManager';
import {useUserContext} from 'contexts/UserContext';
import {Collapse} from 'pages/components/Collapse';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {StyleCard} from '../StyleCardNew';
import styles from './TextStyleList.module.scss';
import {Props, TextType} from './TextStyleList.types';

const ICON_MAP: Record<
  TextType,
  FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >
> = {
  title: TextIcon,
  subtitle: SubtitleIcon,
};

export function TextStyleList({
  type,
  data: textStyles,
  selectedStyleName,
  onSelected,
}: Props) {
  const {resUrl} = useResourceManager();
  const {
    userInfo: {plan},
  } = useUserContext();

  const Icon = ICON_MAP[type];

  const isSupport = useCallback(
    (supported_plans: string[] = ['FREE', 'STANDARD', 'PRO']) =>
      supported_plans.includes(plan),
    [plan]
  );

  const {t} = useTranslation('project');

  const TITLE_MAP: Record<TextType, string> = {
    title: t('Title'),
    subtitle: t('Subtitle'),
  };

  return (
    <Collapse
      icon={<Icon className={classNames(styles['label-icon'], styles.fill)} />}
      title={TITLE_MAP[type]}
    >
      <ul className={styles.list}>
        <StyleCard
          preview=""
          background={None}
          isSelected={selectedStyleName === null}
          onSelected={() => onSelected(null)}
        />
        {textStyles.map(({id, name, image, supported_plans}) => {
          return (
            <StyleCard
              disabled={!isSupport(supported_plans)}
              preview={`${resUrl}${image}`}
              isSelected={selectedStyleName === name}
              onSelected={() => onSelected(name)}
              key={id}
            />
          );
        })}
      </ul>
    </Collapse>
  );
}
