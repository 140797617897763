// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {getBillingPagePath} from 'api/payment';
import {ReactComponent as LockIcon} from 'assets/svg/3.0/Lock.svg';
import {ReactComponent as InfoIcon} from 'assets/svg/outline/Info.svg';
import {ReactComponent as WrongIcon} from 'assets/svg/outline/Plus2.svg';
import {ReactComponent as RightIcon} from 'assets/svg/outline/Tick.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useUserContext} from 'contexts/UserContext';
import {convertUTCToLocaleDateString} from 'pages/common/date';
import {Button} from 'pages/components/Button';
import {Toast} from 'pages/components/Toast';
import {ToggleSwitch} from 'pages/components/ToggleSwitch';
import {useCallback} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Outlet} from 'react-router-dom';

import {DiscountCard} from '../DiscountCard';
import {BillingCard} from './BillingCard';
import {creditsPerMonthByLevel, PerMonthChargeMap} from './constData';
import {CreditCard} from './CreditCard';
import {getButtonProps} from './Plan.container';
import styles from './Plan.module.scss';
import {
  FeaturesOfLevelMapType,
  PlanListProps,
  PlanPageProps,
  TypeStateEnum,
} from './Plan.types';
import {SubscriptionCard} from './SubscriptionCard';
import {UpgradeConfirmToast} from './UpgradeConfirmToast';

export function PlanPage({
  typeState,
  setTypeState,
  currentPlanInfo,
  onAction,
  downgradeProductId,
  upgradeProductId,
  onDowngradeConfirm,
  clearActionProductId,
  onUpgradeConfirm,
  userInfo,
  isOnAction,
}: PlanPageProps) {
  const {activeProductId, subscriptionProductId} = useUserContext();

  const openBillingPage = useCallback(async () => {
    const {data: url} = await getBillingPagePath();

    // 使用异步的方法打开新窗口，避免被Safari等浏览器拦截
    setTimeout(() => window.open(url, '_blank'));
  }, []);

  const {t} = useTranslation('plan');

  const FeaturesOfLevelMap: FeaturesOfLevelMapType = {
    Free: [
      {
        text: t('20,000 characters prompt'),
      },
      {
        text: t('16 scenes maximum per project'),
      },
      {
        text: t('6 visual styles'),
      },
      {
        text: t('Limited voiceovers'),
      },
      {
        notAvailable: true,
        text: t('Watermark free'),
      },
      {
        notAvailable: true,
        text: (
          <>
            {t('Priority generation')}
            <Popover
              triggerElement={<InfoIcon className={styles['info-icon']} />}
              trigger={'hover'}
              direction="top"
              distance={7}
              style={{padding: '6px 8px', borderRadius: '4px'}}
              content={
                <div className={styles['tip-content']}>
                  {t(
                    'Your task will be given priority when more users are generating tasks'
                  )}
                </div>
              }
            />
          </>
        ),
      },
      {
        text: t('1GB asset upload'),
      },
      {
        text: t('Precise pose & motion control'),
      },
      {text: t('Video clip generation')},
      {text: t('1080p')},
      {text: t('General commercial license')},
    ],
    Standard: [
      {
        text: t('20,000 characters prompt'),
      },
      {
        text: t('16 scenes maximum per project'),
      },
      {
        text: (
          <Trans ns="plan" i18nKey="10+ visual styles">
            <span className={styles.colorText}>10+ </span>visual styles
          </Trans>
        ),
      },
      {text: t('More realistic voiceovers')},
      {
        text: t('Watermark free'),
      },
      {
        text: (
          <>
            {t('Priority generation')}
            <Popover
              triggerElement={<InfoIcon className={styles['info-icon']} />}
              trigger={'hover'}
              direction="top"
              distance={7}
              style={{padding: '6px 8px', borderRadius: '4px'}}
              content={
                <div className={styles['tip-content']}>
                  {t(
                    'Your task will be given priority when more users are generating tasks'
                  )}
                </div>
              }
            />
          </>
        ),
      },
      {
        text: (
          <Trans ns="plan" i18nKey="10GB asset upload">
            <span className={styles.colorText}>10GB</span> asset upload
          </Trans>
        ),
      },
      {
        text: t('Precise pose & motion control'),
      },
      {text: t('Bulk video clip generation')},
      {text: t('1080p')},
      {text: t('General commercial license')},
    ],
    Pro: [
      {
        text: (
          <Trans ns="plan" i18nKey="50,000 characters prompt">
            <span className={styles.colorText}>50,000</span> characters prompt
          </Trans>
        ),
      },
      {
        text: (
          <Trans ns="plan" i18nKey="32 scenes maximum per project">
            <span className={styles.colorText}>32</span> scenes maximum per
            project
          </Trans>
        ),
      },
      {
        text: (
          <Trans ns="plan" i18nKey="10+ visual styles">
            <span className={styles.colorText}>10+</span> visual styles
          </Trans>
        ),
      },
      {text: t('All voiceovers')},
      {
        text: t('Watermark free'),
      },
      {
        text: (
          <>
            {t('Priority generation')}
            <Popover
              triggerElement={<InfoIcon className={styles['info-icon']} />}
              trigger={'hover'}
              direction="top"
              distance={7}
              style={{padding: '6px 8px', borderRadius: '4px'}}
              content={
                <div className={styles['tip-content']}>
                  {t(
                    'Your task will be given priority when more users are generating tasks'
                  )}
                </div>
              }
            />
          </>
        ),
      },
      {
        text: (
          <Trans ns="plan" i18nKey="100GB asset upload">
            <span className={styles.colorText}>100GB</span> asset upload
          </Trans>
        ),
      },
      {
        text: t('Precise pose & motion control'),
      },
      {text: t('Bulk video clip generation')},
      {text: t('1080p')},
      {text: t('General commercial license')},
    ],
  };

  const planList: PlanListProps = {
    Monthly: [
      {
        title: 'Free',
        perMonthCharge: PerMonthChargeMap['Monthly']['Free'],
        creditsPerMonth: creditsPerMonthByLevel['Free'],
        creditsPerMonthText: t("Can't buy more credits"),
        creditsPerMonthTextIsLocked: true,
        features: FeaturesOfLevelMap['Free'],
        productId: 0,
      },
      {
        title: 'Standard',
        flag: t('Popular'),
        perMonthCharge: PerMonthChargeMap['Monthly']['Standard'],
        creditsPerMonth: creditsPerMonthByLevel['Standard'],
        creditsPerMonthText: t('Buy more credits as you need'),
        creditsPerMonthTextIsLocked: false,
        features: FeaturesOfLevelMap['Standard'],
        productId: 3,
      },
      {
        title: 'Pro',
        perMonthCharge: PerMonthChargeMap['Monthly']['Pro'],
        creditsPerMonth: creditsPerMonthByLevel['Pro'],
        creditsPerMonthText: t('Buy more credits as you need'),
        creditsPerMonthTextIsLocked: false,
        features: FeaturesOfLevelMap['Pro'],
        productId: 5,
      },
    ],
    Yearly: [
      {
        title: 'Free',
        perMonthCharge: PerMonthChargeMap['Yearly']['Free'],
        creditsPerMonth: creditsPerMonthByLevel['Free'],
        creditsPerMonthText: t("Can't buy more credits"),
        creditsPerMonthTextIsLocked: true,
        features: FeaturesOfLevelMap['Free'],
        productId: 0,
      },
      {
        title: 'Standard',
        flag: t('Popular'),
        perMonthCharge: PerMonthChargeMap['Yearly']['Standard'],
        perMonthChargeOfMonthly: PerMonthChargeMap['Monthly']['Standard'],
        perYearlyCharge: 96,
        creditsPerMonth: creditsPerMonthByLevel['Standard'],
        creditsPerMonthText: t('Buy more credits as you need'),
        creditsPerMonthTextIsLocked: false,
        features: FeaturesOfLevelMap['Standard'],
        productId: 4,
      },
      {
        title: 'Pro',
        perMonthCharge: PerMonthChargeMap['Yearly']['Pro'],
        perMonthChargeOfMonthly: PerMonthChargeMap['Monthly']['Pro'],
        perYearlyCharge: 384,
        creditsPerMonth: creditsPerMonthByLevel['Pro'],
        creditsPerMonthText: t('Buy more credits as you need'),
        creditsPerMonthTextIsLocked: false,
        features: FeaturesOfLevelMap['Pro'],
        productId: 6,
      },
    ],
  };

  const ToggleOptions = [
    {label: 'Monthly', width: 114},
    {label: 'Yearly', discount: t('20% off'), width: 138},
  ];

  if (!typeState || !currentPlanInfo) return null;
  return (
    <div className={styles.container}>
      <h1 className={styles.containerTitle} id="choosePlan">
        {t('Subscription')}
      </h1>
      <div className={styles.head}>
        <SubscriptionCard
          credit={userInfo.credit}
          creditPack={userInfo.creditPackAmount}
          creditAmount={userInfo.planCreditAmount}
          activeProductId={activeProductId}
          subscriptionProductId={subscriptionProductId}
          renewalDate={
            userInfo.periodEnd
              ? convertUTCToLocaleDateString(new Date(userInfo.periodEnd))
              : ''
          }
        />
        <BillingCard
          activeProductId={activeProductId}
          subscriptionProductId={subscriptionProductId}
          renewalDate={
            userInfo.periodEnd
              ? convertUTCToLocaleDateString(new Date(userInfo.periodEnd))
              : ''
          }
          onEditBilling={openBillingPage}
        />
        <CreditCard
          onBuyCredits={productId => onAction('Subscription', productId)}
          isDisabled={userInfo.plan === 'FREE'}
        />
      </div>
      <ToggleSwitch
        options={ToggleOptions}
        activeIndex={typeState === 'Monthly' ? 0 : 1}
        onToggle={index => {
          setTypeState(ToggleOptions[index].label as TypeStateEnum);
        }}
        className={styles.toggleSwitch}
        totalWidth={248}
      />
      <DiscountCard size="large" />
      <div className={styles.planOptionWrap}>
        {planList[typeState].map(item => {
          const {children, ...buttonProps} = getButtonProps({
            userLevel: currentPlanInfo.level,
            userType: currentPlanInfo.type,
            currentLevel: item.title,
            currentType: typeState,
          });

          return (
            <div
              className={classNames(styles.planItem, {
                [styles.active]:
                  item.title === 'Standard' && currentPlanInfo.level === 'Free',
              })}
              key={item.title}
            >
              <div className={styles.top}>
                <div className={styles.title}>
                  {item.title}
                  {item.flag && <div className={styles.flag}>{item.flag}</div>}
                </div>
                <div className={styles.price}>
                  <div className={styles.priceText}>
                    <span className={styles.perMonthCharge}>
                      {item.perMonthCharge === 0
                        ? t('Free forever')
                        : '$' + item.perMonthCharge}
                    </span>
                    {item.title !== 'Free' && (
                      <div className={styles.append}>
                        <span>
                          {typeState === 'Yearly' &&
                          item.perMonthChargeOfMonthly !== undefined ? (
                            <span className={styles.sourcePrice}>
                              ${item.perMonthChargeOfMonthly}
                            </span>
                          ) : null}
                          /{t('month')}
                        </span>
                        {typeState === 'Yearly' && (
                          <span>
                            {t('Billed yearly as charge', {
                              charge: item.perYearlyCharge,
                            })}
                          </span>
                        )}
                        {typeState === 'Monthly' && (
                          <span>{t('Billed monthly')}</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.creditsInfo}>
                  <div className={styles.creditsPerMonth}>
                    {t('n credits/month', {n: item.creditsPerMonth})}
                  </div>
                  <div className={styles.tip}>
                    {item.creditsPerMonthTextIsLocked ? <LockIcon /> : null}
                    {item.creditsPerMonthText}
                  </div>
                </div>
              </div>

              <Button
                size="large"
                disabled={isOnAction}
                className={styles.buttonSection}
                {...buttonProps}
                onClick={() => {
                  if (children === 'Current plan') {
                    return;
                  }
                  if (children === 'Upgrade') {
                    if (currentPlanInfo.level === 'Free') {
                      onAction('Subscription', item.productId);
                    } else {
                      onAction('Upgrade', item.productId);
                    }
                  } else {
                    onAction('Downgrade', item.productId);
                  }
                }}
              >
                {t(children)}
              </Button>

              <div className={styles.bottomTips}>
                {item.features.map((feature, index) => {
                  return (
                    <div className={styles.tipItem} key={index}>
                      {feature.notAvailable ? (
                        <WrongIcon
                          className={classNames(
                            styles.icon,
                            styles.notAvailable
                          )}
                        />
                      ) : (
                        <RightIcon className={styles.icon} />
                      )}
                      <span className={styles.textWrap}>
                        <span className={styles.text}>{feature.text}</span>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Outlet />
      {downgradeProductId !== null && (
        <Toast
          title={t('Are you sure to downgrade')}
          visible={downgradeProductId !== null}
          onOk={onDowngradeConfirm}
          onCancel={() => clearActionProductId('Downgrade')}
          confirmButtonProps={{
            isLoading: isOnAction,
            disabled: isOnAction,
            icon: <></>,
          }}
          cancelButtonProps={{
            disabled: isOnAction,
          }}
          showCloseBtn={false}
          btnTheme="primary"
          cancelText={t('Cancel')}
          confirmText={t('Confirm downgrade')}
          className={styles.downgradeToast}
        >
          {t(
            'We’ll keep current plan active until the end of this subscription period.'
          )}
        </Toast>
      )}
      {upgradeProductId !== null && (
        <UpgradeConfirmToast
          productId={upgradeProductId}
          isUpgrading={isOnAction}
          onUpgradeConfirm={onUpgradeConfirm}
          onCancel={() => clearActionProductId('Upgrade')}
        />
      )}
    </div>
  );
}
