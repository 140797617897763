// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProficiencyLevel} from 'modules/project/types';
import {PROFICIENCY_LEVEL_OPTIONS} from 'modules/project/utils';
import {useTranslation} from 'react-i18next';

import {CommonSelectWithoutLabel} from './CommonSelectWithoutLabel/CommonSelectWithoutLabel';
import styles from './CommonSelectWithoutLabel/CommonSelectWithoutLabel.module.scss';

export interface Props {
  value: ProficiencyLevel;
  onChange: (value: ProficiencyLevel) => void;
}
export function ProficiencyLevelSelect({value, onChange}: Props) {
  const {t} = useTranslation('project');
  const options = PROFICIENCY_LEVEL_OPTIONS.map(option => ({
    value: option.value,
    label: t(option.label),
  }));
  return (
    <CommonSelectWithoutLabel
      options={options}
      placeholderOfOtherInput={t('Input learning level')}
      value={value}
      title={
        <span className={styles['panel-title-container']}>
          <span>{t('Proficiency level')}</span>
        </span>
      }
      onChange={value => onChange(value as ProficiencyLevel)}
      customizeValue="Other"
    />
  );
}
