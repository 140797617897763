// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {axiosFactory} from './axiosFactory';

export class SubscriptionPurchasedError extends Error {}
export class SubscriptionNotChangedError extends Error {}

export enum OrderResponseCode {
  SUCCESS = 200,
  SUBSCRIPTION_ALREADY_PURCHASED = 30001,
  SUBSCRIPTION_NOT_CHANGED = 30002,
}

interface Response {
  code: number;
  msg: string;
}

interface ErrorResponse extends Response {
  error: OrderResponseCode;
}

interface ResponseData<T> extends Response {
  data: T;
}

function convertStringUnixTimestamp(unixTimestamp: string): number | null {
  const timestamp = Number(unixTimestamp) * 1000;
  return isNaN(timestamp) ? null : timestamp;
}

interface InitiatePrepaymentResponse {
  priceId: string;
  outTradeNo: string;
}

interface SubscriptionDetail<T extends string | number | null> {
  creditAmount: number;
  periodEnd: T;
  autoRenewal: 0 | 1;
}

interface UserSubscriptionInfo {
  activeProductId: number;
  subscriptionProductId: number;
  subscriptionDetail: SubscriptionDetail<number | null> | null;
}
type CheckoutSessionParams = {
  price: string;
  user_id: string;
  out_trade_no: string;
  success_url: string;
  cancel_url: string;
  is_subscription: boolean;
};

type CheckOrderResponse = ResponseData<{
  isSuccessful: boolean;
  customerEmail: string;
}>;

export const api = axiosFactory({
  baseURL: process.env.REACT_APP_API_ORDER_URL as string,
  timeout: 10000,
});

export async function getUserSubscription(
  userId: string
): Promise<ResponseData<UserSubscriptionInfo>> {
  const response: ResponseData<UserSubscriptionInfo> = await api.get(
    `/order/products/${userId}`
  );

  const subscriptionDetail = response.data
    .subscriptionDetail as SubscriptionDetail<string> | null;

  // Stripe timestamp is in seconds, but we need milliseconds
  return {
    ...response,
    data: {
      ...response.data,
      subscriptionDetail: subscriptionDetail
        ? {
            ...subscriptionDetail,
            periodEnd: convertStringUnixTimestamp(subscriptionDetail.periodEnd),
          }
        : null,
    },
  };
}

export async function initiatePrepayment(
  userId: string,
  productId: number
): Promise<ResponseData<InitiatePrepaymentResponse>> {
  try {
    const res = (await api.post('/order/preorder', {
      userId,
      productId,
    })) as ResponseData<InitiatePrepaymentResponse>;
    return res;
  } catch (error) {
    const err = error as Error;
    if (
      'data' in err &&
      (err.data as ErrorResponse).error ===
        OrderResponseCode.SUBSCRIPTION_ALREADY_PURCHASED
    ) {
      throw new SubscriptionPurchasedError();
    } else {
      throw error;
    }
  }
}

export function checkoutSession({
  price,
  user_id,
  out_trade_no,
  success_url,
  cancel_url,
  is_subscription,
}: CheckoutSessionParams): Promise<ResponseData<string>> {
  return api.post('/order/stripe/checkout/session', {
    line_items: [
      {
        price,
        quantity: 1,
      },
    ],
    payment_method_types: ['card'],
    out_trade_no,
    user_id,
    success_url,
    cancel_url,
    is_subscription,
  });
}

export function initiatePrepaymentV2(
  userId: string,
  productName: string
): Promise<ResponseData<InitiatePrepaymentResponse>> {
  return api.post('/order/preorder/v2', {userId, productName});
}

export function checkoutItemSession(
  price: string,
  outTradeNo: string,
  itemId: string,
  successUrl: string,
  cancelUrl: string
): Promise<ResponseData<string>> {
  return api.post('/order/stripe/checkout/session/user-items', {
    line_items: [
      {
        price,
        quantity: 1,
      },
    ],
    out_trade_no: outTradeNo,
    item_id: itemId,
    success_url: successUrl,
    cancel_url: cancelUrl,
  });
}

export function checkOrder(outTradeNo: string): Promise<CheckOrderResponse> {
  return api.get(`/order/${outTradeNo}/success/v2`);
}

export async function upgrade(
  curProductId: number,
  newProductId: number,
  userId: string,
  promotionCode?: string
): Promise<ResponseData<string>> {
  try {
    const res = (await api.patch('/order/stripe/subscription/upgrade', {
      curProductId,
      newProductId,
      userId,
      promotionCode,
    })) as ResponseData<string>;
    return res;
  } catch (error) {
    const err = error as Error;

    if (
      'data' in err &&
      (err.data as ErrorResponse).error ===
        OrderResponseCode.SUBSCRIPTION_NOT_CHANGED
    ) {
      throw new SubscriptionNotChangedError();
    } else {
      throw error;
    }
  }
}

export function downgrade(
  curProductId: number | undefined,
  newProductId: number,
  userId: string
): Promise<Response> {
  return api.patch('/order/stripe/subscription/downgrade', {
    curProductId,
    newProductId,
    userId,
  });
}

export function getBillingPagePath(): Promise<ResponseData<string>> {
  return api.get('/order/stripe/customer-portal');
}

export function checkPromoCode(
  promotionCode: string,
  userId: string,
  productId: number
): Promise<
  ResponseData<
    | {valid: false; discount: undefined; id: undefined}
    | {valid: true; discount: number; id: string}
  >
> {
  return api.post('/order/stripe/promotions/verify', {
    promotionCode,
    userId,
    productId,
  });
}
