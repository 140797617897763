// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';

import {ObjectEditor} from './ObjectEditor/ObjectEditor';
import styles from './ObjectsEditor.module.scss';
import {ObjectsEditorProps} from './ObjectsEditor.type';

export function ObjectsEditor({
  onLoad,
  className,
  onAction,
  disabled,
  outerHeight,
  outerWidth,
  draft,
  updateObject,
  getImageWithObjectKey,
  refreshImage,
  forceLoading,
  outerEl,
  animating,
}: ObjectsEditorProps) {
  const [selectedObject, setSelectedObject] = useState<number | null>(null);
  const prevObjectsLength = useRef(draft?.objects.length || 0);

  useEffect(() => {
    if (!draft) return;
    if (prevObjectsLength.current > draft.objects.length) {
      // 删除object后保存
      // saveScene();
      setSelectedObject(null);
    } else if (prevObjectsLength.current < draft.objects.length) {
      // 新增后选中
      setSelectedObject(draft.objects.length - 1);
    }
    prevObjectsLength.current = draft.objects.length;
  }, [draft]);

  if (!draft) return null;

  return (
    <div
      className={classNames(styles['objects-editor'], className)}
      style={{
        background:
          draft.backgroundType === 'color' ? draft.background : undefined,
      }}
    >
      {animating && <div className={styles['objects-editor-mask']}></div>}
      {draft.backgroundType === 'image' && (
        <img
          className={styles.preview}
          src={staticCombiner(draft.background)}
          onLoad={onLoad}
        />
      )}
      {draft.objects.map((object, index) => (
        <ObjectEditor
          key={index}
          object={object}
          active={!disabled && selectedObject === index}
          disabled={disabled}
          onClick={() => setSelectedObject(index)}
          onClickOutSide={() => {
            updateObject?.(index, draft.objects[index], true);
            setSelectedObject(null);
          }}
          onAction={(type, object) => {
            selectedObject !== null && onAction?.(type, object, selectedObject);
          }}
          updateObject={(object, immediately) => {
            !disabled && updateObject?.(index, object, immediately);
          }}
          outerWidth={outerWidth}
          outerHeight={outerHeight}
          getImageWithObjectKey={getImageWithObjectKey}
          refreshImage={refreshImage}
          forceLoading={forceLoading === index}
          outerEl={outerEl}
        />
      ))}
    </div>
  );
}
