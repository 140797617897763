// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

// Tiktok Pixel
export function reportEventToTiktok(
  event: string,
  config: Record<string, unknown>
) {
  window.ttq.track(event, config?.data ? config.data : undefined);
}

// Google Event tracking (Google Analytics & Google Ads)
export function reportEventToGoogle(
  event: string,
  config: Record<string, unknown>
) {
  window.gtag('event', event, config);
}
