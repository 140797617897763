// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Draft} from 'modules/draft/models/Draft';
import {TaskType as StoryboardTaskType} from 'modules/storyboard/types';
import {Task} from 'modules/task/models/Task';

import {
  BilingualStoryClosedScene,
  BilingualStorySceneJSON,
  TaskType,
} from '../types';
import {isSceneTask} from '../utils';
import {BaseScene} from './BaseScene';

export class BilingualStoryScene extends BaseScene {
  static fromJSON(
    {
      id,
      type,
      prompt,
      last_prompt,
      subtitle,
      native_subtitle,
      shot_type,
      characters,
      image,
      video,
      draft,
      task,
      animate_prompt,
    }: BilingualStorySceneJSON,
    tasks?: Task<StoryboardTaskType>[]
  ) {
    const _task = task && tasks ? tasks.find(t => t.id === task) : undefined;
    if (task && !_task) {
      throw new Error(`Task with id ${task} not found`);
    }
    if (_task && !isSceneTask(_task)) {
      throw new Error(`Invalid task type: ${_task.type}`);
    }
    return new BilingualStoryScene(
      id,
      type,
      prompt,
      last_prompt,
      subtitle,
      native_subtitle,
      shot_type,
      characters,
      image,
      video,
      draft ? Draft.fromJSON(draft) : undefined,
      _task as Task<TaskType> | undefined,
      animate_prompt
    );
  }

  constructor(
    id: string,
    type: string,
    prompt?: string,
    lastPrompt?: string,
    subtitle?: string,
    readonly nativeSubtitle?: string,
    shotType?: number,
    characters?: BilingualStorySceneJSON['characters'],
    image?: string,
    video?: string,
    draft?: Draft,
    task?: Task<TaskType>,
    animatePrompt?: string
  ) {
    super(
      id,
      type,
      prompt,
      lastPrompt,
      subtitle,
      shotType,
      characters,
      image,
      video,
      draft,
      task,
      animatePrompt
    );
  }

  isValid() {
    return !!this.currentImage && !!this.subtitle && !!this.nativeSubtitle;
  }

  patch(
    data: Partial<{
      [K in
        | 'prompt'
        | 'subtitle'
        | 'nativeSubtitle'
        | 'image'
        | 'video'
        | 'draft'
        | 'task'
        | 'animatePrompt']: BilingualStoryScene[K];
    }>
  ) {
    const {
      id,
      type,
      prompt,
      lastPrompt,
      subtitle,
      nativeSubtitle,
      shotType,
      characters,
      image,
      video,
      draft,
      task,
      animatePrompt,
    } = Object.assign({}, this, data);
    return new BilingualStoryScene(
      id,
      type,
      prompt,
      lastPrompt,
      subtitle,
      nativeSubtitle,
      shotType,
      characters,
      image,
      video,
      draft,
      task,
      animatePrompt
    );
  }

  toJSON(): BilingualStorySceneJSON {
    return {
      ...super.toJSON(),
      native_subtitle: this.nativeSubtitle,
    };
  }

  toClosed(): BilingualStoryClosedScene {
    return {
      ...super.toClosed(),
      native_subtitle: this.nativeSubtitle,
    };
  }
}
