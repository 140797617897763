// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DeleteIcon} from 'assets/svg/3.0/Delete.svg';
import {ReactComponent as MergeNextIcon} from 'assets/svg/3.0/MergeNext.svg';
import {ReactComponent as MergePrevIcon} from 'assets/svg/3.0/MergePrev.svg';
import {ReactComponent as SplitIcon} from 'assets/svg/3.0/Split.svg';
import classNames from 'classnames';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './SceneActionTips.module.scss';
import {ContentMapType, ContentValueType, Props} from './SceneActionTips.types';

export function SceneActionTips({
  type,
  sceneId,
  onCancel,
  onSceneAction,
}: Props) {
  const {t} = useTranslation('project');
  const CONTENT_MAP: ContentMapType = useMemo(
    () => ({
      split_scene: {
        text: t('Split'),
        Icon: SplitIcon,
        content: t('Split this scene into two new scenes.'),
      },
      merge_prev_scene: {
        text: t('Merge up'),
        Icon: MergePrevIcon,
        content: t('Merge this scene with the previous one'),
      },
      merge_next_scene: {
        text: t('Merge down'),
        Icon: MergeNextIcon,
        content: t('Merge this scene with the next one'),
      },
      delete_scene: {
        text: t('Delete'),
        Icon: DeleteIcon,
        content: t('Confirm to delete this scene?'),
      },
    }),
    [t]
  );

  const {Icon, text, content} = useMemo<ContentValueType>(
    () => CONTENT_MAP[type],
    [CONTENT_MAP, type]
  );

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        <Icon className={styles.icon} /> <span>{text}</span>
      </p>
      <p className={styles.content}>{content}</p>
      <div className={styles.buttons}>
        <button
          className={classNames(styles.button, styles.cancel)}
          onClick={onCancel}
        >
          {t('Cancel')}
        </button>
        <button
          className={classNames(
            styles.button,
            styles.yes,
            type === 'delete_scene' && styles.delete
          )}
          onClick={() => {
            onSceneAction(type, sceneId);
            onCancel();
          }}
        >
          {t('Yes')}
        </button>
      </div>
    </div>
  );
}
