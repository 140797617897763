// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import {ReactComponent as NarratorIcon} from 'assets/svg/3.0/Narrator.svg';
import {ReactComponent as RoleIcon} from 'assets/svg/3.0/Role.svg';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import styles from './BilingualDialogueSceneInsertActionTips.module.scss';
import {Props} from './BilingualDialogueSceneInsertActionTips.types';

export function BilingualDialogueSceneInsertActionTips(props: Props) {
  const {characterNames, onSelect, onCancel} = props;
  const {t} = useTranslation('project');
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t('Choose a speaker for the new scene')}
      </div>
      <div className={styles.content}>
        <div className={styles.buttons}>
          {characterNames.map((name, index) => (
            <button
              key={index}
              className={styles.button}
              onClick={() => {
                onSelect(name);
                onCancel();
              }}
            >
              <span className={styles.icon}>
                <RoleIcon />
              </span>
              <span className={styles.text}>{name}</span>
            </button>
          ))}
          <button
            className={styles.button}
            onClick={() => {
              onSelect();
              onCancel();
            }}
          >
            <span className={styles.icon}>
              <NarratorIcon />
            </span>
            <span className={styles.text}>{t('Narrator')}</span>
          </button>
          <button
            className={classNames(styles.button, styles.cancel)}
            onClick={() => onCancel()}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    </div>
  );
}
