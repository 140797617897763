// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CoinIcon} from 'assets/svg/3.0/Coin.svg';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow3.svg';
import classNames from 'classnames';
import {Button} from 'pages/components/Button';
import {SearchInput} from 'pages/components/SearchInput';
import {useTranslation} from 'react-i18next';

import {Props} from './Generator.types';

export type PoseGeneratorProps = {
  styles: {[key: string]: string};
} & Pick<
  Props,
  | 'switchValue'
  | 'posePrompt'
  | 'onChangePosePrompt'
  | 'onGeneratePoseJson'
  | 'poseList'
  | 'activePose'
  | 'changeActivePose'
  | 'resUrl'
>;

export function PoseGenerator({
  styles,
  switchValue,
  posePrompt,
  onChangePosePrompt,
  onGeneratePoseJson,
  poseList,
  activePose,
  changeActivePose,
  resUrl,
}: PoseGeneratorProps) {
  const {t} = useTranslation('project');
  return (
    <>
      <p
        className={classNames(styles.tips, {
          [styles.isHidden]: switchValue,
        })}
      >
        {t('Generate and control character in the scene')}
      </p>
      <SearchInput
        value={posePrompt}
        className={classNames(styles.searchInput, {
          [styles.isHidden]: !switchValue,
        })}
        onChange={onChangePosePrompt}
        onOK={onGeneratePoseJson}
        maxLength={400}
        suffix={
          <Button
            disabled={posePrompt === undefined || posePrompt.length === 0}
            size="middle"
            theme="primary"
            onClick={onGeneratePoseJson}
            className={styles.suffixButton}
          >
            <span className={styles.credits}>
              <CoinIcon className={styles.icon} /> 1
            </span>
            <RightArrowIcon className={styles.arrowIcon} />
          </Button>
        }
        status="default"
        placeholder={t('Generate pose with text prompt')}
      />
      <div
        className={classNames(styles.poseWrap, {
          [styles.isHidden]: !switchValue,
        })}
      >
        {poseList.map(pose => (
          <div
            key={pose.id}
            className={classNames(styles.poseItem, {
              [styles.selected]: activePose === pose.id,
            })}
            data-id={pose.id}
            onClick={changeActivePose}
          >
            <img
              key={pose.id}
              src={resUrl + pose.image}
              className={styles.poseImg}
            />
          </div>
        ))}
      </div>
    </>
  );
}
