// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {noop} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './ToggleSwitch.module.scss';

type ToggleOption = {
  label: string;
  discount?: string;
  width: number;
};

type ToggleSwitchProps = {
  options: ToggleOption[];
  activeIndex?: number;
  onToggle?: (index: number) => void;
  className?: string;
  totalWidth: number;
};

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  options,
  activeIndex = 0,
  onToggle = noop,
  className,
  totalWidth,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(activeIndex);

  const handleToggle = (index: number) => {
    setSelectedIndex(index);
    onToggle(index);
  };
  const widthSum = options.reduce((acc, option) => acc + option.width, 0);
  const {t} = useTranslation('plan');
  return (
    <div className={classNames(styles.toggleSwitch, className)}>
      {options.map((option, index) => (
        <div
          key={index}
          className={`${styles.switchOption} ${
            selectedIndex === index ? styles.active : ''
          }`}
          onClick={() => handleToggle(index)}
          style={{
            width: option.width + 'px',
            marginLeft: index === 0 ? 0 : totalWidth - widthSum + 'px',
          }}
        >
          {t(option.label)}
          {option.discount && (
            <span className={styles.discount}>{option.discount}</span>
          )}
        </div>
      ))}
      <div
        className={styles.slider}
        style={{
          width: options[selectedIndex].width + 'px',
          left:
            selectedIndex === 0
              ? '1px'
              : totalWidth - options[1].width - 1 + 'px',
        }}
      ></div>
    </div>
  );
};
