// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {TextEditor} from '../../TextEditor';
import styles from './SubtitleEditor.module.scss';
import {Props} from './SubtitleEditor.types';

export function SubtitleEditor({
  subtitle,
  isProcessing,
  isSelected,
  isAnimating,
  onChange,
  onFocus,
  subtitleMaxLength,
}: Props) {
  const isOverLength = useMemo(
    () => !!subtitle && subtitle.length > subtitleMaxLength,
    [subtitleMaxLength, subtitle]
  );
  const {t} = useTranslation('project');

  return (
    <div
      className={classNames(
        styles.container,
        isAnimating && styles.isEditorDisabled
      )}
      onClick={() => (isProcessing || isAnimating) && onFocus()}
    >
      <TextEditor
        text={subtitle}
        isDisabled={isAnimating || isProcessing}
        onChange={val => onChange(val)}
        label={false}
        size="large"
        placeholder={t('Type in subtitles')}
        isFocused={isSelected}
        isOverLength={isOverLength}
        overLengthTips={t(
          'Try summarize the content or split this scene, max characters per scene.',
          {max: subtitleMaxLength}
        )}
      />
      {isAnimating && (
        <div
          className={classNames(styles.mask, {
            [styles.focused]: isSelected,
          })}
        />
      )}
    </div>
  );
}
