// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {checkPromoCode} from 'api/payment';
import {combine} from 'components/Combine';
import {useUserContext} from 'contexts/UserContext';
import {useCallback, useState} from 'react';

import {PromoInfo} from '../PromoInput';
import {UpgradeConfirmToast} from './UpgradeConfirmToast';
import {HookParams, HookReturn} from './UpgradeConfirmToast.types';

function useHook({productId, onUpgradeConfirm}: HookParams): HookReturn {
  // Promo info 存在三种类型
  // 1. PromoInfo 类型，表示 promo code 有效，包含 discount 字段
  // 2. null，表示 promo code 无效
  // 3. undefined，表示 promo code 待检查
  const [promoInfo, setPromoInfo] = useState<PromoInfo>();

  const {
    userInfo: {userId},
  } = useUserContext();

  const onCheck = useCallback(
    async (promoCode: string) => {
      try {
        const {
          data: {valid, discount, id},
        } = await checkPromoCode(promoCode, userId, productId);
        if (valid && typeof discount === 'number' && typeof id === 'string') {
          setPromoInfo({promoCodeId: id, discount: discount});
        } else {
          setPromoInfo(null);
        }
      } catch {
        setPromoInfo(null);
      }
    },
    [productId, userId]
  );

  const onUpgrade = useCallback(async () => {
    await onUpgradeConfirm(promoInfo?.promoCodeId);
  }, [onUpgradeConfirm, promoInfo?.promoCodeId]);

  const clearPromoInfo = useCallback(() => {
    setPromoInfo(undefined);
  }, []);

  return {
    promoInfo,
    onUpgrade,
    clearPromoInfo,
    onCheck,
  };
}

export const UpgradeConfirmToastContainer = combine(useHook, [
  'productId',
  'onUpgradeConfirm',
])(UpgradeConfirmToast);
