// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as InfoIcon} from 'assets/svg/outline/Info.svg';
import classNames from 'classnames';
import {Button} from 'components/Button';
import {Popover} from 'components/PopoverNew';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Card} from '../Card';
import {PerMonthChargeMap} from '../constData';
import {getLevelByProductId, getTypeByProductId} from '../Plan.container';
import styles from './BillingCard.module.scss';
import {Props} from './BillingCard.types';

export function BillingCard({
  activeProductId,
  subscriptionProductId,
  renewalDate,
  onEditBilling,
}: Props) {
  const currentPlan = getLevelByProductId(activeProductId);
  const nextPlan = getLevelByProductId(subscriptionProductId);
  const [isLoading, setIsLoading] = useState(false);

  const onEditBillingClick = useCallback(async () => {
    setIsLoading(true);
    await onEditBilling();
    setIsLoading(false);
  }, [onEditBilling]);

  const {t} = useTranslation('plan');

  const rows = useMemo(() => {
    const defaultRows = [
      {
        label: t('Billing & payment'),
        value:
          currentPlan === 'Free' ? (
            ''
          ) : (
            <>
              <Button
                icon={<></>}
                type="text"
                isLoading={isLoading}
                className={styles.button}
                onClick={onEditBillingClick}
                disabled={isLoading}
              >
                {t('Edit billing')}
              </Button>
              <Popover
                triggerElement={
                  <div
                    className={classNames(
                      styles['billing-button'],
                      isLoading && styles.disabled
                    )}
                  >
                    <InfoIcon className={styles.icon} />
                  </div>
                }
                style={{borderRadius: '4px', transform: 'translateY(-10px)'}}
                direction="top"
                trigger="hover"
                content={
                  <p className={styles.tips}>
                    {t(
                      'Click to update your credit card or change your subscription details'
                    )}
                  </p>
                }
              />
            </>
          ),
      },
      {
        label: t('Price'),
        value:
          currentPlan === 'Free'
            ? t('Free')
            : t('price/mo', {
                price:
                  PerMonthChargeMap[getTypeByProductId(activeProductId)][
                    currentPlan
                  ],
              }),
      },
      {
        label: t('Billing period'),
        value: t(getTypeByProductId(activeProductId)),
      },
    ];
    if (currentPlan === 'Free') return defaultRows;
    else {
      return [
        ...defaultRows,
        {
          label: nextPlan !== 'Free' ? t('Renewal date') : t('Expire date'),
          value: renewalDate,
        },
      ];
    }
  }, [
    activeProductId,
    currentPlan,
    isLoading,
    nextPlan,
    onEditBillingClick,
    renewalDate,
    t,
  ]);

  return <Card rows={rows} />;
}
