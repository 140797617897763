// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import None from 'assets/svg/3.0/None.svg';
import {ReactComponent as SubtitleIcon} from 'assets/svg/3.0/Subtitle.svg';
import {ReactComponent as TextIcon} from 'assets/svg/3.0/Text.svg';
import classNames from 'classnames';
import {useResourceManager} from 'contexts/ResourceManager';
import {Collapse} from 'pages/components/Collapse';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

import {TextStyleGroupCard} from '../TextStyleGroupCard';
import styles from './TextStyleGroupList.module.scss';
import {Props, TextType} from './TextStyleGroupList.types';

const ICON_MAP: Record<
  TextType,
  FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >
> = {
  title: TextIcon,
  subtitle: SubtitleIcon,
};

export function TextStyleGroupList({
  type,
  textStyleCombination,
  selectedTextStyle,
  onChange,
}: Props) {
  const {resUrl} = useResourceManager();

  const Icon = ICON_MAP[type];

  const {t} = useTranslation('project');
  const TITLE_MAP: Record<TextType, string> = {
    title: t('Title'),
    subtitle: t('Subtitle'),
  };

  return (
    <Collapse
      icon={<Icon className={classNames(styles['label-icon'], styles.fill)} />}
      title={TITLE_MAP[type]}
    >
      <ul className={styles.list}>
        <TextStyleGroupCard
          isSelected={selectedTextStyle === null}
          background={None}
          onSelect={() => onChange(null)}
        />
        {textStyleCombination.map(
          ({name, nativeTextStyleImage, targetTextStyleImage}) => {
            return (
              <TextStyleGroupCard
                stylePreview={`${resUrl}${targetTextStyleImage}`}
                nativeStylePreview={`${resUrl}${nativeTextStyleImage}`}
                isSelected={selectedTextStyle === name}
                onSelect={() => onChange(name)}
                key={name}
              />
            );
          }
        )}
      </ul>
    </Collapse>
  );
}
