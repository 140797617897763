// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import {combine} from 'components/Combine';
import {useAPI} from 'contexts/APIContext';
import {ErrorType, useError} from 'contexts/ErrorContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {VoiceoverDataItem} from 'contexts/ResourceManager.type';
import {useUserContext} from 'contexts/UserContext';
import {formatAspectRatio} from 'lib/ratio';
import {retryIgnoreError} from 'lib/retry';
import {
  CreateTask,
  FindTask,
  PollingCallback,
  useTaskManager,
} from 'modules/ai-frontend/services';
import {TaskParams, TaskType} from 'modules/ai-frontend/types';
import {BilingualDialogueComposition} from 'modules/composition/models/BilingualDialogueComposition';
import {BilingualStoryComposition} from 'modules/composition/models/BilingualStoryComposition';
import {Composition} from 'modules/composition/models/Composition';
import {ProjectType} from 'modules/project/types';
import {getSceneImage} from 'modules/project/utils';
import {BilingualDialogueProjectConfig} from 'modules/project-config/models/BilingualDialogueProjectConfig';
import {BilingualStoryProjectConfig} from 'modules/project-config/models/BilingualStoryProjectConfig';
import {GeneralStoryProjectConfig} from 'modules/project-config/models/GeneralStoryProjectConfig';
import {ShortVideoProjectConfig} from 'modules/project-config/models/ShortVideoProjectConfig';
import {ThumbnailType} from 'modules/project-config/types';
import {isWithThumbnailByProjectConfig} from 'modules/project-config/utils';
import {
  BilingualDialogueClosedScene,
  BilingualStoryClosedScene,
  ClosedScene,
} from 'modules/scene/types';
import {MergeTaskCallbackParams} from 'modules/storyboard/services';
import {Task} from 'modules/task/models/Task';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {CompositionPage} from './CompositionPage';
import {HookParams, HookReturn} from './CompositionPage.types';

function useHook({
  projectConfig,
  projectId,
  composition,
  updateComposition,
  onBack,
}: HookParams): HookReturn {
  const hasExcuteRef = useRef(false);
  const {
    resUrl,
    voiceoversOf,
    getBilingualStoryVoiceover,
    getDefaultVoiceover,
    bgmsOf,
    getTextStyleJson,
    getTransitions,
    getTitleBilingualCombinationByName,
    getSubtitleBilingualCombinationByName,
    getTitleStyleByName,
    getSubtitleStyleByName,
  } = useResourceManager();
  //showDialog
  const ratio = useMemo(() => {
    return formatAspectRatio(composition.size);
  }, [composition.size]);
  const task =
    composition?.task && !composition?.task.isEndedStatus
      ? composition?.task
      : undefined;

  const onSummaryChange = useCallback(
    (summary: string) => {
      updateComposition(composition.patch({description: summary}), true);
    },
    [composition, updateComposition]
  );
  const onHashTagsChange = useCallback(
    (hashtags: string[]) => {
      updateComposition(composition.patch({hashtags}), true);
    },
    [composition, updateComposition]
  );

  const {refreshAssetUrl, executeTask, assetUrl} = useNewHook({
    projectId,
    composition,
    updateComposition,
    onBack,
  });
  const getEffect = useCallback(() => {
    if (!projectConfig.effect) return undefined;
    const effect = projectConfig.effect;
    if (typeof effect === 'string') return effect;
    else {
      return {
        type: effect.type,
        max_zoom_ratio: effect.ratio,
      };
    }
  }, [projectConfig.effect]);

  const getThumbnail = useCallback(
    (scenes: ClosedScene<ProjectType>[], thumbnailType: ThumbnailType) => {
      if (thumbnailType === 'Image') return getSceneImage(scenes![0]);
      else return `color${thumbnailType}`;
    },
    []
  );

  useEffect(() => {
    if (hasExcuteRef.current) return;
    if (composition.task) return;
    hasExcuteRef.current = true;
    (async function createCompositeVideoTask() {
      const voiceoverList =
        composition instanceof BilingualStoryComposition ||
        composition instanceof BilingualDialogueComposition
          ? getBilingualStoryVoiceover()
          : voiceoversOf(composition.language);

      const voiceover =
        (projectConfig instanceof GeneralStoryProjectConfig ||
          projectConfig instanceof BilingualStoryProjectConfig ||
          projectConfig instanceof ShortVideoProjectConfig) &&
        projectConfig.voiceover
          ? voiceoverList.find(({name}) => name === projectConfig.voiceover) ||
            null
          : null;
      const finalVoiceover = voiceover ?? voiceoverList[0];
      const bgm =
        bgmsOf(
          projectConfig instanceof BilingualStoryProjectConfig ||
            projectConfig instanceof BilingualDialogueProjectConfig
            ? 'BILINGUAL'
            : 'ALL'
        ).find(({name}) => name === projectConfig.bgm) ?? null;

      const titleBilingualCombination =
          composition instanceof BilingualStoryComposition ||
          composition instanceof BilingualDialogueComposition
            ? getTitleBilingualCombinationByName(projectConfig.titleStyle)
            : undefined,
        subtitleBilingualCombination =
          composition instanceof BilingualStoryComposition ||
          composition instanceof BilingualDialogueComposition
            ? getSubtitleBilingualCombinationByName(projectConfig.subtitleStyle)
            : undefined;

      //titleStyleJson
      const titleStyle = getTitleStyleByName(
        titleBilingualCombination?.target_style ?? projectConfig.titleStyle
      );

      let titleStyleJson = titleStyle
        ? await getTextStyleJson(resUrl, titleStyle)
        : undefined;
      if (
        (composition instanceof BilingualStoryComposition ||
          composition instanceof BilingualDialogueComposition) &&
        titleStyleJson
      ) {
        titleStyleJson = titleBilingualCombination
          ? ({
              ...titleStyleJson,
              margin_preset:
                titleBilingualCombination.target_margin_preset ??
                titleStyleJson?.margin_preset,
            } as Record<string, string>)
          : titleStyleJson;
      }

      //nativeTitleStyleJson
      const nativeTitleStyle = getTitleStyleByName(
        titleBilingualCombination?.native_style
      );

      let nativeTitleStyleJson = nativeTitleStyle
        ? await getTextStyleJson(resUrl, nativeTitleStyle)
        : undefined;

      if (
        (composition instanceof BilingualStoryComposition ||
          composition instanceof BilingualDialogueComposition) &&
        nativeTitleStyleJson
      ) {
        nativeTitleStyleJson = titleBilingualCombination
          ? ({
              ...nativeTitleStyleJson,
              margin_preset:
                titleBilingualCombination.native_margin_preset ??
                nativeTitleStyleJson?.margin_preset,
            } as Record<string, string>)
          : nativeTitleStyleJson;
      }

      const subtitleStyle = getSubtitleStyleByName(
        subtitleBilingualCombination?.target_style ??
          projectConfig.subtitleStyle
      );
      let subtitleStyleJson = subtitleStyle
        ? await getTextStyleJson(resUrl, subtitleStyle)
        : undefined;

      if (
        (composition instanceof BilingualStoryComposition ||
          composition instanceof BilingualDialogueComposition) &&
        subtitleStyleJson
      ) {
        subtitleStyleJson = subtitleBilingualCombination
          ? ({
              ...subtitleStyleJson,
              margin_preset:
                subtitleBilingualCombination.target_margin_preset ??
                subtitleStyleJson?.margin_preset,
            } as Record<string, string>)
          : subtitleStyleJson;
      }

      //nativeSubtitleStyleJson
      const nativeSubtitleStyle = getSubtitleStyleByName(
        subtitleBilingualCombination?.native_style
      );
      let nativeSubtitleStyleJson = nativeSubtitleStyle
        ? await getTextStyleJson(resUrl, nativeSubtitleStyle)
        : undefined;
      if (
        (composition instanceof BilingualStoryComposition ||
          composition instanceof BilingualDialogueComposition) &&
        nativeSubtitleStyleJson
      ) {
        nativeSubtitleStyleJson = subtitleBilingualCombination
          ? ({
              ...nativeSubtitleStyleJson,
              margin_preset:
                subtitleBilingualCombination.native_margin_preset ??
                nativeSubtitleStyleJson?.margin_preset,
            } as Record<string, string>)
          : nativeSubtitleStyleJson;
      }

      const transition =
        (projectConfig.transition &&
          getTransitions().find(t => t.value === projectConfig.transition)) ||
        null;

      executeTask('composite_video', {
        title: composition.title,
        native_title:
          composition instanceof BilingualStoryComposition ||
          composition instanceof BilingualDialogueComposition
            ? composition.nativeTitle
            : undefined,
        scenes:
          composition.scenes?.map(scene => ({
            type: scene.type,
            image: scene.image,
            video: scene.video,
            subtitle: scene.subtitle!.length > 0 ? scene.subtitle : undefined,
            native_subtitle:
              composition instanceof BilingualStoryComposition ||
              composition instanceof BilingualDialogueComposition
                ? (
                    scene as
                      | BilingualStoryClosedScene
                      | BilingualDialogueClosedScene
                  ).native_subtitle
                : undefined,
            speaker:
              composition instanceof BilingualDialogueComposition
                ? (scene as BilingualDialogueClosedScene).speaker
                : undefined,
          })) || [],
        thumbnail:
          (isWithThumbnailByProjectConfig(projectConfig) &&
            getThumbnail(
              composition.scenes,
              projectConfig.thumbnailType || 'Image'
            )) ||
          undefined,
        video_config: {
          size: composition.size,
          effect: getEffect(),
          include_thumbnail:
            isWithThumbnailByProjectConfig(projectConfig) &&
            projectConfig.thumbnailIncludeVideo,
          title: titleStyleJson ? {style: titleStyleJson} : undefined,
          native_title: nativeTitleStyleJson
            ? {style: nativeTitleStyleJson}
            : undefined,
          subtitle: subtitleStyleJson
            ? {
                style: subtitleStyleJson,
              }
            : undefined,
          native_subtitle: nativeSubtitleStyleJson
            ? {
                style: nativeSubtitleStyleJson,
              }
            : undefined,
          voiceover_order:
            projectConfig instanceof BilingualStoryProjectConfig ||
            projectConfig instanceof BilingualDialogueProjectConfig
              ? projectConfig.voiceoverOrder
              : undefined,
          use_ending_effect: false,
          transition: transition
            ? {type: transition.value, duration: transition.duration}
            : undefined,
          bgm: bgm
            ? {
                audio: `${resUrl}${bgm.asset}`,
                volume: bgm.volume ?? 0.5,
              }
            : undefined,
          keywords:
            composition instanceof BilingualStoryComposition ||
            composition instanceof BilingualDialogueComposition
              ? composition.vocabulary
              : undefined,
        },
        // 当没有voiceover的时候，传递一个费用较低的voiceover来生成视频，在合成视频的时候
        // 会将voiceover mute掉，因为视频中每个场景的时长是根据voiceover的时长来计算的
        voice_config: {
          type:
            projectConfig instanceof BilingualDialogueProjectConfig
              ? undefined
              : finalVoiceover.name,
          mute:
            projectConfig instanceof BilingualDialogueProjectConfig
              ? undefined
              : voiceover === null
              ? true
              : undefined,
          agent:
            projectConfig instanceof BilingualDialogueProjectConfig
              ? undefined
              : finalVoiceover.agent,
          ...((projectConfig instanceof GeneralStoryProjectConfig ||
            projectConfig instanceof ShortVideoProjectConfig) &&
          projectConfig.voiceoverSpeed === 'fast'
            ? {speed: finalVoiceover.speed.fast}
            : {}),
          voices:
            projectConfig instanceof BilingualDialogueProjectConfig
              ? projectConfig.voiceovers
                  .map((voiceoverName, idx) => {
                    const voiceoverData: VoiceoverDataItem | undefined =
                      voiceoverList.find(({name}) => name === voiceoverName);
                    if (!voiceoverData) return undefined;
                    const character = composition.characters[idx];
                    return {
                      name: character?.name ?? '',
                      config: {
                        agent: voiceoverData?.agent,
                        type: voiceoverData?.name,
                      },
                    };
                  })
                  .filter(Boolean)
              : undefined,
        },
      });
    })();
  }, [
    bgmsOf,
    executeTask,
    getDefaultVoiceover,
    getEffect,
    getTextStyleJson,
    getThumbnail,
    composition,
    projectConfig,
    ratio,
    resUrl,
    voiceoversOf,
    getTransitions,
    getBilingualStoryVoiceover,
    getTitleBilingualCombinationByName,
    getSubtitleBilingualCombinationByName,
    getTitleStyleByName,
    getSubtitleStyleByName,
  ]);
  return {
    composition,
    assetUrl,
    isProcessing: task?.status !== 'success',
    ratio,
    projectId: projectId,
    onBack,
    refreshAssetUrl,
    onSummaryChange,
    onHashTagsChange,
  };
}

export const CompositionPageContainer = combine(useHook, [
  'onBack',
  'projectConfig',
  'projectId',
  'updateComposition',
  'updateProject',
  'composition',
])(CompositionPage);

function useNewHook({
  projectId,
  composition,
  updateComposition,
  onBack,
}: {
  projectId: string;
  composition: Composition<ProjectType>;
  updateComposition: (
    state: React.SetStateAction<Composition<ProjectType>>,
    delay?: number | boolean | undefined
  ) => void;
  onBack: () => void;
}) {
  const {aiFrontendClient} = useAPI();
  const {report} = useError();
  const {updateCredit} = useUserContext();
  const [assetUrl, setAssetUrl] = useState<string | null>();
  const mergeTaskCallback = useCallback(
    async (...[task]: MergeTaskCallbackParams) => {
      updateCredit();
      if (task.status === 'failure') {
        report(ErrorType.Service);
        onBack && onBack();
      }
    },
    [onBack, report, updateCredit]
  );

  const pollingCallback = useCallback(
    (...params: Parameters<PollingCallback<TaskType.Shooting>>) => {
      const [_, taskId, task] = params;
      if (task.closed) {
        const nextTask = Task.fromJSON({
          id: task.id,
          type: 'composite_video',
          status: task.status,
        });
        updateComposition(
          composition.patch({
            task: nextTask,
            asset: task.synthesis?.asset,
            thumbnail: task.synthesis?.thumbnail,
            createTime: task.createTime,
            assetProduct: task.synthesis?.asset_product,
          }),
          false
        );
        mergeTaskCallback(nextTask, {});
      } else {
        if (!composition.task || composition.task.id !== taskId) {
          throw new Error('Invalid task');
        }
        const nextTask = composition.task.patch({
          status: task.status,
          progress: task.progress?.ratio,
        });
        updateComposition(composition.patch({task: nextTask}));
      }
    },
    [composition, mergeTaskCallback, updateComposition]
  );

  const createTask: CreateTask<TaskType.Shooting, null> = useMemo(
    () => aiFrontendClient.createTask.bind(aiFrontendClient),
    [aiFrontendClient]
  );

  const findTask: FindTask<TaskType.Shooting> = useMemo(
    () => aiFrontendClient.findTask.bind(aiFrontendClient),
    [aiFrontendClient]
  );

  const getAssetUrl = useCallback(
    async (assetProduct: string) => {
      return await aiFrontendClient.getAssetUrl(assetProduct);
    },
    [aiFrontendClient]
  );

  const {execute, poll, clearAll} = useTaskManager<TaskType.Shooting, null>(
    createTask,
    findTask,
    pollingCallback
  );

  const executeTask = useCallback(
    async (
      type: 'composite_video',
      data: TaskParams<TaskType.Shooting>['data']
    ) => {
      try {
        const taskId = await execute(TaskType.Shooting, {
          data,
          projectId,
        } as TaskParams<TaskType.Shooting>);
        const task = new Task(taskId, type, 'created');
        updateComposition(composition.patch({task}), false);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          throw err;
        }
        onBack && onBack();
        if (axios.isAxiosError(err) && err?.status === 402) {
          report(ErrorType.NoCredit, err);
        } else {
          report(ErrorType.Service, err);
        }
      }
    },
    [composition, execute, onBack, projectId, report, updateComposition]
  );

  useEffect(() => {
    clearAll();
  }, [clearAll]);

  useEffect(() => {
    let ignore = false;
    if (!composition.assetProduct) {
      setAssetUrl(null);
      return;
    }
    getAssetUrl(composition.assetProduct)
      .then(url => {
        !ignore && setAssetUrl(url);
      })
      .catch(() => {
        // 无权限
        !ignore && setAssetUrl(null);
      });
    return () => {
      ignore = true;
      setAssetUrl(undefined);
    };
  }, [composition.assetProduct, getAssetUrl]);

  const refreshAssetUrl = useCallback(async () => {
    if (!composition.assetProduct) return;
    try {
      const url = await retryIgnoreError(
        {times: 5, interval: 1000},
        getAssetUrl,
        composition.assetProduct
      );
      setAssetUrl(url);
    } catch {
      setAssetUrl(null);
    }
  }, [composition.assetProduct, getAssetUrl]);

  useEffect(() => {
    if (!composition.task) return;
    if (composition.task.isEndedStatus) return;
    poll(TaskType.Shooting, composition.task.id, {immediately: true});
  }, [composition, poll]);

  return {executeTask, assetUrl, refreshAssetUrl};
}
