// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useAPI} from 'contexts/APIContext';
import {useRawVideoPayment} from 'modules/payment/services/raw-video';
import {useCallback} from 'react';
import {openStripeCheckout} from 'utils/stripe';

import {VideoDownloadToast} from './VideoDownloadToast';
import {HookParams, HookReturn} from './VideoDownloadToast.types';

function useHook({
  videoId,
  type,
  outTradeNo,
  historyId,
  projectId,
  onCancel,
  onDownload,
  refreshAssetUrl,
}: HookParams): HookReturn {
  const {aiFrontendClient} = useAPI();
  const {buyVideo} = useRawVideoPayment();

  const onConfirm = useCallback(
    async (isChecked: boolean) => {
      if (!isChecked) {
        onDownload();
        return onCancel();
      }
      try {
        await aiFrontendClient.getAssetUrl(videoId);
        await refreshAssetUrl();
      } catch {
        const params = {type, projectId};
        const session = await buyVideo(videoId, {
          ...params,
          historyId: historyId ?? '',
        });
        await openStripeCheckout(session);
      }
    },
    [
      onDownload,
      onCancel,
      aiFrontendClient,
      videoId,
      refreshAssetUrl,
      type,
      projectId,
      buyVideo,
      historyId,
    ]
  );

  return {
    outTradeNo,
    onConfirm,
    onCancel,
  };
}

export const VideoDownloadToastContainer = combine(useHook, [
  'videoId',
  'type',
  'onCancel',
  'outTradeNo',
  'projectId',
  'onDownload',
  'historyId',
  'refreshAssetUrl',
])(VideoDownloadToast);
