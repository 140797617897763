// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import {combine} from 'components/Combine';
import {PopoverHandle} from 'components/PopoverNew/Popover';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useCallback, useRef, useState} from 'react';

import {
  UseHistoryModalType,
  useModalStatus,
  useMyProjects,
  useProjectOrGalleryModal,
} from '../modules';
import {
  GalleryItemType,
  isProjectItemType,
  ProjectItemType,
  ProjectOrGalleryItemType,
} from '../WorkspacePage.types';
import {ProjectItem} from './ProjectItem';

type UseHookParamsType = {
  project: ProjectOrGalleryItemType;
  refreshProjectList?: ReturnType<typeof useMyProjects>['refreshProjectList'];
  onclickHistoryBtn?: UseHistoryModalType[3];
  onClickUseBtn?: (projectId: string) => void;
  onclickProjectOrGalleryItem: ReturnType<typeof useProjectOrGalleryModal>[3];
  onClickDuplicateBtn?: (projectId: string) => void;
  refreshAfterDelete?: ReturnType<typeof useMyProjects>['refreshAfterDelete'];
};
type RenameProjectParamsType = (
  project: ProjectItemType,
  newProjectName: string
) => void;

export type UseHookReturnType = {
  deleteProject?: (projectId: string) => void;
  handleClick: (e: React.MouseEvent) => void;
  deleteConfirmVisible: boolean;
  hideDeleteConfirmModal: () => void;
  popoverRef: React.RefObject<PopoverHandle>;
  isRename: boolean;
  onConfirmDelete: () => void;
  project: ProjectOrGalleryItemType;
  onRename: (value: string) => void;
};

function useHook({
  refreshProjectList,
  onclickProjectOrGalleryItem,
  onclickHistoryBtn,
  onClickDuplicateBtn,
  onClickUseBtn,
  project,
  refreshAfterDelete,
}: UseHookParamsType): UseHookReturnType {
  const [deleteConfirmVisible, showDeleteConfirmModal, hideDeleteConfirmModal] =
    useModalStatus();
  const popoverRef = useRef<PopoverHandle>(null);
  const [isRename, setIsRename] = useState(false);
  const {showNotification} = useNotificationContext();

  const deleteProject = useCallback(
    async (projectId: string) => {
      const res = await apiServer
        .deleteProjectById(projectId)
        .then(_ => {
          showNotification({
            type: 'SUCCESS',
            message: 'Delete successfully',
          });
          return true;
        })
        .catch(_ => {
          showNotification({
            type: 'ERROR',
            message: 'Delete failed',
          });
        });
      if (res) {
        if (refreshAfterDelete) {
          refreshAfterDelete(projectId, 'projectId');
        } else {
          refreshProjectList && refreshProjectList();
        }
      }
    },
    [refreshAfterDelete, refreshProjectList, showNotification]
  );
  const renameProject = useCallback<RenameProjectParamsType>(
    async (project, newProjectName) => {
      await apiServer.updateProject({
        projectId: project.projectId,
        projectName: newProjectName?.substring(0, 100),
      });
      refreshProjectList?.();
    },
    [refreshProjectList]
  );
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const action = (e.currentTarget as HTMLDivElement)?.dataset.action;
    if (action === 'duplicate') {
      onClickDuplicateBtn &&
        onClickDuplicateBtn((project as ProjectItemType).projectId);
      popoverRef.current?.close();
    } else if (action === 'history') {
      onclickHistoryBtn && onclickHistoryBtn(project);
    } else if (action === 'use') {
      onClickUseBtn && onClickUseBtn((project as GalleryItemType).galleryId);
    } else if (action === 'click') {
      onclickProjectOrGalleryItem && onclickProjectOrGalleryItem(project);
    } else if (action === 'rename') {
      popoverRef.current?.close();
      setIsRename(true);
    } else if (action === 'delete') {
      popoverRef.current?.close();
      showDeleteConfirmModal();
    }
  };

  const onRename = useCallback(
    (value: string) => {
      setIsRename(false);
      if (isProjectItemType(project)) {
        return renameProject(project, value);
      }
    },
    [project, renameProject, setIsRename]
  );

  const onConfirmDelete = () => {
    if (isProjectItemType(project)) {
      deleteProject && deleteProject(project.projectId);
    }
    hideDeleteConfirmModal();
  };
  return {
    deleteProject,
    handleClick,
    deleteConfirmVisible,
    hideDeleteConfirmModal,
    popoverRef,
    isRename,
    onConfirmDelete,
    project,
    onRename,
  };
}
export const ProjectItemContainer = combine(useHook, [
  'refreshProjectList',
  'onclickProjectOrGalleryItem',
  'onClickDuplicateBtn',
  'onclickHistoryBtn',
  'onClickUseBtn',
  'project',
  'refreshAfterDelete',
])(ProjectItem);
