// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import BilingualDialogueHeaderBg from 'assets/images/bilingual-dialogue-header-bg.png';
import BilingualStoryHeaderBg from 'assets/images/bilingual-story-header-bg.png';
import ShortVideoHeaderBg from 'assets/images/faceless-short-videos-header-bg.png';
import GeneralStoryHeaderBg from 'assets/images/general-creation-header-bg.png';
import {ReactComponent as LeftArrowIcon} from 'assets/svg/3.0/ArrowLeft.svg';
import {ReactComponent as HomeIcon} from 'assets/svg/outline/Home.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {trimNewline} from 'modules/project/utils';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {WORKSPACE_PAGE_GENERATE_PATH} from 'utils/path';

import styles from './Header.module.scss';
import {Props} from './Header.types';

export function Header({
  title,
  right,
  background,
  isScrollTop = true,
  languageCode,
  children,
  onBack,
  onHome,
  labelType = 'general_story',
  showTag = true,
  showBg = true,
}: Props) {
  const navigate = useNavigate();
  const backgroundImageUrl = useMemo(() => {
    if (labelType === 'bilingual_story') return BilingualStoryHeaderBg;
    if (labelType === 'bilingual_dialogue') return BilingualDialogueHeaderBg;
    if (labelType === 'general_story') return GeneralStoryHeaderBg;
    if (labelType === 'short_video') return ShortVideoHeaderBg;
    return '';
  }, [labelType]);
  const {t} = useTranslation('project');
  const {t: commonT} = useTranslation();
  const tag = useMemo(() => {
    if (labelType === 'bilingual_story') return t('Bilingual story');
    if (labelType === 'bilingual_dialogue') return t('Bilingual dialogue');
    if (labelType === 'general_story') return t('General creation');
    if (labelType === 'short_video') return t('Faceless short videos');
    return '';
  }, [labelType, t]);
  return (
    <div
      className={classNames(styles.container, 'header-container', {
        [styles.background]: !isScrollTop,
        [styles.small]: !isScrollTop,
      })}
      style={{
        background: isScrollTop ? background : undefined,
      }}
    >
      <div className={styles.left}>
        {children || (
          <Popover
            triggerElement={
              <button
                className={classNames(styles.back, styles.button)}
                onClick={() => {
                  onHome?.();
                  navigate(WORKSPACE_PAGE_GENERATE_PATH);
                }}
              >
                <HomeIcon className={styles.icon} />
              </button>
            }
            trigger="hover"
            content={
              <div className={styles['popover-content']}>
                {t('Back to workspace')}
              </div>
            }
            direction="bottom"
            distance={6}
            popoverClassName={styles['popover-container']}
          />
        )}

        {onBack && (
          <button
            className={classNames(styles.prev, styles.button)}
            onClick={onBack}
          >
            <LeftArrowIcon className={styles.icon} />
          </button>
        )}
        {showTag && tag && (
          <div className={classNames(styles.tag, styles['xs-hide'])}>{tag}</div>
        )}
        {title !== null && (
          <p
            className={classNames(
              styles.title,
              'rtl-element',
              styles['xs-hide']
            )}
          >
            {trimNewline(title || commonT('Untitled'), languageCode)}
          </p>
        )}
      </div>
      <div className={styles.right}>{right}</div>
      <div className={styles['xs-title']}>
        {showTag && tag && <div className={styles.tag}>{tag}</div>}
        {title !== null && (
          <p className={classNames(styles.title, 'rtl-element')}>
            {trimNewline(title || commonT('Untitled'), languageCode)}
          </p>
        )}
      </div>
      {showBg && (
        <div
          className={styles.bg}
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
          }}
        />
      )}
    </div>
  );
}
