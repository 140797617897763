// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {clearReferralCode, getReferralCode} from 'contexts/localStorage';
import {useUserContext} from 'contexts/UserContext';
import {useVisible} from 'lib/hooks';
import {useQueryString} from 'pages/AuthPage/modules';
import {useEffect, useRef, useState} from 'react';
import {LOGIN_PATH, ROOT_PATH} from 'utils/path';
import {bindInviterAndInvitee} from 'utils/rewardful';

import {Firewall} from './Firewall';
import {ErrorToastType, HookReturn} from './Firewall.types';

function useHook(): HookReturn {
  const {updateUserLogin, isLogin} = useUserContext();
  const getQueryParam = useQueryString();
  const [tokenInQueryStringStatus, setTokenInQueryStringStatus] = useState<
    boolean | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFetchingRef = useRef<boolean>(false);
  const isAfterConfirm = useRef<boolean>(false);
  const [errorToastVisible, hideErrorToast, showErrorToast, errorToastType] =
    useVisible<ErrorToastType>();

  const {recordEvent} = useAnalysis();

  // 判断是否登录
  // 通过url中的参数登录成功或通过token登录成功都login成功，否则login失败
  useEffect(() => {
    if (isLogin === true || tokenInQueryStringStatus === true) {
      //成功以某种方式登录
      setIsLoading(false);
    }
    if (isLogin === false && tokenInQueryStringStatus === false) {
      //未登录
      setIsLoading(false);
      if (location.pathname !== LOGIN_PATH) {
        location.href = LOGIN_PATH;
      }
    }
  }, [isLogin, tokenInQueryStringStatus]);
  useEffect(() => {
    if (isLogin === true && isAfterConfirm.current === true) {
      location.href = ROOT_PATH;
      isAfterConfirm.current = false;
    }
  }, [isLogin]);
  useEffect(() => {
    // 获取url中的参数,并登录
    const token = getQueryParam('token'),
      email = getQueryParam('email'),
      isNewRegister = getQueryParam('new-register');

    if (email && token && tokenInQueryStringStatus === undefined) {
      if (isFetchingRef.current) return;
      isFetchingRef.current = true;
      apiServer
        .loginWithEmailAndToken(email, token)
        .then(async res => {
          updateUserLogin(
            email,
            res.data.userName,
            res.data.userId,
            res.data.portrait,
            res.data.token,
            res.data.emailConfirmStatus
          );
          isAfterConfirm.current = true;
          if (tokenInQueryStringStatus === undefined) {
            setTokenInQueryStringStatus(true);
          }
          if (isNewRegister === 'true') {
            const referralCode = getReferralCode();
            if (referralCode) {
              await apiServer.recordReferralCode(referralCode);
              clearReferralCode();
            }
            bindInviterAndInvitee();
            recordEvent('Email-Verified', {userId: res.data.userId});
          }
        })
        .catch(() => {
          if (tokenInQueryStringStatus === undefined) {
            setTokenInQueryStringStatus(false);
          }
        });
    } else {
      if (tokenInQueryStringStatus === undefined) {
        setTokenInQueryStringStatus(false);
      }
    }
  }, [getQueryParam, recordEvent, tokenInQueryStringStatus, updateUserLogin]);

  useEffect(() => {
    const handleUncaughtError = (event: PromiseRejectionEvent) => {
      const error = event.reason;
      if (error && error.message === 'Network Error') {
        showErrorToast('networkError');
        event.preventDefault();
      }
    };
    window.addEventListener('unhandledrejection', handleUncaughtError);
    return () => {
      window.removeEventListener('unhandledrejection', handleUncaughtError);
    };
  }, [showErrorToast]);

  return {
    status: isLoading
      ? 'loading'
      : isLogin === true || tokenInQueryStringStatus === true
      ? 'LoggedIn'
      : 'NotLoggedIn',
    errorToastVisible,
    errorToastType,
    hideErrorToast,
  };
}

export const FirewallContainer = combine(useHook)(Firewall);
