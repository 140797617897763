// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import React, {
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {createPortal} from 'react-dom';

import styles from './Popover.module.scss';

type Trigger = 'click' | 'hover';

interface PopoverProps {
  triggerElement: React.ReactNode;
  content: React.ReactNode;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  bgColor?: string;
  trigger?: Trigger | Trigger[];
  key?: string | number;
  children?: React.ReactNode;
  showBorder?: boolean;
  distance?: number;
  delay?: number;
  className?: string;
  popoverClassName?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  popupMatchSelectWidth?: boolean;
  scrollElement?: HTMLElement;
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  outerEl?: React.RefObject<HTMLElement>;
  isControlByOverflow?: boolean;
  closeOnEscape?: boolean;
}
export interface PopoverHandle {
  close: () => void;
  clearHoverTimeout: () => void;
}
export const Popover = forwardRef<PopoverHandle, PopoverProps>(
  (props: PopoverProps, ref) => {
    const {
      triggerElement,
      content,
      direction = 'right',
      bgColor = 'white',
      trigger = 'click',
      showBorder = true,
      distance = 0,
      delay = 0,
      className,
      disabled = false,
      popupMatchSelectWidth = false,
      scrollElement,
      visible,
      onVisibleChange,
      outerEl,
      isControlByOverflow = false,
      closeOnEscape = true,
      popoverClassName,
    } = props;
    // 在 state 中添加一个用于设置 popover 最大高度的 state
    const [popoverMaxHeight, setPopoverMaxHeight] = useState<number>(
      window.innerHeight
    );
    const [innerVisible, setInnerVisible] = useState<boolean>(visible || false);
    const [position, setPosition] = useState({top: -9999, left: -9999});
    const popoverRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const directionRef = useRef<string>(direction);
    const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const clearHoverTimeout = () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
        hoverTimeoutRef.current = null;
      }
    };
    useEffect(() => {
      if (visible !== undefined) {
        setInnerVisible(visible);
      }
    }, [visible]);
    const changeVisible = useCallback(
      (visibleStatus: boolean) => {
        if (visible === undefined) {
          setInnerVisible(visibleStatus);
        }
        onVisibleChange && onVisibleChange(visibleStatus);
      },
      [onVisibleChange, visible]
    );
    useImperativeHandle(ref, () => ({
      close() {
        changeVisible(false);
      },
      clearHoverTimeout,
    }));

    //popover 的位置计算
    const updatePosition = useCallback(() => {
      const triggerRect = triggerRef.current?.getBoundingClientRect();
      const popoverRect = popoverRef.current?.getBoundingClientRect();
      const outerRect = outerEl?.current?.getBoundingClientRect();
      if (triggerRect && popoverRect) {
        const {innerWidth, innerHeight} = window;
        const newPosition = {top: -9999, left: -9999};
        let maxHeight = innerHeight; // 初始化 maxHeight 为视口高度

        switch (direction) {
          case 'top':
            newPosition.top = triggerRect.top - popoverRect.height - distance;
            newPosition.left = Math.max(
              triggerRect.left + (triggerRect.width - popoverRect.width) / 2,
              0
            );
            maxHeight = Math.min(
              triggerRect.top - (outerRect?.top ?? 0) - distance,
              innerHeight
            );

            if (
              newPosition.top + popoverRect.height >
              (outerRect?.bottom ?? innerHeight)
            ) {
              newPosition.top = triggerRect.top - popoverRect.height - distance;
            }
            break;
          case 'right':
            newPosition.left = triggerRect.right + window.scrollX + distance;
            newPosition.top =
              triggerRect.top + (triggerRect.height - popoverRect.height) / 2;
            if (
              newPosition.left + popoverRect.width >
              (outerRect?.right ?? innerWidth)
            ) {
              newPosition.left =
                triggerRect.left - popoverRect.width - distance;
            }
            if (
              newPosition.top + popoverRect.height >
              (outerRect?.bottom ?? innerHeight)
            ) {
              newPosition.top = triggerRect.top - popoverRect.height;
            }
            maxHeight = Math.min(
              innerHeight,
              (outerRect?.bottom ?? innerHeight) - newPosition.top
            );
            break;
          case 'bottom':
            newPosition.top = triggerRect.bottom + distance;
            newPosition.left = Math.max(
              triggerRect.left + (triggerRect.width - popoverRect.width) / 2,
              0
            );
            if (
              newPosition.top + popoverRect.height >
              (outerRect?.bottom ?? innerHeight)
            ) {
              newPosition.top = triggerRect.top - popoverRect.height;
            }
            maxHeight = Math.min(
              innerHeight,
              (outerRect?.bottom ?? innerHeight) - newPosition.top
            );
            break;
          case 'left':
            newPosition.left = triggerRect.left - popoverRect.width - distance;
            newPosition.top =
              triggerRect.top + (triggerRect.height - popoverRect.height) / 2;
            if (newPosition.left < (outerRect?.left ?? 0)) {
              newPosition.left = triggerRect.right;
            }
            if (newPosition.top + popoverRect.height < (outerRect?.top ?? 0)) {
              newPosition.top = triggerRect.bottom;
            }
            if (
              newPosition.top + popoverRect.height >
              (outerRect?.bottom ?? innerHeight)
            ) {
              newPosition.top = triggerRect.top - popoverRect.height;
            }
            maxHeight = Math.min(
              innerHeight,
              (outerRect?.bottom ?? innerHeight) - newPosition.top
            );
            break;
          default:
            break;
        }

        // Ensure the popover stays within the viewport
        newPosition.left = Math.max(
          0,
          Math.min(newPosition.left, innerWidth - popoverRect.width)
        );
        newPosition.top = Math.max(
          0,
          Math.min(newPosition.top, innerHeight - popoverRect.height)
        );

        setPosition(newPosition);
        setPopoverMaxHeight(maxHeight); // 设置最大高度
      }
    }, [direction, distance, outerEl, triggerRef, popoverRef]);

    useEffect(() => {
      const triggerEl = triggerRef.current;

      // 判断是否超出截断
      const isOverflowing = () => {
        const targetEl =
          triggerRef.current && triggerRef.current.firstElementChild;
        if (targetEl) {
          return (
            targetEl.scrollWidth - targetEl.clientWidth &&
            Math.abs(targetEl.scrollWidth - targetEl.clientWidth) > 1
          );
        }
        return false;
      };

      const handleHover = () => {
        clearHoverTimeout();
        if (isControlByOverflow && !isOverflowing()) return; // 只有在内容超出时才显示Popover
        popoverRef.current?.removeEventListener('mouseleave', handleMouseLeave);
        hoverTimeoutRef.current = setTimeout(() => {
          if (!disabled) changeVisible(true);
        }, delay);
      };

      const handleMouseLeave = (e: MouseEvent | TouchEvent) => {
        clearHoverTimeout();

        // 获取相关目标元素
        const relatedTarget = (e as MouseEvent).relatedTarget as Node | null;

        // 检查是否是触摸事件
        if ('touches' in e) {
          // 如果是触摸事件，不需要检查 relatedTarget
          hoverTimeoutRef.current = setTimeout(() => {
            changeVisible(false);
          }, 200);
        } else if (
          // 如果是鼠标事件，检查 relatedTarget
          triggerRef.current &&
          popoverRef.current &&
          !popoverRef.current.contains(relatedTarget)
        ) {
          hoverTimeoutRef.current = setTimeout(() => {
            changeVisible(false);
          }, 200);
        } else if (popoverRef.current?.contains(relatedTarget)) {
          popoverRef.current.addEventListener('mouseleave', handleMouseLeave);
        }
      };

      const handleMouseClick = () => {
        if (isControlByOverflow && !isOverflowing()) return; // 只有在内容超出时才显示Popover
        changeVisible(!innerVisible);
      };
      if (triggerEl) {
        // hover 和 click 不能同时存在，同时传入后 trigger 为 click
        if (trigger.includes('hover')) {
          if ('ontouchstart' in window) {
            if (!trigger.includes('click')) {
              triggerEl.addEventListener('touchstart', handleHover);
              triggerEl.addEventListener('touchend', handleMouseLeave);
            }
          } else {
            triggerEl.addEventListener('mouseenter', handleHover);
            triggerEl.addEventListener('mouseleave', handleMouseLeave);
          }
        }

        if (trigger.includes('click')) {
          triggerEl.addEventListener('click', handleMouseClick);
        }
      }

      return () => {
        if (triggerEl) {
          if (trigger.includes('hover')) {
            if ('ontouchstart' in window) {
              if (!trigger.includes('click')) {
                triggerEl.removeEventListener('touchstart', handleHover);
                triggerEl.removeEventListener('touchend', handleMouseLeave);
              }
            } else {
              triggerEl.removeEventListener('mouseenter', handleHover);
              triggerEl.removeEventListener('mouseleave', handleMouseLeave);
            }
          }

          if (trigger.includes('click')) {
            triggerEl.removeEventListener('click', handleMouseClick);
          }
        }
      };
    }, [
      delay,
      direction,
      disabled,
      trigger,
      innerVisible,
      changeVisible,
      isControlByOverflow,
    ]); // 依赖 trigger，确保触发方式更新时重新绑定事件

    useEffect(() => {
      if (innerVisible) {
        updatePosition();
      }
      // 页面滚动时调用的函数，用于更新Popover的位置
      const handleScroll = () => {
        if (innerVisible) {
          updatePosition();
        }
      };

      // 绑定滚动事件监听器
      scrollElement?.addEventListener('scroll', handleScroll);

      // 组件卸载时移除滚动事件监听器
      return () => {
        scrollElement?.removeEventListener('scroll', handleScroll);
      };
    }, [innerVisible, updatePosition, scrollElement, triggerElement]);

    //增加一个useEffect，当window resize时，重新计算popover的位置
    useEffect(() => {
      const handleResize = () => {
        if (innerVisible) {
          updatePosition();
        }
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [innerVisible, updatePosition]);

    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target as Node) &&
          triggerRef.current &&
          !triggerRef.current.contains(event.target as Node)
        ) {
          changeVisible(false);
        }
      },
      [changeVisible]
    );

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [handleClickOutside]);

    const popoverStyle = {
      top: `${position.top}px`,
      left: `${position.left}px`,
      maxHeight: `${popoverMaxHeight}px`, // 设置 maxHeight 以防止内容溢出
      '--popover-bgColor': bgColor,
      ...props.style,
    };
    if (popupMatchSelectWidth) {
      popoverStyle.width = `${triggerRef.current?.clientWidth}px`;
    }
    const _triggerElement = cloneElement(triggerElement as React.ReactElement, {
      className: classNames(
        (triggerElement as React.ReactElement).props.className,
        {
          isPopOverOpen: innerVisible,
        }
      ),
    });

    useEffect(() => {
      if (!closeOnEscape) return;
      const handler = (e: KeyboardEvent) => {
        if (e.code === 'Escape') changeVisible(false);
      };
      document.addEventListener('keydown', handler);
      return () => {
        document.removeEventListener('keydown', handler);
      };
    }, [changeVisible, closeOnEscape]);
    //如果content 是React.ReactNode，将updatePosition传入content
    let _content = content;
    //是ReactElement的话，但不是dom元素，将updatePosition传入content
    if (isValidElement(content) && typeof content.type !== 'string') {
      _content = cloneElement(content as React.ReactElement, {
        updatePosition,
      });
    }

    return (
      <div className={classNames(styles.popoverContainer, [className])}>
        <div ref={triggerRef} className={styles.triggerElement}>
          {_triggerElement}
        </div>
        {innerVisible &&
          createPortal(
            <div
              className={classNames(
                styles.popoverContent,
                {
                  [styles.visible]: innerVisible,
                  [styles.showBorder]: showBorder,
                },
                popoverClassName
              )}
              data-direction={directionRef.current}
              ref={popoverRef}
              style={popoverStyle}
              onMouseDown={e => e.stopPropagation()}
            >
              {_content}
            </div>,
            document.body
          )}
      </div>
    );
  }
);
Popover.displayName = 'Popover';
