// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import * as apiServer from 'api/server';
import * as apiServerAi from 'api/server-ai';
import axios, {AxiosRequestConfig} from 'axios';
import {useAPI} from 'contexts/APIContext';
import {useLazyLoad} from 'lib/hooks';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {generateIdPath, PROJECT_PATH} from 'utils/path';

import {
  GalleryItemType,
  isProjectItemType,
  ProjectItemListResponse,
  ProjectItemType,
  ProjectOrGalleryItemType,
} from '../WorkspacePage.types';
export type PoseImageType = 'image/png' | 'image/jpeg';
export type UploadStatus = 'confirm' | 'failure' | 'temporary';
export type UseProjectOrGalleryModalType = [
  boolean,
  () => void,
  Record<string, any> | null,
  (projectOrGallery: ProjectOrGalleryItemType) => void
];
export type UseHistoryModalType = [
  boolean,
  () => void,
  Record<string, any> | null,
  (projectOrGallery: ProjectOrGalleryItemType) => void
];
export function useProjectOrGalleryModal(): UseProjectOrGalleryModalType {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState<Record<string, any> | null>(
    null
  );
  const onClickProjectOrGalleryItem = async (
    projectOrGallery: ProjectOrGalleryItemType
  ) => {
    if (isProjectItemType(projectOrGallery)) {
      navigate(generateIdPath(PROJECT_PATH, projectOrGallery.projectId));
    } else {
      const projectInfo = await apiServer.getGalleryById(
        projectOrGallery.galleryId
      );
      setCurrentItem(projectInfo);
      setVisible(true);
    }
  };
  const hideModalVisible = useCallback(() => {
    setVisible(false);
  }, []);
  return [visible, hideModalVisible, currentItem, onClickProjectOrGalleryItem];
}
export function useHistoryModal(): UseHistoryModalType {
  const [visible, setVisible] = useState(false);
  const {backendClient} = useAPI();
  const [currentItem, setCurrentItem] = useState<Record<string, any> | null>(
    null
  );
  const onClickProjectOrGalleryItem = async (
    projectOrGallery: ProjectOrGalleryItemType
  ) => {
    if (isProjectItemType(projectOrGallery)) {
      const projectInfo = await backendClient.getProject(
        projectOrGallery.projectId,
        true
      );
      if (projectInfo) {
        setCurrentItem(projectInfo);
        setVisible(true);
      }
    } else {
      const projectInfo = await apiServer.getGalleryById(
        projectOrGallery.galleryId
      );
      setCurrentItem(projectInfo);
      setVisible(true);
    }
  };
  const hideVisible = useCallback(() => {
    setVisible(false);
  }, []);
  return [visible, hideVisible, currentItem, onClickProjectOrGalleryItem];
}
export function useRecentProjects(): [
  ProjectItemListResponse,
  () => Promise<void>,
  'loading' | 'success' | 'error'
] {
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>(
    'loading'
  );
  const [projects, setProjects] = useState<ProjectItemListResponse>([]);

  const refreshRecentProjectsList = useCallback(() => {
    return new Promise<void>(resolve => {
      apiServer.getRecentProjectList().then(res => {
        setProjects(res.data);
      });
      resolve();
    });
  }, []);

  useEffect(() => {
    refreshRecentProjectsList()
      .then(() => {
        setStatus('success');
      })
      .catch(() => {
        setStatus('error');
      });
  }, [refreshRecentProjectsList]);

  return [projects, refreshRecentProjectsList, status];
}

export function useGalleryProjects(pageSize = 20, currentTag?: string) {
  const {backendClient} = useAPI();
  const lastTagRef = useRef<string | null>(currentTag || 'all');
  const [activeTag, setActiveTag] = useState<string>(currentTag || 'all');
  const [isRefreshFromFirstPage, setIsRefreshFromFirstPage] = useState(false);
  const [galleryTagList, setGalleryTagList] = useState<
    {label: string; value: string}[]
  >([]);
  const {t} = useTranslation('workspace');
  const translatedTagList = useMemo(
    () => galleryTagList.map(item => ({...item, label: t(item.label)})),
    [galleryTagList, t]
  );

  const fetchItems = useCallback(
    (page: number, pageSize: number) => {
      if (activeTag && activeTag !== 'all') {
        return backendClient.getGalleryByCategory(activeTag, page, 50);
      } else {
        return backendClient.getGalleryList(page, pageSize);
      }
    },
    [activeTag, backendClient]
  );

  const {
    data,
    loadMore,
    hasMore,
    isLoading,
    refresh,
    setLoadMoreTrigger,
    loadMoreRef,
  } = useLazyLoad<GalleryItemType>({
    fetchData: fetchItems,
    pageSize: pageSize,
  });

  useEffect(() => {
    if (hasMore && !isLoading) {
      setLoadMoreTrigger(loadMoreRef.current);
    }
  }, [hasMore, isLoading, loadMoreRef, setLoadMoreTrigger]);

  useEffect(() => {
    //activeTag变化时，重新获取数据,标识是刷新
    setIsRefreshFromFirstPage(true);
    if (lastTagRef.current !== activeTag) {
      lastTagRef.current = activeTag;
      refresh();
    }
  }, [activeTag, refresh]);

  useEffect(() => {
    if (isRefreshFromFirstPage && !isLoading) {
      setIsRefreshFromFirstPage(false);
    }
  }, [isLoading, isRefreshFromFirstPage]);

  //useEffect获取tag列表
  useEffect(() => {
    backendClient.getGalleryTags().then(setGalleryTagList);
  }, [backendClient]);

  return {
    galleryTagList: translatedTagList,
    galleryList: data,
    isRefreshFromFirstPage,
    refreshGalleryList: refresh,
    changeTag: setActiveTag,
    activeTag,
    hasMore,
    loadMore,
    isLoading,
    loadMoreRef,
  };
}
export function useMyProjects() {
  const fetchItems = useCallback((page: number, pageSize: number) => {
    return apiServer.getMyProjectList(page, pageSize).then(res => res.data);
  }, []);
  const {
    data,
    loadMore,
    hasMore,
    isLoading,
    refresh,
    setLoadMoreTrigger,
    loadMoreRef,
    refreshAfterDelete,
  } = useLazyLoad<ProjectItemType>({
    fetchData: fetchItems,
    pageSize: 10, // 每页展示条数,
  });
  useEffect(() => {
    if (hasMore && !isLoading) {
      setLoadMoreTrigger(loadMoreRef.current);
    }
  }, [hasMore, isLoading, loadMoreRef, setLoadMoreTrigger]);
  return {
    projectItemList: data,
    refreshProjectList: refresh,
    hasMore,
    loadMore,
    isLoading,
    loadMoreRef,
    refreshAfterDelete,
  };
}
export type UploadTypeEnum = 'db' | 'cdn-st';
export function useAwsFileUpload(
  successfullyUploadCallback: (objectKey: string | string[]) => void,
  failUploadCallback: (objectKey: string | string[]) => void
) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const assetIdRef = useRef<string>('');

  const uploadFile = async (
    fileInfo: Record<string, File>,
    uploadType: UploadTypeEnum = 'db',
    target?: uploadFormTargetType,
    content_type?: Record<string, PoseImageType>,
    options?: {uploader?: 'system' | 'user'}
  ) => {
    if (!fileInfo) return;

    const constructFormDataRes = (await constructFormData(
      fileInfo,
      uploadType,
      target,
      content_type,
      options
    )) as constructFormDataResponseType;
    if (Array.isArray(constructFormDataRes)) {
      setUploadProgress(0);
      setIsUploading(true);
      const allPromise: Promise<string>[] = [];
      constructFormDataRes.forEach(constructFormDataResTtem => {
        allPromise.push(
          new Promise((resolve, reject) => {
            const {formData, url, objectKey} = constructFormDataResTtem;
            return axios
              .post(url, formData)
              .then(_ => {
                setUploadProgress(prev =>
                  prev + Math.ceil(100 / constructFormDataRes.length) > 99
                    ? 100
                    : prev + Math.ceil(100 / constructFormDataRes.length)
                );

                resolve(objectKey);
              })
              .catch(_ => {
                reject(objectKey);
              });
          })
        );
      });
      Promise.all(allPromise)
        .then(res => {
          successfullyUploadCallback && successfullyUploadCallback(res);
        })
        .catch(res => {
          failUploadCallback && failUploadCallback(res);
        });
    } else {
      setUploadProgress(0);
      setIsUploading(true);
      const {formData, url, assetId, objectKey} = constructFormDataRes;
      assetIdRef.current = assetId;
      const config: AxiosRequestConfig = {
        onUploadProgress: progressEvent => {
          setUploadProgress(Math.ceil((progressEvent.progress || 0) * 100));
        },
      };
      return axios
        .post(url, formData, config)
        .then(_ => {
          setUploadProgress(100);
          if (uploadType === 'db') {
            apiServer
              .updateAssestState(assetId, {status: 'confirm'})
              .then(() => {
                successfullyUploadCallback &&
                  successfullyUploadCallback(objectKey);
              });
          } else {
            successfullyUploadCallback && successfullyUploadCallback(objectKey);
          }
          return objectKey;
        })
        .catch(_ => {
          if (uploadType === 'db') {
            apiServer.updateAssestState(assetId, {status: 'failure'});
          }
          failUploadCallback && failUploadCallback(objectKey);
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  return {uploadFile, isUploading, uploadProgress, assetIdRef};
}

export function useToggleWelcomeModal(): [boolean, () => void] {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevIsModalOpen: boolean) => {
      if (!prevIsModalOpen) {
        return prevIsModalOpen;
      }
      const asideSiblingElement =
        document.getElementById('mootion-aside')?.nextElementSibling;
      if (!prevIsModalOpen) {
        asideSiblingElement?.classList.add('hidden_scroll');
      } else {
        asideSiblingElement?.classList.remove('hidden_scroll');
      }
      return !prevIsModalOpen;
    });
  }, []);
  return [isModalOpen, toggleModal];
}

type constructFormDataResponseType = {
  formData: FormData;
  url: string;
  assetId: string;
  objectKey: string;
};
export type uploadFormTargetType =
  | 'remove_image_bg'
  | 'scene_image'
  | 'pose_scene_ref';
type constructFormDataFunctionType = (
  file: Record<string, File>,
  uploadType: UploadTypeEnum,
  target?: uploadFormTargetType,
  content_type?: Record<string, PoseImageType>,
  options?: {uploader?: 'system' | 'user'}
) => Promise<
  constructFormDataResponseType | constructFormDataResponseType[] | undefined
>;
//构建formData表单对象
export const constructFormData: constructFormDataFunctionType = async (
  fileMap,
  uploadType,
  target,
  content_type,
  options
) => {
  if (uploadType === 'db') {
    const file = fileMap.file;
    if (
      file.type === '' ||
      !['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(
        file.type
      )
    ) {
      throw new Error('文件类型不支持');
    }
    const res = await apiServer.createAssest({
      fileName:
        file.name.substr(0, file.name.lastIndexOf('.')).substring(0, 90) +
        file.name.substring(file.name.lastIndexOf('.')),
      fileSizeKb: Math.ceil(file.size / 1024),
      contentType: file.type,
      transferred: options?.uploader === 'system' ? true : undefined,
    });

    const {
      data: {signedUrl: {fields = null, url = ''} = {}, assetId = ''},
    } = res;
    if (!fields) {
      throw new Error('获取上传文件签名失败');
    }
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('Content-Type', file.type);
    formData.append('file', file);
    return {formData, url, assetId: assetId, objectKey: fields['key']};
  } else {
    if (target && ['remove_image_bg', 'scene_image'].includes(target)) {
      const data = await apiServerAi.getUploadForm(fileMap.file.type, target);
      return constructFormDataItem(data, fileMap.file);
    } else if (target && ['pose_scene_ref'].includes(target)) {
      if (!content_type) {
        throw new Error('content_type不能为空');
      }
      const someImageUploadFormData = await apiServerAi.getSomeUploadForm(
        content_type,
        target
      );
      return Object.keys(someImageUploadFormData).map(key => {
        return constructFormDataItem(
          someImageUploadFormData[key],
          fileMap[key]
        );
      });
    } else {
      throw new Error('target类型不支持');
    }
  }
};
export function constructFormDataItem(
  data: apiServerAi.GetImageListResponse,
  file: File
) {
  const formData = new FormData();
  Object.entries(data.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append('key', data.object_id);
  formData.append('Content-Type', file.type);
  formData.append('file', file);
  return {
    formData,
    url: data.url,
    assetId: data.object_id,
    objectKey: data.object_id,
  };
}
export function useModalStatus(): [boolean, () => void, () => void] {
  const [visible, setVisible] = useState(false);
  const showModal = useCallback(() => {
    setVisible(true);
  }, []);
  const hideModal = useCallback(() => {
    setVisible(false);
  }, []);
  return [visible, showModal, hideModal];
}
type UseRemoveImageBgReturnType = {
  removeImageBg: (
    file: File,
    afterGetRemoveImgFun?: (callback: () => void) => void,
    onFailFun?: () => void
  ) => void;
  removeImageBgFlag: removeStageEnum;
  handleReset: () => void;
  removedBgImageUrl: string;
  removingRef: MutableRefObject<boolean | null>;
};
type removeStageEnum =
  | 'init'
  | 'removing'
  | 'removed'
  | 'removedAndCropedReady';
export function useRemoveImageBg(): UseRemoveImageBgReturnType {
  const [removeImageBgFlag, setRemoveStage] = useState<removeStageEnum>('init');
  const [removedBgImageUrl, setRemovedBgImageUrl] = useState<string>('');
  const removingRef = useRef<boolean>(false);

  const removeImageBg: UseRemoveImageBgReturnType['removeImageBg'] =
    useCallback(async (file, afterGetRemoveImgFun, onFailFun) => {
      const startTime = new Date().getTime();
      removingRef.current = true;
      setRemoveStage('removing');

      if (!file) return;

      const res = (await constructFormData(
        {file},
        'cdn-st',
        'remove_image_bg'
      )) as constructFormDataResponseType;
      const {formData, url, assetId} = res;
      const imgId = await axios
        .post(url, formData)
        .then(async _ => {
          return apiServerAi.removeImageBgPostTask(assetId);
        })
        .catch(_ => {
          setRemoveStage('init');
          onFailFun && onFailFun();
        });
      const maxAttemptTimes = 1000 * 60 * 5; //5分钟

      checkStatus();
      function checkStatus() {
        if (new Date().getTime() - startTime > maxAttemptTimes) {
          setRemoveStage('init');
          onFailFun && onFailFun();
          return;
        }
        if (!removingRef.current) {
          return;
        }
        if (!imgId) {
          return;
        }
        apiServerAi
          .getImageById(imgId)
          .then((data: apiServerAi.GetImageByIdTypeResponse) => {
            if (data.status === 'success') {
              fetch(staticCombiner(data.asset))
                .then(response => response.blob())
                .then(blob => {
                  // 创建FileReader对象
                  const reader = new FileReader();

                  // 定义读取完成后的操作
                  reader.onload = function () {
                    if (!removingRef.current) {
                      return;
                    }
                    // 这里的e.target.result就是转换后的DataURL
                    setRemovedBgImageUrl(reader.result as string);
                    setRemoveStage('removed');
                    afterGetRemoveImgFun &&
                      afterGetRemoveImgFun(() => {
                        setRemoveStage('removedAndCropedReady');
                      });
                  };

                  // 读取Blob为DataURL
                  reader.readAsDataURL(blob);
                });
            } else if (data.status === 'failure') {
              setRemoveStage('init');
              onFailFun && onFailFun();
            } else {
              setTimeout(checkStatus, 2000);
            }
          });
      }
    }, []);
  const handleReset = useCallback(() => {
    setRemoveStage('init');
  }, []);
  return {
    removeImageBg,
    removeImageBgFlag,
    handleReset,
    removedBgImageUrl,
    removingRef,
  };
}
