// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './LoadingPrompt.module.scss';
import {PromptType, PromptValue, Props} from './LoadingPrompt.types';

export function LoadingPrompt({type}: Props) {
  const {t} = useTranslation();

  const PROMPT_MAP: Record<PromptType, PromptValue> = useMemo(
    () => ({
      generating: {
        status: t('Generating'),
        text: t('Please wait for a few seconds.'),
      },
      processing: {
        status: t('In processing'),
        text: t('Please wait for a few seconds.'),
      },
    }),
    [t]
  );

  const prompt = useMemo(() => PROMPT_MAP[type], [PROMPT_MAP, type]);
  return (
    <div className={styles.container}>
      <p className={styles.status}>{prompt.status}...</p>
      <p className={styles.text}>{prompt.text}</p>
    </div>
  );
}
