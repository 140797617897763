// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useImage} from 'modules/user-asset/services';
import {MouseEvent as RectMouseEvent, useRef, useState} from 'react';
import {useClickOutside} from 'utils/use-click-outside';

import {ImageLoading} from '../../ImageLoading';
import {ImageBar} from '../ImageBar';
import {ImageBarActionTypes} from '../ImageBar/ImageBar.type';
import styles from './ObjectEditor.module.scss';
import {ObjectEditorProps} from './ObjectEditor.type';

const MIN_SIZE = 40;

export function ObjectEditor({
  object,
  active,
  onClick,
  onClickOutSide,
  onAction: _onAction,
  updateObject,
  getImageWithObjectKey,
  refreshImage,
  outerWidth,
  outerHeight,
  disabled,
  forceLoading,
  outerEl,
}: ObjectEditorProps) {
  const root = useRef<HTMLDivElement>(null);
  const {src, loading, onError} = useImage({
    objectKey: object.asset!,
    getImageWithObjectKey,
    refreshImage,
  });
  const [position, setPosition] = useState({x: 0, y: 0});

  const onAction = (type: ImageBarActionTypes) => {
    if (type === ImageBarActionTypes.delete) return updateObject(null, false);
    _onAction(type, object);
  };

  const isMouseEvent = (e: MouseEvent | TouchEvent): e is MouseEvent =>
    e.type === 'mousemove' || e.type === 'mouseup';

  const startDrag = (e: RectMouseEvent) => {
    if (e.button !== 0) return;
    onClick();
    const startX = e.clientX;
    const startY = e.clientY;
    const availableTop = (-object.y - object.height) * outerHeight + MIN_SIZE;
    const availableLeft = (-object.x - object.width) * outerWidth + MIN_SIZE;
    const availableRight = outerWidth - object.x * outerWidth - MIN_SIZE;
    const availableBottom = outerHeight - object.y * outerHeight - MIN_SIZE;

    const getAvailablePosition = (x: number, y: number) => {
      if (x < availableLeft) {
        x = availableLeft;
      }
      if (y < availableTop) {
        y = availableTop;
      }
      if (x > availableRight) {
        x = availableRight;
      }
      if (y > availableBottom) {
        y = availableBottom;
      }
      return {x, y};
    };

    const onDrag = (e: MouseEvent | TouchEvent) => {
      if (isMouseEvent(e)) {
        setPosition(
          getAvailablePosition(e.clientX - startX, e.clientY - startY)
        );
      } else {
        e.preventDefault();
        const touch = e.touches[0];
        setPosition(
          getAvailablePosition(touch.clientX - startX, touch.clientY - startY)
        );
      }
    };
    const endDrag = (e: MouseEvent | TouchEvent) => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchend', endDrag);

      const target = isMouseEvent(e) ? e : e.changedTouches[0];

      const obj = getAvailablePosition(
        target.clientX - startX,
        target.clientY - startY
      );
      setPosition({x: 0, y: 0});
      updateObject(
        object.patch({
          x: object.x + obj.x / outerWidth,
          y: object.y + obj.y / outerHeight,
        })
      );
    };
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('touchmove', onDrag, {passive: false});
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('touchend', endDrag);
  };

  const [resizing, setResizing] = useState(false);

  const startResize = (
    e: RectMouseEvent,
    handlerName: 'tl' | 'tr' | 'bl' | 'br'
  ) => {
    onClick();
    setResizing(true);
    e.stopPropagation();

    const startX = e.clientX;
    const startY = e.clientY;

    const onResize = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      const ratio = (object.width * outerWidth) / (object.height * outerHeight);
      let x = (isMouseEvent(e) ? e.clientX : e.touches[0].clientX) - startX;
      let y = (isMouseEvent(e) ? e.clientY : e.touches[0].clientY) - startY;
      let height = Math.max(object.height * outerHeight - y, 1);
      let width = height * ratio;
      let newX = object.x;
      let newY = object.y;

      if (handlerName === 'tl') {
        if (
          y >
          Math.min(
            outerHeight - MIN_SIZE - object.y * outerHeight,
            object.height * outerHeight - MIN_SIZE
          )
        ) {
          y = Math.min(
            outerHeight - MIN_SIZE - object.y * outerHeight,
            object.height * outerHeight - MIN_SIZE
          );
        }
        height = object.height * outerHeight - y;
        width = height * ratio;
        x = object.width * outerWidth - width;
        if (
          x >
          Math.min(
            object.width * outerWidth - MIN_SIZE,
            outerWidth - MIN_SIZE - object.x * outerWidth
          )
        ) {
          x = Math.min(
            object.width * outerWidth - MIN_SIZE,
            outerWidth - MIN_SIZE - object.x * outerWidth
          );
          width = object.width * outerWidth - x;
          height = width / ratio;
          newX = object.x + x / outerWidth;
          newY = object.y + object.height - height / outerHeight;
        } else {
          newX = object.x + x / outerWidth;
          newY = object.y + y / outerHeight;
        }
      } else if (handlerName === 'tr') {
        if (
          y >
          Math.min(
            outerHeight - MIN_SIZE - object.y * outerHeight,
            object.height * outerHeight - MIN_SIZE
          )
        ) {
          y = Math.min(
            outerHeight - MIN_SIZE - object.y * outerHeight,
            object.height * outerHeight - MIN_SIZE
          );
        }
        height = object.height * outerHeight - y;
        width = height * ratio;
        x = width - object.width * outerWidth;
        if (
          x <
          Math.max(
            MIN_SIZE - object.width * outerWidth,
            MIN_SIZE - (object.x + object.width) * outerWidth
          )
        ) {
          x = Math.max(
            MIN_SIZE - object.width * outerWidth,
            MIN_SIZE - (object.x + object.width) * outerWidth
          );
          width = object.width * outerWidth + x;
          height = width / ratio;
          newY = object.y + object.height - height / outerHeight;
        } else {
          newY = object.y + y / outerHeight;
        }
      } else if (handlerName === 'bl') {
        if (
          y <
          Math.max(
            MIN_SIZE - object.height * outerHeight,
            MIN_SIZE - (object.y + object.height) * outerHeight
          )
        ) {
          y = Math.max(
            MIN_SIZE - object.height * outerHeight,
            MIN_SIZE - (object.y + object.height) * outerHeight
          );
        }
        height = object.height * outerHeight + y;
        width = height * ratio;
        x = object.width * outerWidth - width;
        if (
          x >
          Math.min(
            object.width * outerWidth - MIN_SIZE,
            outerWidth - MIN_SIZE - object.x * outerWidth
          )
        ) {
          x = Math.min(
            object.width * outerWidth - MIN_SIZE,
            outerWidth - MIN_SIZE - object.x * outerWidth
          );
          width = object.width * outerWidth - x;
          height = width / ratio;
          newX = object.x + x / outerWidth;
        } else {
          newX = object.x + x / outerWidth;
        }
      } else if (handlerName === 'br') {
        if (
          y <
          Math.max(
            MIN_SIZE - object.height * outerHeight,
            MIN_SIZE - (object.y + object.height) * outerHeight
          )
        ) {
          y = Math.max(
            MIN_SIZE - object.height * outerHeight,
            MIN_SIZE - (object.y + object.height) * outerHeight
          );
        }
        height = object.height * outerHeight + y;
        width = height * ratio;
        x = width - object.width * outerWidth;
        if (
          x <
          Math.max(
            MIN_SIZE - object.width * outerWidth,
            MIN_SIZE - (object.x + object.width) * outerWidth
          )
        ) {
          x = Math.max(
            MIN_SIZE - object.width * outerWidth,
            MIN_SIZE - (object.x + object.width) * outerWidth
          );
          width = object.width * outerWidth + x;
          height = width / ratio;
        }
      }

      updateObject(
        object.patch({
          x: newX,
          y: newY,
          width: width / outerWidth,
          height: height / outerHeight,
        }),
        true
      );
    };
    const endResize = () => {
      setResizing(false);
      document.removeEventListener('mousemove', onResize);
      document.removeEventListener('touchmove', onResize);
      document.removeEventListener('mouseup', endResize);
      document.removeEventListener('touchend', endResize);
    };
    document.addEventListener('mousemove', onResize);
    document.addEventListener('touchmove', onResize);
    document.addEventListener('mouseup', endResize);
    document.addEventListener('touchend', endResize);
  };

  useClickOutside(root, onClickOutSide, active);

  const number2Percent = (num: number) => `${num * 100}%`;
  return (
    <>
      {resizing && <div className={styles['object-editor-mask']}></div>}

      <div
        className={classNames(styles['object-editor'], {
          [styles.active]: active,
          [styles.disabled]: disabled,
        })}
        ref={root}
        style={{
          position: 'absolute',
          top: number2Percent(object.y),
          left: number2Percent(object.x),
          width: number2Percent(object.width),
          height: number2Percent(object.height),
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        onPointerDown={!disabled ? startDrag : undefined}
        onContextMenu={e => e.preventDefault()}
        onClick={onClick}
      >
        <Popover
          outerEl={outerEl}
          distance={10}
          triggerElement={
            <div
              className={classNames(styles.imageResizer, {
                [styles.active]: active,
              })}
              style={{
                width: object.width * outerWidth,
                height: object.height * outerHeight,
              }}
            >
              {loading || forceLoading ? (
                <ImageLoading size={30} />
              ) : (
                <>
                  <span
                    className={classNames(styles.handler, styles.tl)}
                    onPointerDown={e => !disabled && startResize(e, 'tl')}
                  ></span>
                  <span
                    className={classNames(styles.handler, styles.tr)}
                    onPointerDown={e => !disabled && startResize(e, 'tr')}
                  ></span>
                  <span
                    className={classNames(styles.handler, styles.bl)}
                    onPointerDown={e => !disabled && startResize(e, 'bl')}
                  ></span>
                  <span
                    className={classNames(styles.handler, styles.br)}
                    onPointerDown={e => !disabled && startResize(e, 'br')}
                  ></span>
                </>
              )}
            </div>
          }
          content={
            active &&
            !loading &&
            !forceLoading && <ImageBar onAction={onAction} />
          }
          direction="top"
          visible={active}
        ></Popover>
      </div>

      {src && (
        <img
          crossOrigin="anonymous"
          src={src}
          style={{
            position: 'absolute',
            top: number2Percent(object.y),
            left: number2Percent(object.x),
            width: number2Percent(object.width),
            height: number2Percent(object.height),
            transform: `translate(${position.x}px, ${position.y}px)`,
          }}
          onError={onError}
        />
      )}
    </>
  );
}
