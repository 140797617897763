// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import classNames from 'classnames';
import {Button} from 'pages/components/Button';
import {GlassInputBox} from 'pages/components/GlassInputBox/GlassInputBox';
import {useTranslation} from 'react-i18next';

import {EmailErrorMessage} from './AuthForm.module';
import styles from './AuthForm.module.scss';
import {AuthFormResetPasswordProps} from './AuthForm.types';

export function AuthFormResetPassword({
  emailErrorType,
  onConfirm,
  email,
  setEmail,
  validEmail,
}: AuthFormResetPasswordProps) {
  const {t} = useTranslation('auth');
  return (
    <div className={styles.auth_form_container}>
      <GlassInputBox
        className={classNames(styles.password_input, styles.input)}
        placeholder={t('Enter your email')}
        value={email}
        onChange={e => setEmail(e.target.value)}
        onBlur={() => {
          validEmail();
        }}
      />
      {emailErrorType !== null && (
        <EmailErrorMessage
          errorType={emailErrorType}
          className={styles.error_message}
        />
      )}
      <div className={classNames(styles.resetPasswordBox)}>
        <Button
          type="button"
          size="large"
          onClick={onConfirm}
          disabled={emailErrorType !== null}
        >
          {t('Reset password')}
        </Button>
      </div>
    </div>
  );
}
