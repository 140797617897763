// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useResourceManager} from 'contexts/ResourceManager';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {CommonSelectWithoutLabel} from './CommonSelectWithoutLabel/CommonSelectWithoutLabel';

export interface Props {
  value: string;
  onChange: (value: string) => void;
  languageCode: string;
}
export function NativeLanguageSelect({value, onChange, languageCode}: Props) {
  const {getNativeLanguages} = useResourceManager();
  const {t} = useTranslation('project');
  const {t: resourceBuilderT} = useTranslation('resourceBuilder');

  const options = useMemo(() => {
    return getNativeLanguages(languageCode).map(language => ({
      label: language.native_name,
      value: language.code,
      tooltip: language.tooltip
        ? resourceBuilderT(`tooltip_${language.tooltip}`)
        : undefined,
    }));
  }, [getNativeLanguages, languageCode, resourceBuilderT]);

  return (
    <CommonSelectWithoutLabel
      options={options}
      value={value}
      title={t('Native language')}
      onChange={value => onChange(value as string)}
    />
  );
}
