// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useTranslation} from 'react-i18next';

import {Toast} from '../Toast';
import styles from './ContentViolationDialog.module.scss';
import {Props} from './ContentViolationDialog.types';

export function ContentViolationDialog({dialogShowing, onClose}: Props) {
  const {t} = useTranslation('project');
  return (
    <Toast
      visible={dialogShowing}
      title={t('Content violation')}
      showCancel={false}
      confirmText="OK"
      onOk={onClose}
      onCancel={onClose}
    >
      <p className={styles.paragraph}>
        {t(
          'It seems your input doesn’t align with our content policy. Please review and revise to ensure it doesn’t include any inappropriate content, such as violence, threats, or illegal activities.'
        )}
      </p>
    </Toast>
  );
}
