// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {Button} from 'pages/components/Button';
import {useTranslation} from 'react-i18next';

import {RemoveBgPanelProps} from '../AssetEditor.types';
import styles from './Panel.module.scss';

const fillColors = [
  '',
  '#fff',
  '#000',
  '#D03459',
  '#E85A2D',
  '#F2BD36',
  '#F9E951',
  '#C7D74B',
  '#8ABA4F',
  '#5BA350',
  '#3A887D',
  '#4DB0CB',
  '#3E46A6',
  '#862BA1',
];
export function RemoveBgPanel({
  handleChangeFillBgColor,
  fillColor,
  handleRemoveBg,
}: RemoveBgPanelProps) {
  const onChangeBgFillColor = (e: React.MouseEvent<HTMLDivElement>) => {
    const color = (e.currentTarget as HTMLDivElement)?.dataset.color;
    handleChangeFillBgColor && handleChangeFillBgColor(color || '');
  };

  const {t} = useTranslation('workspace');

  return (
    <div className={styles['action-wrap']}>
      <span className={styles['action-wrap-title']}>
        {t('Remove Background')}
      </span>
      <span className={styles['action-wrap-subTitle']}>
        {t('Fill Background')}
      </span>
      <div className={styles['action-wrap-items-color']}>
        {fillColors.map(item => {
          const style = {'--bgColor': item} as React.CSSProperties;
          return (
            <div
              key={item}
              data-color={item}
              style={style}
              onClick={onChangeBgFillColor}
              className={classNames(styles['action-wrap-item-color'], {
                [styles['action-wrap-item-color-active']]: item === fillColor,
                [styles['action-wrap-item-color-transparent']]: item === '',
              })}
            />
          );
        })}
      </div>

      <Button
        className={styles['action-wrap-btn']}
        theme="primary"
        type="button"
        onClick={() => handleRemoveBg()}
      >
        {t('Remove')}
      </Button>
    </div>
  );
}
