// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as ErrorIcon} from 'assets/svg/3.0/Error.svg';
import {ReactComponent as SuccessIcon} from 'assets/svg/3.0/Success.svg';
import classNames from 'classnames';
import {Button} from 'components/Button';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './PromoInput.module.scss';
import {Props} from './PromoInput.types';

export function PromoInput({
  promoInfo,
  isDisabled,
  onClearPromoInfo,
  onCheck,
}: Props) {
  const {t} = useTranslation('plan');

  const [promoCode, setPromoCode] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isEmpty = promoCode.length === 0 && !isFocus;

  const onChange = useCallback(
    (code: string) => {
      onClearPromoInfo();
      setPromoCode(code.replaceAll(/[^0-9a-zA-Z]/g, ''));
    },
    [onClearPromoInfo]
  );

  const onApply = useCallback(async () => {
    setIsLoading(true);
    await onCheck(promoCode);
    setIsLoading(false);
  }, [onCheck, promoCode]);

  return (
    <div
      className={classNames(
        styles.container,
        isEmpty && styles.empty,
        isFocus && styles.focus
      )}
    >
      {isEmpty && (
        <span className={styles.text} onClick={() => setIsFocus(true)}>
          {t('Enter promo code')}
        </span>
      )}
      {!isEmpty && (
        <>
          <input
            className={classNames(
              styles.input,
              promoInfo === null && styles.error
            )}
            autoFocus
            maxLength={15}
            value={promoCode}
            onChange={e => onChange(e.currentTarget.value.toUpperCase())}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            disabled={isLoading || isDisabled}
          />
          {(promoInfo === undefined || isLoading) && (
            <Button
              className={classNames(styles.apply, isLoading && styles.loading)}
              disabled={promoCode.length === 0 || isLoading || isDisabled}
              onClick={onApply}
              type="text"
              isLoading={isLoading}
            >
              {t('Apply')}
            </Button>
          )}
          {promoInfo instanceof Object && (
            <p className={classNames(styles.tips, styles.success)}>
              <SuccessIcon className={styles.icon} />
              {t('OFF', {off: promoInfo.discount})}
            </p>
          )}
          {promoInfo === null && (
            <p className={classNames(styles.tips, styles.error)}>
              <ErrorIcon className={styles.icon} />
              {t('Invalid code')}
            </p>
          )}
        </>
      )}
    </div>
  );
}
