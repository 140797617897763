// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import SuccessIcon from 'assets/svg/fill/Complete.svg';
import WarningIcon from 'assets/svg/fill/Warning.svg';
import classNames from 'classnames';
import {sleep} from 'lib/sleep';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './NotificationBubble.module.scss';
import {Props} from './NotificationBubble.types';

export function NotificationBubble({
  notification,
  onRemove,
  displayingDuration,
  dismissingDuration,
}: Props) {
  const [dismissing, setDismissing] = useState(false);

  const {t} = useTranslation('common');

  const dismiss = useCallback(async () => {
    setDismissing(true);
    await sleep(dismissingDuration);
    onRemove(notification);
  }, [dismissingDuration, notification, onRemove]);

  useEffect(() => {
    const timeout = setTimeout(dismiss, displayingDuration);
    return () => clearTimeout(timeout);
  }, [dismiss, displayingDuration]);

  const dismissingDurationInSecond = dismissingDuration / 1000;

  return (
    <li
      className={classNames(styles.container, 'label-s', 'flex-center')}
      style={{
        top: `${notification.index * 50}px`,
        ...(dismissing
          ? {
              transition: `transform linear ${dismissingDurationInSecond}s, opacity linear ${dismissingDurationInSecond}s`,
              transform: 'translate(-50%, -100px)',
              opacity: 0,
            }
          : {}),
      }}
    >
      <img
        src={notification.type === 'SUCCESS' ? SuccessIcon : WarningIcon}
        className={styles.icon}
      />
      <span> {t(notification.message, notification.messageParams)}</span>
    </li>
  );
}
