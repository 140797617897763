// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LanguageIcon} from 'assets/svg/3.0/Language.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useAPI} from 'contexts/APIContext';
import {setLanguage} from 'contexts/localStorage';
import {SUPPORTED_LANGUAGES} from 'i18n';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './LanguageSelect.module.scss';

export function LanguageSelect() {
  const {t, i18n} = useTranslation();
  const [visible, setVisible] = useState(false);

  const {backendClient} = useAPI();

  const selectLanguage = useCallback(
    (languageCode: string) => {
      backendClient.updateLanguage(languageCode);
      setLanguage(languageCode);
      i18n.changeLanguage(languageCode);
      setVisible(false);
    },
    [backendClient, i18n]
  );

  const content = (
    <div className={styles.select}>
      {SUPPORTED_LANGUAGES.map(({code, name}) => (
        <div
          key={code}
          className={styles['select-item']}
          onMouseDown={e => {
            e.stopPropagation();
            selectLanguage(code);
          }}
          onClick={e => e.stopPropagation()}
        >
          {name}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className={styles.container}>
        <div
          className={classNames(styles.language, {
            [styles.active]: visible,
          })}
          onClick={() => setVisible(true)}
        >
          <LanguageIcon className={styles.icon} />
          <span className={styles.label}>{t('Language')}</span>
        </div>
        {visible && content}
      </div>

      <div className={styles.trigger}>
        <Popover
          trigger="click"
          triggerElement={<LanguageIcon stroke="#56565C" />}
          onVisibleChange={setVisible}
          visible={visible}
          direction="bottom"
          popoverClassName={styles.popover}
          distance={16}
          content={content}
        />
      </div>
    </>
  );
}
