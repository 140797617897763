// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DownArrowIcon} from 'assets/svg//outline/Down.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

import {
  getRenderRadiosType,
  SceneSettingKeys,
  SceneSettingMapType,
  SceneSettingProps,
  SceneTypeEnum,
} from './Generator.types';

const SceneSettingMap: SceneSettingMapType = {
  angle: {
    label: 'Camera preset',
    options: [
      {id: 'front', label: 'Front'},
      {id: 'left', label: 'Left'},
      {id: 'right', label: 'Right'},
      {id: 'top', label: 'Top'},
      {id: 'bottom', label: 'Bottom'},
    ],
  },
  bodyShape: {
    label: 'Body shape',
    options: [
      {id: 'male', label: 'Male'},
      {id: 'Female', label: 'Female'},
      {id: 'Child', label: 'Child'},
    ],
  },
};
const getLabelByTypeAndId = (
  type: SceneTypeEnum,
  id: string | undefined,
  t: TFunction
) => {
  if (!id) {
    return '-';
  }
  const label = SceneSettingMap[type].options.find(
    item => item.id === id
  )?.label;
  if (label) {
    return t(label);
  }
  return '-';
};
const getRenderRadios: getRenderRadiosType = (
  type,
  onChangeRadio,
  currentValue,
  styles,
  t
) => {
  if (SceneSettingMap[type]) {
    return SceneSettingMap[type].options.map(item => {
      return (
        <div
          key={item.id}
          className={classNames(styles.radioItem, {
            [styles.selected]: currentValue === item.id,
          })}
        >
          <input
            type="radio"
            name={type}
            value={item.id}
            id={item.id}
            onChange={() => onChangeRadio(type, item.id)}
            checked={currentValue === item.id}
          />
          <label htmlFor={item.id} className={styles.label}>
            {t(item.label)}
          </label>
        </div>
      );
    });
  }
  return null;
};

export function SceneSetting({
  styles,
  promptBodyRef,
  popoverVisibleMap,
  updatePopoverVisibleMap,
  settingInfo,
  onChangeSceneSetting,
}: SceneSettingProps) {
  const {t} = useTranslation('project');

  return (
    <div className={styles.sceneSetting}>
      {(['angle', 'bodyShape'] as SceneSettingKeys[]).map(type => {
        return (
          <Popover
            style={{zIndex: 1000}}
            key={type}
            direction="top"
            distance={-7}
            popupMatchSelectWidth
            scrollElement={promptBodyRef.current as HTMLElement}
            visible={popoverVisibleMap[type]}
            onVisibleChange={visible => updatePopoverVisibleMap(type, visible)}
            triggerElement={
              <div className={classNames(styles.selectWrap, 'selectWrap')}>
                <span className={styles.selectWrapLabel}>
                  {t(SceneSettingMap[type].label)}
                </span>
                <div className={styles.selectValueWrap}>
                  <span className={styles.selectValue}>
                    {getLabelByTypeAndId(
                      type as SceneTypeEnum,
                      settingInfo[type],
                      t
                    )}
                  </span>
                  <DownArrowIcon className={styles.selectValueIcon} />
                </div>
              </div>
            }
            content={
              <div className={styles.radioContent}>
                {getRenderRadios(
                  type as SceneTypeEnum,
                  onChangeSceneSetting,
                  settingInfo[type],
                  styles,
                  t
                )}
              </div>
            }
          ></Popover>
        );
      })}
    </div>
  );
}
