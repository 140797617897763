// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import * as server from 'api/server';
import {ReactComponent as AnimateIcon} from 'assets/svg/3.0/Animate.svg';
import {ReactComponent as AssetIcon} from 'assets/svg/3.0/Asset.svg';
import {ReactComponent as CoinIcon} from 'assets/svg/3.0/Coin.svg';
import {ReactComponent as DownloadIcon} from 'assets/svg/3.0/Download.svg';
import {ReactComponent as RegenerateIcon} from 'assets/svg/3.0/Regenerate.svg';
import {ReactComponent as LoadingSvg} from 'assets/svg/outline/LoadingLine.svg';
import axios from 'axios';
import classNames from 'classnames';
import {AnimateInfo, AnimateSelect} from 'components/AnimateSelect';
import {AssetActionType, AssetEditor} from 'components/AssetEditor';
import {
  useAssetsList,
  useUploadAssetHook,
} from 'components/AssetsLibrary/AssetsLibrary.container';
import {LoadingPromptDialog} from 'components/LoadingPrompt';
import {PreviewBox} from 'components/PreviewBox';
import {GeneratorDialog} from 'components/Regenerator';
import {UseAssetModal} from 'components/UseAssetModal';
import {useUserContext} from 'contexts/UserContext';
import {TFunction} from 'i18next';
import {download} from 'lib/download';
import {catchAsync} from 'lib/exception';
import {useVisible} from 'lib/hooks';
import {
  blobUrlToFile,
  cloneBlobUrl,
  getImageSizeByUrl,
  getTransferredSize,
  handleDownload,
} from 'lib/image';
import {formatAspectRatio} from 'lib/ratio';
import {noop} from 'lodash';
import {Draft} from 'modules/draft/models/Draft';
import {ImageObject, SceneObject} from 'modules/draft/models/SceneObject';
import {DEFAULT_DRAFT_BACKGROUND_COLOR} from 'modules/draft/utils';
import {ProjectType} from 'modules/project/types';
import {AnimateOptionTypeEnums} from 'modules/scene/utils';
import {ANIMATE_ADVANCE_PRICE, ANIMATE_PRICE} from 'modules/task/utils';
import {useUserAsset} from 'modules/user-asset/services';
import {checkValidAsset} from 'modules/user-asset/utils';
import {Modal} from 'pages/components/Modal';
import {Toast} from 'pages/components/Toast';
import {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {AnimateBtn} from './AnimateBtn';
import styles from './SceneEditor.module.scss';
import {SceneEditorPlaceholder} from './SceneEditor.placeholder';
import {ErrorType, Props, ToastProps} from './SceneEditor.types';

export function SceneEditor({
  title,
  size,
  scene,
  projectId,
  isProcessing,
  selectedSceneIndex,
  animating,
  changeLoading,
  saveScene,
  updateScene,
  executeTaskInEditor,
  onGenerateAnimationOfCurrentScene,
  setAnimateIsDisabled,
}: Props<ProjectType>) {
  const [toastVisible, hideToast, showToast, toastType] =
    useVisible<ErrorType>();
  const {
    userInfo: {plan},
  } = useUserContext();
  const [appending, setAppending] = useState<Record<string, boolean>>({});

  const [animatePrice, setAnimatePrice] = useState(0);
  const [forceLoading, setForceLoading] = useState<
    Record<string, number | null>
  >({});
  const [isUploading, setIsUploading] = useState(false);
  const [downloading, setDownloading] = useState<Record<string, boolean>>({});
  const [useAssetsModalVisible, hideUseAssetsModal, showUseAssetsModal] =
    useVisible(false);
  const [
    useAssetsLimitModalVisible,
    hideUseAssetsLimitModal,
    showUseAssetsLimitModal,
  ] = useVisible(false);
  const [assetEditorInfo, setAssetEditorInfo] = useState<{
    type: AssetActionType;
    url: string;
    width: number;
    height: number;
    object: SceneObject;
    index: number;
  } | null>(null);

  const assetEditorInfoRef = useRef(assetEditorInfo);

  const transferImageRef = useRef<{s3Key: string; blobImageUrl: string} | null>(
    null
  );
  const {refreshAssetList} = useAssetsList();

  const getImage = useCallback(async (objectKey: string) => {
    const res = await server.getSignedUrl([{fileName: objectKey}]);
    const src = res.data[0];
    if (!src) throw new Error(`Failed to get ${objectKey} signed url`);
    const {width, height} = await getImageSizeByUrl(src);
    return {src, width, height};
  }, []);

  const {getImageWithObjectKey, refreshImage} = useUserAsset({
    getImage,
  });

  const {handleUpload, errorToastVisible, hideErrorToast, errorToastType} =
    useUploadAssetHook({
      uploadCallback: refreshAssetList,
    });

  const [isPromptDialogVisible, setIsPromptDialogVisible] = useState(false);
  const preview = scene && scene.image;
  const previewVideo = scene?.video ?? '';
  const handleClickAnimateBtn = async () => {
    const isDisabled = isProcessing || animating || !preview,
      animatingProgress = animatingTask && (animatingTask.progress || 0);
    if (isDisabled || animatingProgress !== undefined) return;
    setAnimatePrice(
      scene.animatePrompt === undefined ? ANIMATE_PRICE : ANIMATE_ADVANCE_PRICE
    );
    showToast('Animate');
  };
  const ratio = useMemo(() => formatAspectRatio(size), [size]);

  const animatingTask = useMemo(() => {
    return scene?.task &&
      !scene.task.isEndedStatus &&
      scene.task.type === 'image_conditioning_video'
      ? scene.task
      : undefined;
  }, [scene?.task]);
  const root = useRef<HTMLDivElement>(null);

  const updateObject = useCallback(
    (
      draftObjectIndex: number,
      object: ImageObject | null,
      immediately = false
    ) => {
      if (!scene?.draft) return;
      const objects =
        scene.draft.objects.reduce(
          (objects: ImageObject[], o: ImageObject, i) =>
            i === draftObjectIndex
              ? object
                ? objects.concat([object])
                : objects
              : objects.concat([o]),
          []
        ) ?? [];
      updateScene(
        scene.id,
        scene.patch({
          draft:
            objects.length > 0
              ? scene.draft.patch({
                  image: undefined,
                  objects,
                })
              : undefined,
        }),
        immediately
      );
    },
    [scene, updateScene]
  );

  const handleAssetEditorApply = useCallback(
    async ({
      file,
      blobImageUrl,
      needUpload,
    }: {
      file: File;
      blobImageUrl: string;
      needUpload: boolean;
    }) => {
      setAssetEditorInfo(null);
      assetEditorInfoRef.current = null;
      if (
        !assetEditorInfo ||
        (!needUpload && !transferImageRef.current) ||
        !scene
      )
        return;
      const sceneId = scene.id;
      setForceLoading(prev => ({...prev, [sceneId]: assetEditorInfo.index}));
      setAnimateIsDisabled(true);
      const [projectWidth, projectHeight] = size;
      const {url} = await cloneBlobUrl(
        needUpload ? blobImageUrl : transferImageRef.current!.blobImageUrl
      );
      const {width, height} = await getImageSizeByUrl(url);
      const s3Key = needUpload
        ? (await handleUpload({file, blobImageUrl: url}, 'system')).objectKey
        : transferImageRef.current!.s3Key;
      if (s3Key) {
        const ratio = width / height;
        let newW = assetEditorInfo.object.width * projectWidth;
        let newH = assetEditorInfo.object.height * projectHeight;
        if (width > height) {
          newH = newW / ratio;
        } else {
          newW = newH * ratio;
        }
        updateObject(
          assetEditorInfo.index,
          assetEditorInfo.object.patch({
            asset: s3Key,
            width: newW / projectWidth,
            height: newH / projectHeight,
          })
        );
      }
      transferImageRef.current = null;
      setAnimateIsDisabled(false);
      setForceLoading(prev => ({...prev, [sceneId]: null}));
    },
    [
      assetEditorInfo,
      handleUpload,
      scene,
      setAnimateIsDisabled,
      size,
      updateObject,
    ]
  );
  const uploadToAssetLibrary = useCallback(
    async (blobImageUrl: string, fileName: string) => {
      const file = await blobUrlToFile(blobImageUrl, fileName);
      const {objectKey: s3Key} = await handleUpload(
        {file, blobImageUrl},
        'system'
      );
      if (s3Key) {
        transferImageRef.current = {s3Key, blobImageUrl};
      }
    },
    [handleUpload]
  );

  const onObjectsEditorAction = useCallback(
    async (type: AssetActionType, object: SceneObject, index: number) => {
      const {src, width, height} = await getImageWithObjectKey(object.asset!);
      checkValidAsset(src).catch(() => {
        refreshImage(object.asset!).then(({src, width, height}) => {
          if (assetEditorInfoRef.current?.object === object) {
            const info = {
              ...assetEditorInfoRef.current,
              url: src,
              width,
              height,
            };
            setAssetEditorInfo(info);
            assetEditorInfoRef.current = info;
          }
        });
      });
      const info = {
        type,
        url: src,
        width,
        height,
        object,
        index,
      };
      setAssetEditorInfo(info);
      assetEditorInfoRef.current = info;
    },
    [getImageWithObjectKey, refreshImage]
  );

  const appendObjectWithObjectKey = useCallback(
    async (objectKey: string) => {
      if (!scene) return;
      const sceneId = scene.id;
      setAppending(prev => ({...prev, [sceneId]: true}));
      setAnimateIsDisabled(true);
      const [projectWidth, projectHeight] = size;
      const {width, height} = await getImageWithObjectKey(objectKey);
      const {width: w, height: h} = getTransferredSize(
        width,
        height,
        projectWidth,
        projectHeight
      );
      const object: ImageObject = ImageObject.fromJSON({
        type: 'image',
        asset: objectKey,
        x: 0.5 - w / projectWidth / 2,
        y: 0.5 - h / projectHeight / 2,
        height: h / projectHeight,
        width: w / projectWidth,
      });
      updateScene(
        sceneId,
        scene =>
          scene.patch({
            draft: scene.draft
              ? scene.draft.patch({
                  objects: scene.draft.objects.concat([object]),
                  image: undefined,
                })
              : new Draft(scene.image ?? DEFAULT_DRAFT_BACKGROUND_COLOR, [
                  object,
                ]),
          }),
        false
      );
      setAnimateIsDisabled(false);
      setAppending(prev => ({...prev, [sceneId]: false}));
    },
    [getImageWithObjectKey, scene, setAnimateIsDisabled, size, updateScene]
  );

  const onDownload = async () => {
    if (!scene) return;
    const {draft, id, video, image} = scene;
    if (!image && !draft) return;
    const title = t('Scene n', {n: selectedSceneIndex + 1});
    if (video) {
      return download(video, title);
    }
    if (draft) {
      if (draft.image) return download(draft.image, title);

      setDownloading(prev => ({...prev, [id]: true}));
      setAnimateIsDisabled(true);
      const res = await catchAsync(saveScene(id));
      setAnimateIsDisabled(false);
      setDownloading(prev => ({...prev, [id]: false}));

      if (res) {
        return handleDownload(res.url, title);
      }
    }
    if (image) {
      return download(image, title);
    }
  };

  const {t} = useTranslation('project');

  if (!scene) {
    return <SceneEditorPlaceholder />;
  }

  const _onAnimate = async (
    shouldCheckWaitingTime: boolean,
    animateInfo: AnimateInfo
  ) => {
    if (!scene || !scene.image) return;
    //点击OK就要存animatePrompt不用管任务执行成功与否
    updateScene(
      scene.id,
      scene.patch({
        animatePrompt:
          animateInfo.model === AnimateOptionTypeEnums.Base
            ? undefined
            : animateInfo.prompt,
      }),
      false
    );
    onGenerateAnimationOfCurrentScene &&
      (await onGenerateAnimationOfCurrentScene(
        shouldCheckWaitingTime,
        animateInfo
      ));
  };
  const onChangeOptionValue = (value: string) => {
    if (value === 'base') {
      setAnimatePrice(ANIMATE_PRICE);
    } else {
      setAnimatePrice(ANIMATE_ADVANCE_PRICE);
    }
  };
  const toastProps = getToastProps({
    toastType,
    toastVisible,
    plan,
    animatePrice,
    initAnimatePrompt: scene.animatePrompt,
    hideToast,
    onAnimate: _onAnimate,
    showToast,
    onChangeOptionValue,
    t,
  });

  const {draft} = scene || {};

  const onUseAsset = () => {
    if ((scene.draft?.objects.length ?? 0) >= 10) {
      showUseAssetsLimitModal();
      return;
    }
    showUseAssetsModal();
  };

  return (
    <div
      ref={root}
      className={classNames(
        styles.container,
        isProcessing && styles.processing
      )}
    >
      <p className={styles.title}>{title}</p>
      <PreviewBox
        sceneId={scene?.id}
        preview={previewVideo || preview!}
        type={previewVideo ? 'Video' : 'Image'}
        ratio={ratio}
        loading={isProcessing}
        onObjectsEditorAction={onObjectsEditorAction}
        objectsEditorDisabled={
          !!(
            isProcessing ||
            !!animatingTask ||
            downloading[scene.id] ||
            appending[scene.id] ||
            forceLoading[scene.id]
          )
        }
        draft={draft}
        getImageWithObjectKey={getImageWithObjectKey}
        refreshImage={refreshImage}
        updateObject={updateObject}
        forceLoading={forceLoading[scene.id]}
        outerEl={root}
      />

      <div className={classNames(styles['button-box'], styles.first)}>
        <button
          className={classNames(styles.regenerate, styles.button)}
          disabled={
            !!(
              isProcessing ||
              !!animatingTask ||
              downloading[scene.id] ||
              appending[scene.id] ||
              forceLoading[scene.id]
            )
          }
          onClick={() => {
            setIsPromptDialogVisible(true);
          }}
        >
          <RegenerateIcon className={styles.icon} />
          {t('Regenerate')}
        </button>
        <AnimateBtn
          styles={styles}
          isDisabled={
            !!(
              isProcessing ||
              animating ||
              !preview ||
              downloading[scene.id] ||
              appending[scene.id] ||
              forceLoading[scene.id]
            )
          }
          onClick={handleClickAnimateBtn}
          animatingProgress={animatingTask && (animatingTask.progress || 0)}
        />
      </div>
      <div className={styles['button-box']}>
        <button
          className={classNames(styles.asset, styles.button)}
          disabled={
            !!(
              isProcessing ||
              !!animatingTask ||
              !!previewVideo ||
              downloading[scene.id] ||
              appending[scene.id] ||
              forceLoading[scene.id]
            )
          }
          onClick={onUseAsset}
        >
          {appending[scene.id] ? (
            <LoadingSvg className={styles.rotateLoading} />
          ) : (
            <AssetIcon className={styles.icon} />
          )}
          {t('Insert image')}
        </button>
        <button
          className={classNames(styles.download, styles.button)}
          disabled={
            !!(
              isProcessing ||
              (!preview && !draft) ||
              !!animatingTask ||
              downloading[scene.id] ||
              appending[scene.id] ||
              forceLoading[scene.id]
            )
          }
          onClick={onDownload}
        >
          {downloading[scene.id] ? (
            <LoadingSvg className={styles.rotateLoading} />
          ) : (
            <DownloadIcon className={styles.icon} />
          )}
          {t('Download')}
        </button>
      </div>
      {toastProps && (
        <Toast
          title={toastProps.title}
          visible={toastProps.visible}
          confirmText={toastProps.confirmText}
          onOk={toastProps.onOk}
          onCancel={toastProps.onCancel || hideToast}
          maskClosable={false}
          showCancel={toastProps.showCancel}
          cancelText={toastProps.cancelText}
        >
          {toastProps.content}
        </Toast>
      )}
      {isPromptDialogVisible && (
        <GeneratorDialog
          dialogShowing={isPromptDialogVisible}
          onCloseDialog={() => setIsPromptDialogVisible(false)}
          prompt={scene.currentPrompt ?? ''}
          selectedSceneIndex={selectedSceneIndex}
          onClose={() => setIsPromptDialogVisible(false)}
          changeLoading={changeLoading}
          showLoading={() => setIsUploading(true)}
          hideLoading={() => setIsUploading(false)}
          onPromptChange={prompt =>
            updateScene(scene.id, scene.patch({prompt}), true)
          }
          handleOk={url => {
            updateScene(scene.id, scene.patch({image: url}), false);
            setIsPromptDialogVisible(false);
          }}
          onRegenerateWithPosePrompt={(
            objectKeys: string | string[],
            pose_description: string
          ) => {
            executeTaskInEditor('regenerate_scene_by_pose_prompt', scene, {
              objectKeys,
              pose_description,
            });
          }}
          onRegenerate={() => {
            if (!scene) return;
            executeTaskInEditor('regenerate_scene_by_prompt', scene);
            setIsPromptDialogVisible(false);
          }}
          size={size}
          projectId={projectId}
        />
      )}
      {useAssetsModalVisible && (
        <UseAssetModal
          visible={useAssetsModalVisible}
          handleOk={url => {
            appendObjectWithObjectKey(url);
            setIsUploading(false);
            hideUseAssetsModal();
          }}
          handleStartUpload={() => setIsUploading(true)}
          sourceImgUrl={(scene.image && staticCombiner(scene.image!)) || ''}
          ratio={ratio}
          handleCancelUserAsset={hideUseAssetsModal}
        />
      )}
      {useAssetsLimitModalVisible && (
        <Toast
          visible={true}
          title={t('Reach asset limit')}
          onOk={hideUseAssetsLimitModal}
          onCancel={hideUseAssetsLimitModal}
          showCancel={false}
          confirmText={t('OK')}
        >
          {t(
            'You have reached the maximum limit of 10 assets for this scene. New asset can not be uploaded.'
          )}
        </Toast>
      )}
      {assetEditorInfo && (
        <Modal
          title={t('Edit Asset')}
          visible={true}
          onCancel={() => {
            transferImageRef.current = null;
            setAssetEditorInfo(null);
            assetEditorInfoRef.current = null;
          }}
          showCloseIcon={true}
          maskClosable={false}
          footer={null}
        >
          <AssetEditor
            type={assetEditorInfo.type}
            imageUrl={assetEditorInfo.url}
            imageWidth={assetEditorInfo.width}
            imageHeight={assetEditorInfo.height}
            filename={
              new URL(assetEditorInfo.url).pathname.split('/').pop() || ''
            }
            handleApply={handleAssetEditorApply}
            uploadToAssetLibrary={uploadToAssetLibrary}
            onReset={() => {
              transferImageRef.current = null;
            }}
            errorToastType={errorToastType}
            errorToastVisible={errorToastVisible}
            hideErrorToast={hideErrorToast}
          />
        </Modal>
      )}
      <LoadingPromptDialog
        dialogShowing={isUploading}
        type={'processing'}
        onCloseDialog={noop}
      />
    </div>
  );
}
function getToastProps({
  toastType,
  toastVisible,
  plan,
  animatePrice,
  hideToast,
  onAnimate,
  showToast,
  onChangeOptionValue,
  t,
  initAnimatePrompt,
}: {
  toastType: ErrorType | undefined;
  toastVisible: boolean;
  plan: string;
  animatePrice: number;
  hideToast: () => void;
  onAnimate?: (
    shouldCheckWaitingTime: boolean,
    animateInfo: AnimateInfo
  ) => void;
  showToast: (type: ErrorType) => void;
  onChangeOptionValue: (value: string) => void;
  t: TFunction;
  initAnimatePrompt?: string;
}) {
  if (!toastType) return undefined;
  let toastProps: ToastProps;

  switch (toastType) {
    case 'Animate':
      toastProps = {
        title: (
          <>
            <AnimateIcon
              className={styles.icon}
              style={{fill: '#7D7D7D', marginRight: '10px'}}
            />
            {t('Animate')}
            <span className="new-tag new-tag-ml4">{t('New')}</span>
          </>
        ),
        visible: toastVisible,
        confirmText: (
          <>
            {t('OK')}
            <span className={styles.credits}>
              <span className={styles.abandon}>
                <CoinIcon className={styles.icon} />
                <span>{animatePrice}</span>
              </span>
            </span>
          </>
        ),
        onOk: async (_, data) => {
          hideToast();
          try {
            onAnimate && (await onAnimate(true, data));
          } catch (err) {
            if (
              axios.isAxiosError(err) &&
              err.response &&
              err.response.status === 429
            ) {
              setTimeout(() => {
                showToast('LimitAnimate');
              }, 0);
            } else if (err instanceof Error && err.message === 'Overloaded') {
              setTimeout(() => {
                showToast('Overloaded');
              }, 0);
            }
          }
        },
        content: (
          <AnimateSelect
            disableAdvanceOption={plan === 'FREE'}
            onChangeOptionValue={onChangeOptionValue}
            initAnimatePrompt={initAnimatePrompt}
            type="animate"
          />
        ),
        cancelText: t('Cancel'),
      };
      break;
    case 'LimitAnimate':
      toastProps = {
        title: t("That's it for today!"),
        visible: toastVisible,
        confirmText: t('OK'),
        onOk: hideToast,
        content: t(
          'Due to high demand, we are limiting free users to animate 20 times per day. Paid users can animate without limits.'
        ),
        showCancel: false,
      };
      break;
    default:
      toastProps = {
        visible: toastVisible,
      };
      break;
  }
  return toastProps;
}
