// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CreditIcon} from 'assets/svg/3.0/CreditIcon.svg';
import classNames from 'classnames';
import {Button} from 'components/Button';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Card} from '../Card';
import {creditOptions} from '../constData';
import styles from './CreditCard.module.scss';
import {Props} from './CreditCard.types';

export function CreditCard({onBuyCredits, isDisabled}: Props) {
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const onBuy = useCallback(async () => {
    if (!selectedProductId || isLoading) return;
    setIsLoading(true);
    await onBuyCredits(selectedProductId);
    setIsLoading(false);
  }, [isLoading, onBuyCredits, selectedProductId]);

  const {t} = useTranslation('plan');

  return (
    <Card
      rows={[
        {
          label: t('Buy more credits'),
        },
        {
          label: '',
          value: (
            <p className={styles.description}>
              {t(
                'Only available for paid tiers. Valid through the current and next billing cycle.'
              )}
            </p>
          ),
        },
        {
          label: '',
          value: (
            <ul className={styles.list}>
              {creditOptions.map(({credit, price, productId}, index) => {
                return (
                  <li
                    className={classNames(
                      styles.item,
                      selectedProductId === productId && styles.selected,
                      isDisabled && styles.disabled
                    )}
                    key={index}
                    onClick={() =>
                      !isDisabled && setSelectedProductId(productId)
                    }
                  >
                    <span className={styles.left}>
                      <CreditIcon className={styles.icon} />
                      {credit}
                    </span>
                    <span className={styles.right}>${price}</span>
                  </li>
                );
              })}
            </ul>
          ),
        },
        {
          label: ' ',
          value: (
            <>
              {selectedProductId && (
                <Button
                  isLoading={isLoading}
                  onClick={onBuy}
                  icon={<></>}
                  disabled={isDisabled || isLoading}
                  className={classNames(
                    styles.button,
                    isLoading && styles.loading
                  )}
                >
                  {t('Continue to pay')}
                </Button>
              )}
              {!selectedProductId && (
                <p className={styles.tips}>{t('Select an amount')}</p>
              )}
            </>
          ),
        },
      ]}
    />
  );
}
