// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ResponseData} from 'api/server';

import {UploadStatus} from './modules';

// import {
//   ProjectConfig,
//   ProjectTone,
//   Storyboard,
// } from 'modules/project/project.types';

export type ProjectItemType = {
  projectId: string;
  projectName: string;
  thumbnailUrl: string;
  frameRatio: string;
  updateTime?: string;
  prompt?: string;
  historyNumber?: number;
  lang?: string;
};

export type ProjectItemListResponse = ProjectItemType[];

export type GalleryItemType = {
  galleryId: string;
  galleryName: string;
  thumbnailUrl: string;
  frameRatio: string;
  prompt?: string;
  lang?: string;
};
export type GalleryItemListResponse = GalleryItemType[];

export type GalleryInfoType = GalleryItemType & {galleryJsonContent: string};

export type ProjectOrGalleryItemType = ProjectItemType | GalleryItemType;
export function isProjectItemType(
  item: ProjectOrGalleryItemType
): item is ProjectItemType {
  return 'projectId' in (item || {}) && 'projectName' in (item || {});
}

export function isGalleryItemType(
  item: ProjectOrGalleryItemType
): item is GalleryItemType {
  return 'galleryId' in item && 'galleryName' in item;
}

export type AssetItemType = {
  assetId: string;
  assetName: string;
  assetThumbUrl: string;
  assetUrl: string;
  assetSize: number;
  updateTime: number;
};
export type AssetItemListResponse = AssetItemType[];
export type SignedUrlFieldsType = {
  bucket: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Date': string;
  key: string;
  Policy: string;
  'X-Amz-Signature': string;
};
interface SignedUrl {
  url: string;
  fields: SignedUrlFieldsType;
}
export type AssetCreateResponse = {
  signedUrl: SignedUrl;
  assetId: string;
};
export type GetProjectResponse = ResponseData<{
  userId: string;
  projectId: string;
  projectName: string;
  thumbnailUrl: string;
  frameRatio: string;
  projectJsonContent: string; // json string
  createTime: string;
  updateTime: string;
}>;
export type updateAssestStateType = {
  assetId: string;
  data: {
    status: UploadStatus;
  };
};
export type createAssestRequestType = {
  fileName: string;
  fileSizeKb: number;
  contentType?: string;
  transferred?: boolean;
};
