// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LoadIcon} from 'assets/svg/3.0/Load.svg';
import classNames from 'classnames';
import React, {MouseEvent, ReactNode, useState} from 'react';

import styles from './Button.module.scss'; // 假设你已经定义了样式

type ButtonSize = 'large' | 'middle' | 'small';
type ButtonType = 'button' | 'ghost' | 'text' | 'link' | 'icon';
type ButtonTheme = 'primary' | 'danger' | 'default';

export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  icon?: ReactNode;
  size?: ButtonSize;
  styles?: React.CSSProperties;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  type?: ButtonType;
  theme?: ButtonTheme;
  cooldown?: number;
  isLoading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  disabled = false,
  icon,
  size = 'middle',
  styles: customStyles,
  onClick,
  children,
  isLoading,
  type = 'button',
  theme = 'primary',
  cooldown = 300, //为0 就相当于不防抖了
}) => {
  const [isCooldown, setIsCooldown] = useState(false);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isCooldown) return;
    if (onClick && !disabled) {
      onClick(event);
    }
    if (cooldown) {
      setIsCooldown(true);
      setTimeout(() => {
        setIsCooldown(false); // 冷却结束，恢复按钮可点击状态
      }, cooldown);
    }
  };

  const buttonClass = `${styles[type]} ${
    size && styles[size] ? styles[size] : ''
  } ${theme && styles[theme] ? styles[theme] : ''} ${className || ''}`;

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      style={customStyles}
      onClick={handleClick}
    >
      {icon && !isLoading && <span className={styles.icon}>{icon}</span>}
      {isLoading && (
        <span className={styles.icon}>
          <LoadIcon
            className={classNames(styles['load-icon'], !icon && styles.center)}
          />
        </span>
      )}
      {(!isLoading || icon !== undefined) && children}
    </button>
  );
};
