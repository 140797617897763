import {useNotificationContext} from 'contexts/NotificationContext';
import {useEffect, useState} from 'react';

import styles from './ProjectTitle.module.scss';
import {Props} from './ProjectTitle.types';

export function ProjectTitle({
  isEdit = false,
  value: defaultValue,
  onSubmit,
  placeholderValue,
}: Props) {
  const [value, setValue] = useState(defaultValue);
  const {showNotification} = useNotificationContext();

  async function handleSubmit() {
    try {
      await onSubmit?.(value);
      showNotification({
        type: 'SUCCESS',
        message: 'rename successfully',
      });
    } catch (err) {
      showNotification({
        type: 'ERROR',
        message: 'rename failed',
      });
      setValue(defaultValue);
    }
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  if (!isEdit) return <span>{value || placeholderValue}</span>;
  return (
    <input
      className={styles.renameInput}
      type="text"
      defaultValue={value}
      onMouseUp={e => e.stopPropagation()}
      onMouseEnter={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      maxLength={100}
      autoFocus
      onChange={e => setValue(e.target.value)}
      onBlur={() => handleSubmit()}
      onKeyDown={e => {
        if (e.key === 'Enter') handleSubmit();
      }}
    />
  );
}
