// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {createProject, updateProject} from 'api/server';
import {ReactComponent as SurpriseIcon} from 'assets/svg/outline/Surprise.svg';
import {LoadingPromptDialog} from 'components/LoadingPrompt';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useUserContext} from 'contexts/UserContext';
import {formatAspectRatio} from 'lib/ratio';
import {noop} from 'lodash';
import {ProjectJSON, ProjectType} from 'modules/project/types';
import {duplicateGallery} from 'modules/project/utils';
import {GalleryPreviewerDialog} from 'pages/WorkspacePage/GalleryPreviewer';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {generateIdPath, PROJECT_PATH} from 'utils/path';

import styles from './PromotionDialog.module.scss';
import {Props} from './PromotionDialog.types';

export function PromotionDialog({
  description,
  target,
  onClose,
  left,
  top,
  visible,
}: Props) {
  const {t, i18n} = useTranslation('workspace');
  const currentLangCode = i18n.language;
  const [position, setPosition] = useState({top: -9999, left: -9999});
  const dialogRef = useRef<HTMLDivElement>(null);
  const [currentGalleryId, setCurrentGalleryId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {showNotification} = useNotificationContext();
  const {userInfo} = useUserContext();
  const navigate = useNavigate();
  const closeHandler = useCallback(() => {
    setPosition({top: -9999, left: -9999});
    onClose();
  }, [onClose]);
  useEffect(() => {
    if (!dialogRef.current) return;
    const newPosition = {top, left};
    const {innerWidth, innerHeight} = window;
    const {width, height} = dialogRef.current.getBoundingClientRect();
    if (newPosition.left + width > innerWidth) {
      newPosition.left = newPosition.left - width;
    }
    if (newPosition.top + height > innerHeight) {
      newPosition.top = innerHeight - height;
    }
    setPosition(newPosition);
  }, [left, top]);

  const onUseGallery = useCallback(
    async (gallery: ProjectJSON<ProjectType>) => {
      setIsLoading(true);
      setCurrentGalleryId(null);
      try {
        const newProject = duplicateGallery(gallery);
        newProject.author_id = userInfo.userId;
        const {data: projectId} = await createProject({
          frameRatio: formatAspectRatio(newProject.size),
          projectJsonContent: JSON.stringify(newProject),
          lang: newProject.language,
        });
        await updateProject({
          projectId,
          projectName: `${gallery.storyboard?.title} copy`,
          lang: newProject.language,
        });
        showNotification({
          type: 'SUCCESS',
          message: 'A new project created',
        });
        navigate(generateIdPath(PROJECT_PATH, projectId));
      } catch {
        // TODO: 使用失败
      }
      setIsLoading(false);
    },
    [userInfo.userId, showNotification, navigate]
  );

  const onPromotionClick = useCallback(() => {
    closeHandler();
    switch (target.type) {
      case 'outer-link':
        window.open(target.value, '_blank');
        break;
      case 'inner-link':
        navigate(target.value);
        break;
      case 'create_project_from_gallery':
        setCurrentGalleryId(target.value);
        break;
      default:
        break;
    }
  }, [closeHandler, navigate, target.type, target.value]);

  return (
    <>
      {visible && (
        <>
          <div className={styles.mask} onClick={closeHandler}></div>
          <div
            className={styles.promotion}
            style={{left: position.left, top: position.top}}
            ref={dialogRef}
          >
            <SurpriseIcon className={styles.icon} />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: description[currentLangCode] ?? description.en,
              }}
            ></div>
            <div className={styles.target} onClick={onPromotionClick}>
              {target.title[currentLangCode] ?? target.title.en}
            </div>
          </div>
        </>
      )}

      {currentGalleryId && (
        <GalleryPreviewerDialog
          dialogShowing
          onCloseDialog={() => setCurrentGalleryId(null)}
          galleryId={currentGalleryId}
          onUseGallery={onUseGallery}
        />
      )}
      <LoadingPromptDialog
        type="processing"
        dialogShowing={isLoading}
        onCloseDialog={noop}
      />
    </>
  );
}
