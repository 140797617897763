// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import {ReactComponent as ArrowTopIcon} from 'assets/svg/outline/ArrowTop.svg';
import {ReactComponent as PromptTipsIcon} from 'assets/svg/outline/PromptTips.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {Button} from 'pages/components/Button';
import {useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PromptEditorProps} from './Generator.types';

export function PromptEditor({
  styles,
  selectedSceneIndex,
  prompt,
  onPromptChange,
  switchValue,
}: PromptEditorProps) {
  const [isFocus, setIsFocus] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const {t} = useTranslation('project');
  return (
    <>
      <div className={styles.label}>
        <div className={styles.title}>
          {t('Prompt for scene', {
            index: selectedSceneIndex + 1,
          })}
        </div>
        {switchValue && <PromptTipsIconWithPopover styles={styles} />}
      </div>
      <div
        className={classNames(styles['textarea-box'], isFocus && styles.focus)}
      >
        <textarea
          className={styles.textarea}
          value={prompt}
          maxLength={500}
          onChange={e => onPromptChange(e.currentTarget.value)}
          ref={textareaRef}
          onBlur={_ => {
            setIsFocus(false);
          }}
          onFocus={() => setIsFocus(true)}
        />
        <div className={styles['prompt-length']}>
          <p
            className={classNames(
              styles.circle,
              500 - prompt.length <= 0 && styles.error
            )}
          />
          <span>{Math.max(500 - prompt.length, 0)}</span>
        </div>
      </div>
    </>
  );
}
type PromptTipsIconWithPopoverProps = {
  styles: {[key: string]: string};
};
function PromptTipsIconWithPopover({styles}: PromptTipsIconWithPopoverProps) {
  const [visible, setVisible] = useState(false);
  const hideVisible = useCallback(() => setVisible(false), []);
  return (
    <Popover
      triggerElement={
        <PromptTipsIcon
          className={classNames(styles.promptTipIcon, {
            [styles.active]: visible,
          })}
        />
      }
      content={
        <PromptTipsIconContent styles={styles} hideVisible={hideVisible} />
      }
      className={styles.promptTipsPopover}
      style={{zIndex: 1000, transform: 'translateX(calc(-50% + 24px))'}}
      direction="bottom"
      distance={-5}
      visible={visible}
      onVisibleChange={setVisible}
    ></Popover>
  );
}

type PromptTipsIconContentProps = {
  styles: {[key: string]: string};
  hideVisible: () => void;
};
function PromptTipsIconContent({
  styles,
  hideVisible,
}: PromptTipsIconContentProps) {
  const {t} = useTranslation('project');
  const PromptTips = [
    {
      text: t('A girl in a red skirt'),
      desc: t('Character description'),
      id: 1,
    },
    {
      text: t('dancing alone '),
      desc: t('Action description'),
      id: 2,
    },
    {
      text: t('in a cozy room'),
      desc: t('Scene description'),
      id: 3,
    },
  ];
  return (
    <div className={styles.promptTipsContentWrap}>
      <div className={styles.title}>
        <span>{t('Suggested prompt structure')} </span>
        <PromptTipsIcon className={styles.tipIcon} />
      </div>
      <div className={styles.subTitle}>
        {t('Follow the structure in your prompt for better generations')}
      </div>
      <div className={styles.prompt}>
        {PromptTips.map(item => (
          <div className={styles.promptItem} key={item.id}>
            <div className={styles.text}>{item.text}</div>
            <div className={styles.desc}>
              <ArrowTopIcon />
              <div className={styles.textBox}>{item.desc} </div>
            </div>
          </div>
        ))}
      </div>
      <Button
        type="link"
        size="middle"
        theme="primary"
        className={styles.footerBtn}
        onClick={hideVisible}
      >
        {t('OK')}
      </Button>
    </div>
  );
}
