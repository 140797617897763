// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LoadingSvg} from 'assets/svg/outline/LoadingLine.svg';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import styles from './Loading.module.scss';
import {Props} from './Loading.types';

export function Loading({size, hasText = true}: Props) {
  const {t} = useTranslation('workspace', {useSuspense: false});
  return (
    <div className={classNames(styles['loading-info'], 'loading-info')}>
      <LoadingSvg
        className={styles.rotateLoading}
        style={{
          width: size ? `${size}px` : undefined,
          height: size ? `${size}px` : undefined,
        }}
      />
      {hasText && <div>{t('Loading...')}</div>}
    </div>
  );
}
