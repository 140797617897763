// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';
import {TaskType as StoryboardTaskType} from 'modules/storyboard/types';
import {Task} from 'modules/task/models/Task';

import {AnimateModelMap, ClosedScene, TaskType} from './types';

const TASK_TYPES: TaskType[] = [
  'split_scene',
  'merge_scenes',
  'regenerate_scene_by_prompt',
  'regenerate_scene_by_pose_prompt',
  'image_conditioning_video',
];

export function isSceneTask(
  task: Task<StoryboardTaskType>
): task is Task<TaskType> {
  return TASK_TYPES.includes(task.type as TaskType);
}

export function getScriptCopyContent(scenes: ClosedScene<ProjectType>[]) {
  return (
    scenes
      ?.reduce((resArr: string[], scene) => {
        let speaker = '';
        let str = '';
        if ('speaker' in scene) {
          speaker = scene.speaker ? `${scene.speaker}: ` : '';
        }
        scene.subtitle && (str += `${speaker + scene.subtitle}`);
        if ('native_subtitle' in scene) {
          str += `\n${speaker + scene.native_subtitle || ''}`;
        }
        resArr.push(`\n${str}\n`);
        return resArr;
      }, [])
      .join('') || ''
  );
}
export const AnimateOptionTypeEnums = {
  Base: 'base',
  Vira: 'vira',
} satisfies AnimateModelMap;
