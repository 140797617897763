// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Tone} from 'modules/project/types';
import {TONE_OPTIONS} from 'modules/project/utils';
import {useTranslation} from 'react-i18next';

import {CommonSelectWithoutLabel} from './CommonSelectWithoutLabel/CommonSelectWithoutLabel';

export interface Props {
  value: Tone;
  onChange: (value: Tone) => void;
}

export function ToneSelect({value, onChange}: Props) {
  const {t} = useTranslation('project');
  const options = TONE_OPTIONS.map(option => ({
    ...option,
    label: t(option.label),
  }));
  return (
    <CommonSelectWithoutLabel
      options={options}
      value={value}
      title={t('Tone')}
      placeholderOfOtherInput={t('Type in customize tone here')}
      onChange={value => onChange(value as Tone)}
      customizeValue="Other"
    />
  );
}
