// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import PlayingIcon from 'assets/images/playing.gif';
import {ReactComponent as BGMIcon} from 'assets/svg/3.0/bgm.svg';
import {ReactComponent as FastIcon} from 'assets/svg/3.0/FastIcon.svg';
import {ReactComponent as Instrumental} from 'assets/svg/3.0/Instrumental.svg';
import {ReactComponent as Lock} from 'assets/svg/3.0/Lock2.svg';
import {ReactComponent as NoneIcon} from 'assets/svg/3.0/None.svg';
import {ReactComponent as SlowIcon} from 'assets/svg/3.0/SlowIcon.svg';
import {ReactComponent as VocalIcon} from 'assets/svg/3.0/vocal.svg';
import VoiceoverIcon from 'assets/svg/3.0/Voiceover.svg';
import {Option} from 'components/Option';
import {useResourceManager} from 'contexts/ResourceManager';
import {useUserContext} from 'contexts/UserContext';
import {Collapse} from 'pages/components/Collapse';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './BGMList.module.scss';
import {Props} from './BGMList.types';

export function BGMList({bgm, bgms, onChange}: Props) {
  const {resUrl} = useResourceManager();
  const [BGMPlayingOfPath, setBGMPlayingOfPath] = useState<string | null>(null);
  const [showMore, setShowMore] = useState(false);
  const bgmList = useMemo(
    () => (showMore ? bgms : bgms.slice(0, 8)),
    [showMore, bgms]
  );
  const hasShowMore = useMemo(() => bgms.length > 8, [bgms.length]);
  const {
    userInfo: {plan},
  } = useUserContext();

  const audioRef = useRef<HTMLAudioElement>(document.createElement('audio'));

  const onStopVoiceover = useCallback(() => {
    setBGMPlayingOfPath(null);
    audioRef.current.pause();
  }, []);

  const onPlayVoiceover = useCallback(
    async (path: string) => {
      setBGMPlayingOfPath(path);
      audioRef.current.src = `${resUrl}${path}`;
      audioRef.current.onended = onStopVoiceover;
      await audioRef.current.play();
    },
    [onStopVoiceover, resUrl]
  );

  const onVoiceoverAction = useCallback(
    (audio: string) => {
      onStopVoiceover();
      if (!BGMPlayingOfPath || BGMPlayingOfPath !== audio) {
        onPlayVoiceover(audio);
      }
    },
    [onPlayVoiceover, onStopVoiceover, BGMPlayingOfPath]
  );

  useEffect(() => {
    return onStopVoiceover;
  }, [onStopVoiceover]);

  const {t} = useTranslation('project');
  const {t: resourceBuilderT} = useTranslation('resourceBuilder');

  return (
    <Collapse icon={<BGMIcon className={styles.icon} />} title={t('AI Music')}>
      <ul className={styles.container}>
        <li className={styles.item}>
          <Option
            isDisabled={false}
            label={t('None')}
            isChecked={bgm === null}
            onCheck={() => {
              if (bgm === null) return;
              onChange(null);
            }}
          />
          <NoneIcon className={styles.svg} />
        </li>
        {bgmList.map(
          ({
            display_name: displayName,
            name,
            category,
            id,
            asset,
            genre,
            emotions,
            flow,
            supported_plans = ['FREE', 'STANDARD', 'PRO'],
          }) => (
            <li key={id} className={styles.item}>
              <Option
                isDisabled={!supported_plans.includes(plan)}
                label={
                  <div className={styles.title}>
                    {category === 'Instrumental Music' ? (
                      <Instrumental />
                    ) : (
                      <VocalIcon />
                    )}
                    <div>{displayName}</div>
                    {flow === 'Fast' && <FastIcon />}
                    {flow === 'Slow' && <SlowIcon />}
                    <div className={styles.labels}>
                      <div className={styles.label}>
                        {resourceBuilderT(genre)}
                      </div>
                      <div className={styles.label}>
                        {emotions
                          .map(emotion => resourceBuilderT(emotion))
                          .join(', ')}
                      </div>
                    </div>
                  </div>
                }
                isChecked={name === bgm}
                onCheck={() => {
                  if (name === bgm) return;
                  onChange(name);
                }}
              />
              <div className={styles['item-right']}>
                {!supported_plans.includes(plan) && (
                  <div className={styles.lock}>
                    <Lock />
                  </div>
                )}

                <img
                  className={styles.icon}
                  src={BGMPlayingOfPath === asset ? PlayingIcon : VoiceoverIcon}
                  onClick={() => onVoiceoverAction(asset)}
                />
              </div>
            </li>
          )
        )}
      </ul>
      {hasShowMore && (
        <div className={styles.showMore}>
          <span
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {!showMore ? t('Show more') : t('Show less')}
          </span>
        </div>
      )}
    </Collapse>
  );
}
