// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Toast} from 'pages/components/Toast';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'utils/path';

import {ErrorToastshowCancelMap} from './const';
import {GeneratorToastProps} from './Generator.types';
export function GeneratorToast({
  errorToastType,
  errorDialogVisible,
  hideErrorDialog,
  onRegenerate,
}: GeneratorToastProps) {
  const navigate = useNavigate();
  const handleDialogConfirm = useCallback(() => {
    hideErrorDialog();
    if (errorToastType === 'systemError') {
      return;
    } else if (errorToastType === 'noPose') {
      onRegenerate();
    } else if (errorToastType === 'no_credit') {
      navigate(PLAN_PAGE_PATH);
    }
  }, [errorToastType, hideErrorDialog, navigate, onRegenerate]);
  const {t} = useTranslation('project');
  const ErrorToastContentMap = {
    systemError: () => t('System error. Please try again.'),
    noPose: () => t('Continue without pose control?'),
    no_credit: () =>
      t('Sorry, all credits have been depleted. You can recharge now.'),
  };
  const ErrorToastMap = {
    systemError: t('Notice'),
    noPose: t('Notice'),
    no_credit: t('No Credit'),
  };
  const ErrorToastConfirmTextMap = {
    systemError: t('OK'),
    noPose: t('Yes'),
    no_credit: t('Recharge'),
  };
  return (
    <Toast
      title={errorToastType && ErrorToastMap[errorToastType]}
      visible={errorDialogVisible}
      showCloseBtn={false}
      onCancel={hideErrorDialog}
      onOk={handleDialogConfirm}
      showCancel={errorToastType && ErrorToastshowCancelMap[errorToastType]}
      confirmText={errorToastType && ErrorToastConfirmTextMap[errorToastType]}
      cancelText={t('Cancel')}
    >
      {errorToastType &&
        ErrorToastContentMap[errorToastType] &&
        ErrorToastContentMap[errorToastType]()}
    </Toast>
  );
}
