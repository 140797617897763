// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CopyIcon} from 'assets/svg/3.0/Copy.svg';
import classNames from 'classnames';
import {ScriptPreviewer} from 'components/ScriptPreviewer';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {copy} from 'lib/copy';
import {ProjectType} from 'modules/project/types';
import {trimNewline} from 'modules/project/utils';
import {BilingualStoryClosedScene} from 'modules/scene/types';
import {getScriptCopyContent} from 'modules/scene/utils';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isRtl} from 'utils/is-rtl';

import {Textarea} from '../Textarea';
import styles from './DetailBox.module.scss';
import {Props} from './DetailBox.types';

export function DetailBox({
  title,
  duration,
  summary,
  scenesNumber,
  isDisabled,
  size,
  hashtags,
  storyboard,
  languageCode,
  nativeLanguageCode,
  projectType,
  onHashTagsChange,
  onSummaryChange,
}: Props) {
  const {getLanguages, getNativeLanguages} = useResourceManager();
  const {showNotification} = useNotificationContext();
  const [hashTagStr, setHashTagStr] = useState(
    hashtags.map(tag => `#${tag}`).join(' ')
  );

  const convertStringToHashTags = useCallback((hashTagStr: string) => {
    const tags = hashTagStr.substring(0, 1000).split('#');
    tags.shift();
    return tags;
  }, []);

  const {t} = useTranslation('project');
  const {t: commonT} = useTranslation();

  const onCopy = useCallback(
    async (text: string) => {
      await copy(text);
      showNotification({message: 'Copied', type: 'SUCCESS'});
    },
    [showNotification]
  );

  return (
    <div className={styles.container}>
      <div className={styles.scroll}>
        <div className={styles.item}>
          <p className={styles.title}>
            <span className={classNames(styles.label, 'rtl-element')}>
              {trimNewline(title || commonT('Untitled'), languageCode)}
            </span>
            <CopyIcon
              className={classNames(styles.icon, styles.copy)}
              onClick={() => onCopy(title)}
            />
          </p>
          <ul className={styles.infos}>
            <li>{t('n scenes', {n: scenesNumber})}</li>
            <li>
              {size[0]}x{size[1]}
            </li>
            {duration && <li>{duration.toFixed(1)}s</li>}
          </ul>
        </div>
        <div className={styles.item}>
          <p className={styles.title}>
            <span className={styles.label}>{t('Summary')}</span>
            <CopyIcon
              className={classNames(styles.icon, styles.copy)}
              onClick={() => onCopy(summary)}
            />
          </p>
          <Textarea
            value={summary}
            maxLength={2000}
            disabled={isDisabled}
            onChange={e => onSummaryChange(e.currentTarget.value)}
            className={getClassName(projectType)}
          />
        </div>
        <div className={styles.item}>
          <p className={styles.title}>
            <span className={styles.label}>{t('Hashtags')}</span>
            <CopyIcon
              className={classNames(styles.icon, styles.copy)}
              onClick={() => onCopy(hashTagStr)}
            />
          </p>
          <Textarea
            value={hashTagStr}
            disabled={isDisabled}
            maxLength={2000}
            onChange={e => {
              setHashTagStr(e.currentTarget.value);
              const tags = convertStringToHashTags(e.currentTarget.value);
              onHashTagsChange(tags);
            }}
            className={getClassName(projectType)}
          />
        </div>
        <div className={styles.item}>
          <p className={styles.title}>
            <span className={styles.label}>{t('Script')}</span>
            <CopyIcon
              className={classNames(styles.icon, styles.copy)}
              onClick={() => {
                onCopy(getScriptCopyContent(storyboard!.scenes));
              }}
            />
          </p>
          <div className={styles['scripts-box']}>
            <ScriptPreviewer
              isRtl={isRtl(getLanguages(), languageCode)}
              scripts={
                storyboard?.scenes.map(scene => {
                  if ('speaker' in scene) {
                    return `${(scene.speaker && scene.speaker + ': ') || ''}${
                      scene.subtitle
                    }`;
                  }
                  return scene.subtitle || '';
                }) ?? []
              }
              isNativeRtl={
                nativeLanguageCode
                  ? isRtl(getNativeLanguages(languageCode), nativeLanguageCode)
                  : undefined
              }
              nativeScripts={
                (nativeLanguageCode &&
                  storyboard?.scenes.map(scene => {
                    if ('speaker' in scene) {
                      return `${(scene.speaker && scene.speaker + ': ') || ''}${
                        scene.native_subtitle
                      }`;
                    }
                    return (
                      (scene as BilingualStoryClosedScene).native_subtitle || ''
                    );
                  })) ||
                undefined
              }
            />
          </div>
        </div>
        {storyboard?.promptPolicy && storyboard.promptPolicy.isIdeaPrompt && (
          <div className={styles.item}>
            <p className={styles.title}>
              <span className={styles.label}>{t('Prompt')}</span>
              <CopyIcon
                className={classNames(styles.icon, styles.copy)}
                onClick={() => onCopy(storyboard.prompt)}
              />
            </p>
            <Textarea
              value={storyboard.prompt}
              disabled
              className={classNames(
                styles['textarea-disabled'],
                getClassName(projectType)
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}
function getClassName(projectType: ProjectType) {
  if (
    projectType === 'bilingual_story' ||
    projectType === 'bilingual_dialogue'
  ) {
    return 'rtl-bilingual-element';
  } else {
    return 'rtl-element';
  }
}
