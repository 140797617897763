// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {withDialog} from 'pages/components/Dialog';

import {BackBox} from './BackBox';

export const BackDialog = withDialog({
  style: {
    left: '50%',
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    animation: 'none',
    position: 'absolute',
  },
})(BackBox);
