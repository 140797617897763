// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CloseBtn} from 'assets/svg/3.0/CloseBtn.svg';
import classNames from 'classnames';
import React, {
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {createPortal} from 'react-dom';

import {Button} from '../Button';
import {ButtonProps} from '../Button/Button';
import styles from './Toast.module.scss';

interface ToastProps {
  visible: boolean;
  getContainer?: () => HTMLElement;
  keyboard?: boolean;
  maskClosable?: boolean;
  onOk?: (close: () => void, validData?: any) => void | Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
  showCloseBtn?: boolean;
  title?: string | React.ReactNode;
  btnTheme?: 'primary' | 'danger';
  footer?: ((params: {close: () => void}) => React.ReactNode) | React.ReactNode;
  cancelText?: string;
  confirmText?: string | React.ReactNode;
  showFooter?: boolean;
  showCancel?: boolean;
  className?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
}

export const Toast: React.FC<ToastProps> = ({
  visible,
  getContainer = () => document.body,
  keyboard = true,
  maskClosable = true,
  onOk,
  onCancel,
  children,
  showCloseBtn = true,
  title,
  footer,
  btnTheme = 'primary',
  cancelText = 'Cancel',
  confirmText = 'Delete',
  showFooter = true,
  showCancel = true,
  className,
  cancelButtonProps = {},
  confirmButtonProps = {},
}) => {
  const childRef = useRef<any>(null); // Create a ref for the child

  const close = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  const handleOk = async () => {
    // Attempt to call `getValidData` if the child implements it
    let validData;
    if (isValidElement(children)) {
      // Check if the child supports `getValidData`
      if (childRef.current?.getValidData) {
        validData = await childRef.current.getValidData();
      }
    }
    if (onOk) {
      const promise = onOk(close, validData);
      if (promise instanceof Promise) {
        promise.then(close);
      }
    } else {
      close();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (keyboard && event.key === 'Escape') {
        close();
      }
    };

    if (visible && keyboard) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [visible, keyboard, close]);

  const toastContent = visible ? (
    <div
      className={styles.toastContainer}
      onClick={() => maskClosable && close()}
    >
      <div
        className={classNames(styles.toastContent, className)}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          {showCloseBtn && (
            <span className={styles.close_btn} onClick={() => close()}>
              <CloseBtn />
            </span>
          )}
        </div>
        <div className={styles.content}>
          {isValidElement(children)
            ? cloneElement(children, {ref: childRef} as any)
            : children}
        </div>
        {footer
          ? typeof footer === 'function'
            ? footer({close})
            : footer
          : showFooter && (
              <div className={styles.footer}>
                {showCancel && (
                  <Button
                    onClick={onCancel}
                    size="large"
                    type="ghost"
                    className={classNames(styles.footerBtn, styles.cancelBtn)}
                    {...cancelButtonProps}
                  >
                    {cancelText}
                  </Button>
                )}

                <Button
                  onClick={handleOk}
                  theme={btnTheme}
                  size="large"
                  className={classNames(styles.footerBtn)}
                  {...confirmButtonProps}
                >
                  {confirmText}
                </Button>
              </div>
            )}
      </div>
    </div>
  ) : null;

  return createPortal(
    toastContent,
    getContainer() ? getContainer() : document.body
  );
};
