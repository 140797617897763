// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {Option} from 'components/Option';
import {Popover} from 'components/PopoverNew';
import {useResourceManager} from 'contexts/ResourceManager';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Select} from '../Select';
import {SelectCard} from '../SelectCard';
import styles from './LanguageSelect.module.scss';
import {Props} from './LanguageSelect.types';

export function LanguageSelect({value, onChange}: Props) {
  const {t} = useTranslation('project');
  const {t: resourceBuilderT} = useTranslation('resourceBuilder');
  const {getLanguages} = useResourceManager();

  const [isOpen, setIsOpen] = useState(false);
  const selectedLanguageName = useMemo(
    () =>
      getLanguages().find(languageData => languageData.code === value)
        ?.native_name || '',
    [getLanguages, value]
  );

  return (
    <Select
      label="Output"
      value={selectedLanguageName}
      isOpen={isOpen}
      onVisibleChange={setIsOpen}
    >
      <SelectCard label={t('Output language')}>
        <ul className={styles['language-list']}>
          {getLanguages().map(languageData => (
            <li
              key={languageData.code}
              className={classNames(
                styles['language-item'],
                languageData.code === value && styles.active
              )}
            >
              <Option
                label={
                  languageData.tooltip ? (
                    <Popover
                      triggerElement={
                        <span className={styles['language-label']}>
                          {languageData.native_name}
                        </span>
                      }
                      trigger="hover"
                      content={
                        <div className={styles['popover-content']}>
                          {resourceBuilderT(`tooltip_${languageData.tooltip}`)}
                        </div>
                      }
                      direction="right"
                      distance={20}
                    />
                  ) : (
                    <span className={styles['language-label']}>
                      {languageData.native_name}
                    </span>
                  )
                }
                isChecked={languageData.code === value}
                isDisabled={false}
                onCheck={() => {
                  setIsOpen(false);
                  if (languageData.code === value) return;
                  onChange(languageData.code);
                }}
              />
            </li>
          ))}
        </ul>
      </SelectCard>
    </Select>
  );
}
