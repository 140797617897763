// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import styles from './UpgradeUnlockStyleBox.module.scss';
import {Props} from './UpgradeUnlockStyleBox.types';

export function UpgradeUnlockStyleBox({onCancel, onUpgrade}: Props) {
  const {t} = useTranslation('workspace');
  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('Upgrade to unlock')}</p>
      <p className={styles.content}>
        {t('Upgrade your plan to enjoy more styles.')}
      </p>
      <div className={styles['button-box']}>
        <button
          className={classNames(styles.button, styles.cancel)}
          onClick={onCancel}
        >
          {t('I’m OK')}
        </button>
        <button
          className={classNames(styles.button, styles.upgrade)}
          onClick={onUpgrade}
        >
          {t('Upgrade')}
        </button>
      </div>
    </div>
  );
}
