// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useTranslation} from 'react-i18next';

import styles from './SystemError.module.scss';
import {Props} from './SystemError.types';

export function SystemError({onCancel}: Props) {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('Notice')}</p>
      <p className={styles.content}>{t('System error. Please try again.')}</p>
      <button className={styles.button} onClick={onCancel}>
        {t('OK')}
      </button>
    </div>
  );
}
