// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {ShortVideoProjectConfig} from 'modules/project-config/models/ShortVideoProjectConfig';

import {ShortVideoComposition} from '../../composition/models/ShortVideoComposition';
import {ShortVideoConstraint} from '../../constraint/models/ShortVideoConstraint';
import {ShortVideoStoryboard} from '../../storyboard/models/ShortVideoStoryboard';
import {PromptType, ShortVideoProjectJSON, Size} from '../types';
import {checkoutPromptType, mergePrompt, parsePrompt} from '../utils';
import {BaseProject} from './BaseProject';
import {fromJSON as fromJSONPromptPolicy, PromptPolicy} from './PromptPolicy';

export class ShortVideoProject extends BaseProject<'short_video'> {
  static fromJSON({
    id,
    version,
    author_id,
    constraint,
    size,
    language,
    prompt_policy,
    prompt,
    storyboard,
    composition,
    config,
    style,
    histories,
  }: ShortVideoProjectJSON): ShortVideoProject {
    const promptPolicy = fromJSONPromptPolicy('short_video', prompt_policy);
    const promptType = checkoutPromptType(promptPolicy);
    const _prompt = parsePrompt(promptType, prompt);
    const _storyboard = storyboard
      ? ShortVideoStoryboard.fromJSON(
          size,
          language,
          style,
          promptPolicy,
          _prompt[promptType],
          storyboard
        )
      : undefined;
    if (composition && !_storyboard) {
      throw new Error('Composition is not allowed without storyboard');
    }
    const _composition =
      composition && _storyboard
        ? ShortVideoComposition.fromJSON(
            _storyboard.size,
            _storyboard.language,
            _storyboard.style,
            _storyboard.promptPolicy,
            _storyboard.prompt,
            _storyboard.title!,
            _storyboard.description!,
            _storyboard.hashtags!,
            composition
          )
        : undefined;
    return new ShortVideoProject(
      id,
      version,
      author_id,
      ShortVideoConstraint.fromJSON(constraint),
      size,
      language,
      style,
      promptPolicy,
      ShortVideoProjectConfig.fromJSON(config),
      _prompt,
      _storyboard,
      _composition,
      histories
    );
  }

  constructor(
    id: string,
    version: number,
    authorId: string,
    constraint: ShortVideoConstraint,
    size: Size,
    language: string,
    readonly style: string,
    promptPolicy: PromptPolicy<'short_video'>,
    config: ShortVideoProjectConfig,
    prompt: Record<PromptType, string>,
    storyboard?: ShortVideoStoryboard,
    composition?: ShortVideoComposition,
    histories?: string[]
  ) {
    super(
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      promptPolicy,
      config,
      prompt,
      storyboard,
      composition,
      histories
    );
  }

  get type() {
    return 'short_video' as const;
  }

  get thumbnail() {
    const firstValidScene = this.storyboard?.scenes?.find(scene =>
      scene.isValid()
    );
    return firstValidScene ? firstValidScene.currentImage : undefined;
  }

  patch(data: Partial<ShortVideoProject>) {
    const {
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      style,
      promptPolicy,
      config,
      storyboard,
      composition,
      histories,
    } = Object.assign({}, this, data);

    const _prompt = mergePrompt(
      this._prompt,
      checkoutPromptType(promptPolicy),
      data
    );
    return new ShortVideoProject(
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      style,
      promptPolicy,
      config,
      _prompt,
      storyboard,
      composition,
      histories
    );
  }

  toJSON(): ShortVideoProjectJSON {
    return {
      ...super.toJSON(),
      style: this.style,
    };
  }
}
