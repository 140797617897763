// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {checkOrder, getUserSubscription} from 'api/payment';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useUserContext} from 'contexts/UserContext';
import {isSubscription} from 'modules/payment/services';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {convertAffiliateOrder} from 'utils/rewardful';
import {
  OUT_TRADE_NO_QUERY_KEY,
  SUBSCRIPTION_PRODUCT_ID_QUERY_KEY,
} from 'utils/stripe';

import {creditOptions} from '../constData';
import {getLevelByProductId, getTypeByProductId} from '../Plan.container';
import {PaymentResult} from './PaymentResult';
import {CheckState, HookParams, HookReturn} from './PaymentResult.types';

export function useHook({status}: HookParams): HookReturn {
  const [checkState, setCheckState] = useState<CheckState>('CHECKING');
  const [params] = useSearchParams();

  const {recordEvent} = useAnalysis();
  const productInfo: HookReturn['productInfo'] = useMemo(() => {
    const productId = Number(params.get(SUBSCRIPTION_PRODUCT_ID_QUERY_KEY));
    const is_subscription = isSubscription(productId);
    const payCreditItem =
      !is_subscription &&
      creditOptions.find(item => Number(item.productId) === productId);
    return {
      is_subscription,
      level: (is_subscription && getLevelByProductId(productId)) || undefined,
      type: (is_subscription && getTypeByProductId(productId)) || undefined,
      productId: productId,
      payCredit: (payCreditItem && payCreditItem.credit) || undefined,
    };
  }, [params]);
  const outTradeNo = params.get(OUT_TRADE_NO_QUERY_KEY) ?? '';
  const {userInfo, updateUserInfo, updateAccount} = useUserContext();

  const checkOrderSuccess = useCallback(async () => {
    if (!outTradeNo) return setCheckState('FAIL');
    const {
      data: {isSuccessful: isPaymentSuccessful, customerEmail},
    } = await checkOrder(outTradeNo);

    // 支付成功页面并且订单编号验证成功，则发送转化统计
    if (status === 'SUCCESS' && isPaymentSuccessful) {
      if (customerEmail) {
        convertAffiliateOrder(customerEmail, outTradeNo);
      }

      recordEvent('Subscription', {data: {transaction_id: outTradeNo}});
    }

    if (
      (status === 'SUCCESS' && isPaymentSuccessful) ||
      (status === 'FAILED' && !isPaymentSuccessful)
    ) {
      setCheckState('SUCCESS');
      updateAccount();
      if (productInfo.is_subscription) {
        const {
          data: {subscriptionDetail},
        } = await getUserSubscription(userInfo.userId);
        if (subscriptionDetail) {
          updateUserInfo({
            periodEnd: subscriptionDetail.periodEnd,
            autoRenewal: Boolean(subscriptionDetail.autoRenewal),
          });
        }
      }
    } else if (
      (status === 'SUCCESS' && !isPaymentSuccessful) ||
      (status === 'FAILED' && isPaymentSuccessful)
    ) {
      setCheckState('FAIL');
    }
  }, [
    outTradeNo,
    status,
    recordEvent,
    updateAccount,
    productInfo.is_subscription,
    userInfo.userId,
    updateUserInfo,
  ]);

  useEffect(() => {
    checkOrderSuccess();
  }, [checkOrderSuccess]);

  return {checkState, status, productInfo, userInfo};
}

export const PaymentResultContainer = combine(useHook, ['status'])(
  PaymentResult
);
