// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {BilingualStoryComposition} from 'modules/composition/models/BilingualStoryComposition';
import {BilingualStoryConstraint} from 'modules/constraint/models/BilingualStoryConstraint';
import {BilingualStoryProjectConfig} from 'modules/project-config/models/BilingualStoryProjectConfig';
import {BilingualStoryStoryboard} from 'modules/storyboard/models/BilingualStoryStoryboard';

import {BilingualStoryProjectJSON, PromptType, Size} from '../types';
import {checkoutPromptType, mergePrompt, parsePrompt} from '../utils';
import {BaseProject} from './BaseProject';
import {fromJSON as fromJSONPromptPolicy, PromptPolicy} from './PromptPolicy';

export class BilingualStoryProject extends BaseProject<'bilingual_story'> {
  static fromJSON({
    id,
    version,
    author_id,
    constraint,
    size,
    language,
    prompt_policy,
    prompt,
    native_language,
    vocabulary,
    storyboard,
    composition,
    config,
    style,
    histories,
  }: BilingualStoryProjectJSON): BilingualStoryProject {
    const promptPolicy = fromJSONPromptPolicy('bilingual_story', prompt_policy);
    const promptType = checkoutPromptType(promptPolicy);
    const _prompt = parsePrompt(promptType, prompt);
    const _storyboard = storyboard
      ? BilingualStoryStoryboard.fromJSON(
          size,
          language,
          native_language,
          (vocabulary || []).filter(Boolean) as string[],
          style,
          promptPolicy,
          _prompt[promptType],
          storyboard
        )
      : undefined;
    if (composition && !_storyboard) {
      throw new Error('Composition is not allowed without storyboard');
    }
    const _composition =
      composition && _storyboard
        ? BilingualStoryComposition.fromJSON(
            _storyboard.size,
            _storyboard.language,
            _storyboard.nativeLanguage,
            _storyboard.vocabulary,
            _storyboard.style,
            _storyboard.promptPolicy,
            _storyboard.prompt,
            _storyboard.title!,
            _storyboard.nativeTitle!,
            _storyboard.description!,
            _storyboard.hashtags!,
            composition
          )
        : undefined;
    return new BilingualStoryProject(
      id,
      version,
      author_id,
      BilingualStoryConstraint.fromJSON(constraint),
      size,
      language,
      native_language,
      style,
      promptPolicy,
      BilingualStoryProjectConfig.fromJSON(config),
      _prompt,
      vocabulary,
      _storyboard,
      _composition,
      histories
    );
  }

  constructor(
    id: string,
    version: number,
    authorId: string,
    constraint: BilingualStoryConstraint,
    size: Size,
    language: string,
    readonly nativeLanguage: string,
    readonly style: string,
    promptPolicy: PromptPolicy<'bilingual_story'>,
    config: BilingualStoryProjectConfig,
    prompt: Record<PromptType, string>,
    readonly vocabulary?: (string | null)[],
    storyboard?: BilingualStoryStoryboard,
    composition?: BilingualStoryComposition,
    histories?: string[]
  ) {
    super(
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      promptPolicy,
      config,
      prompt,
      storyboard,
      composition,
      histories
    );
  }

  get type() {
    return 'bilingual_story' as const;
  }

  get thumbnail() {
    const firstValidScene = this.storyboard?.scenes?.find(scene =>
      scene.isValid()
    );
    return firstValidScene ? firstValidScene.currentImage : undefined;
  }

  patch(data: Partial<BilingualStoryProject>) {
    const {
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      style,
      promptPolicy,
      config,
      nativeLanguage,
      vocabulary,
      storyboard,
      composition,
      histories,
    } = Object.assign({}, this, data);

    const _prompt = mergePrompt(
      this._prompt,
      checkoutPromptType(promptPolicy),
      data
    );

    return new BilingualStoryProject(
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      nativeLanguage,
      style,
      promptPolicy,
      config,
      _prompt,
      vocabulary,
      storyboard,
      composition,
      histories
    );
  }

  toJSON(): BilingualStoryProjectJSON {
    return {
      ...super.toJSON(),
      style: this.style,
      native_language: this.nativeLanguage,
      vocabulary: this.vocabulary,
    };
  }
}
