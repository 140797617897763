// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export const poseImageKeyMap = {
  mask: 'mask',
  depth: 'depth',
  openPose: 'open_pose',
};

export const ErrorToastshowCancelMap = {
  systemError: false,
  noPose: true,
  no_credit: true,
};
