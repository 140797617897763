// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {loadStripe, Stripe} from '@stripe/stripe-js';
import * as paymentApi from 'api/payment';
import * as apiServer from 'api/server';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useUserContext} from 'contexts/UserContext';
import {usePayment} from 'modules/payment/services';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {creditsPerMonthByLevel, PerMonthChargeMap} from './constData';
import {PlanPage} from './Plan';
import {
  ActionType,
  ButtonInfoProps,
  ButtonInfoReturn,
  LevelEnum,
  ProductIdEnum,
  TypeStateEnum,
  UseHookReturn,
} from './Plan.types';

function useHook(): UseHookReturn {
  const [typeState, setTypeState] = useState<TypeStateEnum | undefined>();
  const [isOnAction, setIsOnAction] = useState<boolean>(false);
  const {t} = useTranslation('plan');
  const [currentPlanInfo, setCurrentPlanInfo] =
    useState<UseHookReturn['currentPlanInfo']>(null);

  const {
    userInfo,
    updateUserInfo,
    activeProductId,
    subscriptionProductId,
    setActiveProductId,
    setSubscriptionProductId,
    updateAccount,
  } = useUserContext();
  const {subscribe, upgradePlan, downgradePlan} = usePayment();
  const {showNotification} = useNotificationContext();
  const {recordEvent} = useAnalysis();

  const [downgradeProductId, setDowngradeProductId] = useState<number | null>(
    null
  );
  const [upgradeProductId, setUpgradeProductId] = useState<number | null>(null);

  const refreshUserInfo = useCallback(async () => {
    updateAccount();
    const {
      data: {subscriptionDetail, activeProductId, subscriptionProductId},
    } = await paymentApi.getUserSubscription(userInfo.userId);
    setActiveProductId(activeProductId);
    setSubscriptionProductId(subscriptionProductId);
    updateUserInfo({
      periodEnd: subscriptionDetail?.periodEnd ?? null,
    });
  }, [
    setActiveProductId,
    setSubscriptionProductId,
    updateAccount,
    updateUserInfo,
    userInfo.userId,
  ]);

  useEffect(() => {
    if (!userInfo.userId) return;
    paymentApi.getUserSubscription(userInfo.userId).then(res => {
      updateUserInfo({
        periodEnd: res.data.subscriptionDetail?.periodEnd ?? null,
      });
      setActiveProductId(res.data.activeProductId);
      setSubscriptionProductId(res.data.subscriptionProductId);
      setTypeState(
        res.data.activeProductId === 0
          ? 'Yearly'
          : getTypeByProductId(res.data.activeProductId)
      );
    });
  }, [
    setActiveProductId,
    setSubscriptionProductId,
    updateUserInfo,
    userInfo.userId,
  ]);
  useEffect(() => {
    const currentType = getTypeByProductId(activeProductId),
      currentLevel = getLevelByProductId(activeProductId),
      nextLevel = getLevelByProductId(subscriptionProductId),
      nextType = getTypeByProductId(subscriptionProductId);
    setCurrentPlanInfo(pre => {
      return {
        ...(pre || {}),
        type: currentType,
        level: currentLevel,
        nextLevel: nextLevel || currentLevel,
        nextType: nextType || currentType,
      };
    });
  }, [activeProductId, subscriptionProductId]);

  const initializeStripe = useCallback(async () => {
    const stripeInstance = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
    );
    return stripeInstance as Stripe;
  }, []);
  const afterUpgradeOrDownGrade = useCallback(
    async (actionType: 'Downgrade' | 'Upgrade', newProductId: number) => {
      const {
        data: {
          activeProductId: newActiveProductId,
          subscriptionProductId: newSubscriptionProductId,
          subscriptionDetail,
        },
      } = await paymentApi.getUserSubscription(userInfo.userId);
      setSubscriptionProductId(newSubscriptionProductId);
      setActiveProductId(newActiveProductId);

      const newRealLevel = getLevelByProductId(newProductId);

      //轮询获取用户信息，直到获取到用户信息为止,最多尝试10次，每次间隔1s，尝试失败无需提示用户
      let count = 0;
      const interval = setInterval(() => {
        if (count > 30) {
          clearInterval(interval);
          return;
        }
        count++;
        apiServer
          .getAccountDetails()
          .then((userCreditData: apiServer.GetAccountDetailsResponseType) => {
            if (
              actionType === 'Upgrade' &&
              userCreditData.planType === newRealLevel.toUpperCase() &&
              userCreditData.planCreditAmount ===
                creditsPerMonthByLevel[newRealLevel]
            ) {
              //升级时，planType和planCreditAmount接口返回数据都更新成功，就停止轮询
              clearInterval(interval);
              updateAccount();
              updateUserInfo({
                periodEnd: subscriptionDetail?.periodEnd ?? null,
                autoRenewal:
                  (subscriptionDetail &&
                    Boolean(subscriptionDetail.autoRenewal)) ||
                  false,
              });
            }
            if (actionType === 'Downgrade') {
              //降级时，planType和planCreditAmount不会更新不需要轮询
              clearInterval(interval);
              updateAccount();
              updateUserInfo({
                periodEnd: subscriptionDetail?.periodEnd ?? null,
                autoRenewal:
                  (subscriptionDetail &&
                    Boolean(subscriptionDetail.autoRenewal)) ||
                  false,
              });
            }
          });
      }, 1000);
    },
    [
      setActiveProductId,
      setSubscriptionProductId,
      updateAccount,
      updateUserInfo,
      userInfo.userId,
    ]
  );
  const clearActionProductId = useCallback(
    (actionType: Extract<ActionType, 'Downgrade' | 'Upgrade'>) => {
      if (actionType === 'Downgrade') {
        setDowngradeProductId(null);
      } else if (actionType === 'Upgrade') {
        setUpgradeProductId(null);
      }
      setIsOnAction(false);
    },
    []
  );

  const onDowngradeConfirm = useCallback(async () => {
    if (activeProductId === null || downgradeProductId === null) return;
    await downgradePlan(activeProductId, downgradeProductId as number)
      .then(async () => {
        showNotification({
          type: 'SUCCESS',
          message: 'Downgrade success',
        });
        afterUpgradeOrDownGrade('Downgrade', downgradeProductId);
      })
      .catch(() => {
        showNotification({
          type: 'ERROR',
          message: 'Downgrade failed',
        });
      })
      .finally(() => {
        clearActionProductId('Downgrade');
      });
  }, [
    activeProductId,
    afterUpgradeOrDownGrade,
    clearActionProductId,
    downgradePlan,
    downgradeProductId,
    showNotification,
  ]);

  const onUpgradeConfirm = useCallback(
    async (promoCode?: string) => {
      if (upgradeProductId === null) return;
      setIsOnAction(true);
      try {
        const clientSecret = await upgradePlan(
          activeProductId,
          upgradeProductId,
          promoCode
        );
        if (!clientSecret) {
          throw new Error('No client secret');
        }
        const strip = await initializeStripe();
        await strip.confirmCardPayment(clientSecret);
        showNotification({
          type: 'SUCCESS',
          message: 'Upgrade success',
        });
        afterUpgradeOrDownGrade('Upgrade', upgradeProductId);
        // Upgrade 成功发送转换统计
        recordEvent('Subscription', {data: {transaction_id: 'upgrade'}});
      } catch (error) {
        if (error instanceof paymentApi.SubscriptionNotChangedError) {
          setUpgradeProductId(null);
          refreshUserInfo();
        } else {
          showNotification({
            type: 'ERROR',
            message: 'Upgrade failed',
          });
        }
      } finally {
        setIsOnAction(false);
        clearActionProductId('Upgrade');
      }
    },
    [
      upgradeProductId,
      upgradePlan,
      activeProductId,
      initializeStripe,
      showNotification,
      afterUpgradeOrDownGrade,
      recordEvent,
      refreshUserInfo,
      clearActionProductId,
    ]
  );

  const onAction = useCallback(
    async (type: ActionType, productId: number) => {
      switch (type) {
        case 'Downgrade':
          setDowngradeProductId(productId);
          return;
        case 'Upgrade':
          setUpgradeProductId(productId);
          return;
        case 'Subscription':
          setIsOnAction(true);
          try {
            const session = await subscribe(productId);
            const strip = await initializeStripe();
            await strip.redirectToCheckout({sessionId: session});
          } catch (error) {
            if (error instanceof paymentApi.SubscriptionPurchasedError) {
              await refreshUserInfo();
            } else {
              showNotification({
                type: 'ERROR',
                message: 'Subscribe failed',
              });
            }
          } finally {
            setIsOnAction(false);
          }
      }
    },
    [initializeStripe, refreshUserInfo, showNotification, subscribe]
  );

  return {
    userInfo,
    typeState,
    setTypeState,
    currentPlanInfo,
    onAction,
    onDowngradeConfirm,
    downgradeProductId,
    upgradeProductId,
    clearActionProductId,
    onUpgradeConfirm,
    isOnAction,
  };
}
export const PlanContainer = combine(useHook)(PlanPage);
export const priceMap = {
  Monthly: {
    Standard: PerMonthChargeMap['Monthly']['Standard'],
    Pro: PerMonthChargeMap['Monthly']['Pro'],
    Free: PerMonthChargeMap['Monthly']['Free'],
  },
  Yearly: {
    Standard: PerMonthChargeMap['Yearly']['Standard'] * 12,
    Pro: PerMonthChargeMap['Yearly']['Pro'] * 12,
    Free: PerMonthChargeMap['Yearly']['Free'],
  },
};

export function getButtonProps({
  userLevel,
  userType,
  currentLevel,
  currentType,
}: ButtonInfoProps): ButtonInfoReturn {
  if (
    (userLevel === 'Free' && currentLevel === 'Free') ||
    (userLevel === currentLevel && userType === currentType)
  ) {
    return {
      type: 'ghost',
      children: 'Current plan',
      theme: 'default',
    };
  } else if (
    priceMap[userType][userLevel] < priceMap[currentType][currentLevel]
  ) {
    return {
      type: 'button',
      children: 'Upgrade',
    };
  } else {
    return {
      type: 'ghost',
      children: 'Downgrade',
    };
  }
}
export function getLevelByProductId(productId: number): LevelEnum {
  if (productId === undefined) {
    return productId;
  }
  if (productId === ProductIdEnum.FREE) {
    return 'Free';
  }
  if (
    productId === ProductIdEnum.PRO_MONTHLY ||
    productId === ProductIdEnum.PRO_YEARLY
  ) {
    return 'Pro';
  }
  return 'Standard';
}
export function getTypeByProductId(productId: number): TypeStateEnum {
  if (productId === undefined) {
    return 'Yearly';
  }
  if (
    [
      ProductIdEnum.FREE,
      ProductIdEnum.PRO_MONTHLY,
      ProductIdEnum.STANDARD_MONTHLY,
    ].includes(productId)
  ) {
    return 'Monthly';
  }
  return 'Yearly';
}
