// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export enum TaskType {
  Storyboard = '/storyboards',
  Shooting = '/shootings',
  Motion = '/motions',
  RemoveImageBG = '/tools/remove-image-bg',
  PoseSceneImage = '/tools/pose-scene-image',
  ImageConditioningVideo = '/tools/image-conditioning-video',
  StyleTransfer = '/tools/style-transfer',
}

type Size = [number, number];

export type StoryboardTemplate =
  | '/base'
  | '/flashcard'
  | '/bilingual_story'
  | '/bilingual_chat';

export type StoryboardAction =
  | 'generate_shot'
  | 'split_shot'
  | 'merge_shots'
  | 'generate_image';

interface UserInput {
  prompt: string;
  language: string;
  keep_user_content: boolean;
  paragraph_as_shots: boolean;
  tone?: string;
  tone_other?: string;
  native_language?: string;
  proficiency_level?: string;
  proficiency_level_other?: string;
  keywords?: string[];
}

interface AbstractCharacter {
  name: string;
  description: string;
  character_type?: number;
}

interface RequestCharacter extends AbstractCharacter {
  image: string;
}

interface AbstractScene {
  subtitle?: string;
  characters?: {name: string; prompt?: string}[];
  shot_type?: number;
  native_subtitle?: string;
  speaker?: string;
}

interface RequestScene extends AbstractScene {
  subtitle: string;
  prompt?: string;
  prompt_diff_ratio?: number;
}

interface AbstractStoryboard {
  title?: string;
  abstract?: string;
  hashtags?: string[];
  native_title?: string;
}

interface RequestStoryboard<A extends StoryboardAction>
  extends AbstractStoryboard {
  script: string;
  characters: RequestCharacter[];
  scenes: A extends 'merge_shots'
    ? [RequestScene, RequestScene]
    : [RequestScene];
}

export interface StyleRefImages {
  '1': string;
  '2': string;
}

interface StoryboardData {
  size: Size;
  style: string;
  user_input: UserInput;
  style_ref_images?: StyleRefImages;
  customized_characters?: {name: string}[];
}

interface ShootingScene extends AbstractScene {
  type: string;
  image: string;
  video?: string;
}

type MotionMode = 'pose';

export type StoryboardParams = {
  template: StoryboardTemplate;
  action: StoryboardAction | null;
};

export type MotionParams = {
  mode: MotionMode;
};

export type AnimateModel = 'base' | 'vira';

export type ImageConditioningVideoParams = {
  model: AnimateModel;
};

export type TaskParams<
  T extends TaskType,
  P extends
    | StoryboardParams
    | MotionParams
    | ImageConditioningVideoParams
    | null = null,
  A = P extends StoryboardParams
    ? P extends {action: infer A}
      ? A
      : never
    : never
> = T extends TaskType.Storyboard
  ? P extends StoryboardParams
    ? {projectId: string} & {
        params: Pick<P, 'template'> &
          (A extends StoryboardAction ? {action: A} : unknown);
      } & (A extends StoryboardAction
          ? {
              data: StoryboardData & {storyboard: RequestStoryboard<A>};
            }
          : {data: StoryboardData})
    : never
  : T extends TaskType.Shooting
  ? {
      projectId: string;
      data: {
        title?: string;
        native_title?: string;
        thumbnail?: string;
        scenes: ShootingScene[];
        video_config: Record<string, unknown>;
        voice_config: Record<string, unknown>;
      };
    }
  : T extends TaskType.Motion
  ? P extends MotionParams
    ? {
        params: P;
        projectId: string;
        data: {prompt: string; context_id?: string};
      }
    : never
  : T extends TaskType.RemoveImageBG
  ? {data: {image: string}}
  : T extends TaskType.PoseSceneImage
  ? {
      projectId: string;
      data: {
        size: Size;
        style: string;
        prompt: string;
        ref_images: {
          mask: string;
          depth: string;
          open_pose: string;
          characters: string[];
        };
        pose_description: string;
        shot_type?: number;
      };
    }
  : T extends TaskType.ImageConditioningVideo
  ? P extends ImageConditioningVideoParams
    ? {
        params: P;
        projectId: string;
        data: {
          style?: string;
          size: Size;
          ref_image: string;
          prompt?: string; //animate时用户输入的prompt
          image_prompt?: string; //scene的prompt
        };
      }
    : never
  : T extends TaskType.StyleTransfer
  ? {
      data: {
        style: string;
        prompt: string;
        ref_images: StyleRefImages;
        image: string;
      };
    }
  : never;

export enum TaskStatus {
  Created = 'created',
  Pending = 'pending',
  Processing = 'processing',
  Success = 'success',
  Failure = 'failure',
  Cancelled = 'cancelled',
  Timeout = 'timeout',
}

export interface ProgressSchema {
  current: number;
  total: number;
}

export interface FailureReason {
  code: number;
  message: string;
}

interface BaseTaskSchema {
  id: string;
  status: TaskStatus;
  type: string;
  create_time: string;
  progress?: ProgressSchema;
  failure_reason?: FailureReason;
  estimated_remaining_time?: number;
  elapsed_total_time?: number;
}

type MergeBaseTask<T> = BaseTaskSchema & T;

interface CharacterSchema extends AbstractCharacter {
  image?: string;
}

export type Character = MergeBaseTask<CharacterSchema>;

interface SceneSchema extends AbstractScene {
  prompt: string;
  image?: string;
}

export type Scene = MergeBaseTask<SceneSchema>;

interface StoryboardSchema extends AbstractStoryboard {
  script_full_text?: string;
  characters: Character[];
  scenes: Scene[];
}

interface SynthesisSchema {
  asset?: string;
  thumbnail?: string;
  asset_product?: string;
}

export type Synthesis = MergeBaseTask<SynthesisSchema>;

interface ShootingSchema {
  synthesis?: Synthesis;
}

export type TaskSchema<T extends TaskType> = T extends TaskType.Storyboard
  ? MergeBaseTask<StoryboardSchema>
  : T extends TaskType.Shooting
  ? MergeBaseTask<ShootingSchema>
  : T extends TaskType.Motion
  ? MergeBaseTask<{asset?: string}>
  : T extends TaskType.RemoveImageBG
  ? MergeBaseTask<{asset?: string}>
  : T extends TaskType.PoseSceneImage
  ? MergeBaseTask<{asset?: string}>
  : T extends TaskType.ImageConditioningVideo
  ? MergeBaseTask<{asset?: string}>
  : T extends TaskType.StyleTransfer
  ? MergeBaseTask<{asset?: string}>
  : never;

export type UploadFormTarget =
  | 'scene_image'
  | 'remove_image_bg'
  | 'pose_scene_ref'
  | 'style_transfer';

type ContentType<T extends string> = {content_type: T};
export type UploadFormParams<T extends UploadFormTarget> =
  T extends 'scene_image'
    ? [T, ContentType<'image/jpeg'>]
    : T extends 'remove_image_bg' | 'style_transfer'
    ? [T, ContentType<'image/jpeg' | 'image/png' | 'image/webp'>]
    : T extends 'pose_scene_ref'
    ? [
        T,
        {
          mask: ContentType<'image/png'>;
          depth: ContentType<'image/jpeg'>;
          open_pose: ContentType<'image/jpeg'>;
        }
      ]
    : never;

interface UploadForm {
  object_id: string;
  url: string;
  fields: Record<string, string>;
}

export type UploadFormSchema<T extends UploadFormTarget> = T extends
  | 'scene_image'
  | 'remove_image_bg'
  | 'style_transfer'
  ? UploadForm
  : T extends 'pose_scene_ref'
  ? {mask: UploadForm; depth: UploadForm; open_pose: UploadForm}
  : never;
