// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {setLanguage} from 'contexts/localStorage';
import {useUserContext} from 'contexts/UserContext';
import {PropsWithChildren, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export function AutoSelectLanguage({children}: PropsWithChildren) {
  const {userInfo} = useUserContext();
  const {i18n} = useTranslation();
  useEffect(() => {
    if (userInfo.lang && userInfo.lang !== i18n.language) {
      setLanguage(userInfo.lang);
      i18n.changeLanguage(userInfo.lang);
    }
  }, [i18n, userInfo.lang]);
  return <>{children}</>;
}
