// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Option} from 'components/Option';
import {Select} from 'pages/components/Select/Select';
import {SelectCard} from 'pages/components/SelectCard';
import {useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './CharacterSelect.module.scss';
import {Props} from './CharacterSelect.types';

export function CharacterSelect({value, onChange}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const renderSelectedValue = () => {
    if (value === null) {
      return t('AI generated');
    }
    let str = 'A';
    if (value[0].name) {
      str += ` - ${value[0].name}`;
    }
    str += ' / B';
    if (value[1].name) {
      str += ` - ${value[1].name}`;
    }
    return str;
  };
  const onSelectCustom = () => {
    if (value === null) {
      onChange([{name: undefined}, {name: undefined}]);
      inputRef.current?.focus();
    }
  };
  const {t} = useTranslation('project');
  const renderCustomizeOption = () => {
    return (
      <div className={styles.customizeOption}>
        <div className={styles.item}>
          <span className={styles.prefix}>A -</span>{' '}
          <input
            ref={inputRef}
            className={styles.input}
            type="text"
            placeholder={t('Input name A')}
            value={(value && value[0].name) || ''}
            maxLength={30}
            onChange={e => {
              onChange([
                {name: e.target.value},
                {name: (value && value[1].name) || ''},
              ]);
            }}
            onFocus={onSelectCustom}
            onKeyUp={e => e.code === 'Enter' && onClose()}
          />
        </div>
        <div className={styles.item}>
          <span className={styles.prefix}>B -</span>{' '}
          <input
            className={styles.input}
            type="text"
            placeholder={t('Input name B')}
            value={(value && value[1].name) || ''}
            maxLength={30}
            onChange={e => {
              onChange([
                {name: (value && value[0].name) || ''},
                {name: e.target.value},
              ]);
            }}
            onFocus={onSelectCustom}
            onKeyUp={e => e.code === 'Enter' && onClose()}
          />
        </div>
      </div>
    );
  };
  return (
    <Select
      label=""
      isOpen={isOpen}
      value={renderSelectedValue()}
      onVisibleChange={visible => (visible ? setIsOpen(true) : onClose())}
    >
      <SelectCard label={t('Character')}>
        <ul className={styles['option-list']}>
          <li className={styles['option-item']}>
            <Option
              isChecked={value === null}
              label={t('AI generated')}
              isDisabled={false}
              onCheck={() => {
                onChange(null);
                onClose();
              }}
            ></Option>
          </li>
          <li className={styles['option-item']}>
            <Option
              isChecked={value !== null}
              label={t('I prefer')}
              isDisabled={false}
              onCheck={onSelectCustom}
            ></Option>
            {renderCustomizeOption()}
          </li>
        </ul>
      </SelectCard>
    </Select>
  );
}
